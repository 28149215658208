import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import LabeledField from '../../../../../components/form/LabeledField';
import Button from '../../../../../components/form/Button';
import { useFormikContext } from 'formik';
import { TextField } from '../../../../../components/form/TextField';
import { DocumentsModalForm } from '../DocumentsContext.model';

import { useTranslation } from 'react-i18next';
import { useDocumentsContext } from '../DocumentsContext';
import { LicenseDuplicateCheckResponse } from '../../../../../api/partnerDocuments/PartnerDocumentsResource';
type LicenseNumberProps = {
  label: string;
  buttonLabel: string;
};
const LicenseNumber: FC<LicenseNumberProps> = (props: LicenseNumberProps) => {
  const formik = useFormikContext<DocumentsModalForm>();
  const { licenseDuplicateCheckResponse, actions } = useDocumentsContext();
  const { t } = useTranslation('partnerDocuments');
  const [message, setMessage] = useState<string | null>(null);
  const [messageColor, setMessageColor] = useState<string>('#0F2125');

  const onDuplicateCheck = (licenseNumber: string | null) => {
    if (licenseNumber && licenseNumber.trim()) {
      actions.onCheckLicenseDuplicates(licenseNumber.trim());
    } else {
      setMessageColor('red');
      setMessage(t('licenseDuplicateCheck.emptyLicenseField'));
    }
  };
  const licenseDuplicateCheckResultMessage = () => {
    if (licenseDuplicateCheckResponse) {
      if (licenseDuplicateCheckResponse.duplicate) {
        setMessageColor('red');
        return t('licenseDuplicateCheck.licenseHasDuplicates', {
          scoutIds: getPartnersScoutIds(licenseDuplicateCheckResponse),
        });
      } else if (licenseDuplicateCheckResponse.alreadyUsedByPartner) {
        setMessageColor('red');
        return t('licenseDuplicateCheck.licenseAlreadyInUse');
      } else {
        setMessageColor('#0F2125');
        return t('licenseDuplicateCheck.licenseHasNoDuplicates');
      }
    } else {
      return null;
    }
  };

  function getPartnersScoutIds(licenseDuplicateCheckResponse: LicenseDuplicateCheckResponse) {
    return licenseDuplicateCheckResponse.partnersUsingLicence.toString();
  }

  useEffect(() => {
    setMessage(licenseDuplicateCheckResultMessage());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseDuplicateCheckResponse]);

  useEffect(() => {
    setMessage(null);
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ flexGrow: 9 }}>
        <LabeledField label={props.label}>
          <Box>
            <TextField
              sx={{
                width: '300px',
                margin: '5px 10px !important',
              }}
              id={'licenseNumber'}
              key={'licenseNumber'}
              type={'string'}
              value={formik.values?.licenseNumber || null}
              onChange={(e) => formik.handleChange(e)}
            />
            <Button
              type={'button'}
              label={props.buttonLabel}
              onClick={() => {
                onDuplicateCheck(formik.getFieldProps('licenseNumber').value);
              }}
              sx={{ margin: '5px 10px' }}
            />
          </Box>
        </LabeledField>
        <Box sx={{ display: 'flex', justifyContent: 'start', fontSize: 14, fontWeight: 400, color: messageColor }}>
          {message}
        </Box>
      </Box>
    </Box>
  );
};

export default LicenseNumber;
