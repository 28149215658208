import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useTheme } from '@mui/styles';
import { Box, Theme } from '@mui/material';
import BookingSection from '../../BookingSection';
import Checkbox from '../../../../../../components/form/Checkbox';
import Label from '../../../../../../components/form/Label';
import FormInput from '../../../../../../components/form/FormInput';

const tKey = (key: String) => `booking.edit.sections.maxPartners.${key}`;

const RelocationTypes: FC = () => {
  const { t } = useTranslation('bookings');
  const theme = useTheme<Theme>();
  const formik = useFormikContext();
  const maxPartnersProps = formik.getFieldProps<number | null>('maxCompetitors');
  const maxPartnersSelectedProps = formik.getFieldProps<boolean>('maxCompetitorsSelected');
  return (
    <BookingSection title={t(tKey('header'))} isModalSection={true}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            justifyContent: 'start',
            columnGap: '20px',
          },
        }}
      >
        {
          <Box sx={{ display: 'flex' }}>
            <Checkbox
              id={maxPartnersSelectedProps.name}
              onChange={(event) => formik?.setFieldValue(maxPartnersSelectedProps.name, event.target.checked)}
              checked={maxPartnersSelectedProps.value}
            />
            <FormInput
              translateKey={tKey('content')}
              translationNameSpace={'bookings'}
              disabled={!maxPartnersSelectedProps.value}
              formField={'maxCompetitors'}
              width={'425px'}
              minFormWidth={'90px'}
              valueAlign={'left'}
              textAlign={'left'}
              type={'number'}
              endAdornment={<></>}
            />
            {maxPartnersProps.value !== null && maxPartnersSelectedProps.value && (
              <Label
                label={`(${t(tKey('description'))} ${maxPartnersProps.value + 1})`}
                sx={{
                  margin: '5px 0',
                  textAlign: 'left',
                  flexGrow: 1,
                }}
              />
            )}
          </Box>
        }
      </Box>
    </BookingSection>
  );
};
export default RelocationTypes;
