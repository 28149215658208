import { apiVersion, getApiClient } from '../ApiUtils';
import { AlgotestRelocationRequest } from './AlgotestRelocationRequest';
import { AlgotestResponse } from './AlgotestResponse';

const ALGOTEST_API_PREFIX = '/algotest';

export function getAlgotestRelocationRequests(requestId: string): Promise<AlgotestRelocationRequest> {
  return getApiClient()
    .then((client) =>
      client.get<AlgotestRelocationRequest>(`${ALGOTEST_API_PREFIX}/relocation-request/${requestId}`, {
        baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion,
      }),
    )
    .then((response) => response.data);
}
export function getAlgotestSteps(): Promise<string[]> {
  return getApiClient()
    .then((client) =>
      client.get<string[]>(`${ALGOTEST_API_PREFIX}/steps`, {
        baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion,
      }),
    )
    .then((response) => response.data);
}
export function leadGenerationTest(request: AlgotestRelocationRequest): Promise<AlgotestResponse> {
  return getApiClient()
    .then((client) =>
      client.post<AlgotestResponse>(ALGOTEST_API_PREFIX, request, {
        baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion,
      }),
    )
    .then((response) => response.data);
}
