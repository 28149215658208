import { FormControlLabel, Theme, styled, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ToggleSwitch from '../form/ToggleSwitch';

const StyledDateRangeCalendar = styled('div')({
  display: 'flex',
  marginTop: '20px',
  justifyContent: 'space-between',
});

const SwitchWrapper = styled('div')({
  position: 'relative',
  marginBottom: '20px',
  paddingBottom: '6px',
  borderBottom: '1px solid #CCCCCC',
});

const toggleStyles = {
  margin: '10px',
};

export type DateRangeCalendarModalProps = {
  sx?: SxProps<Theme>;
  onSelect: (newActivationDate?: Date, newDeactivationDate?: Date) => void;
  activationDate?: Date;
  deactivationDate?: Date;
  activeStatus?: boolean;
};

const BookingDateCalendar: React.FC<DateRangeCalendarModalProps> = ({
  activationDate,
  deactivationDate,
  onSelect,
  activeStatus,
}) => {
  const startDay = activationDate != null ? new Date(activationDate) : undefined;
  const endDay = deactivationDate != null ? new Date(deactivationDate) : undefined;

  const { t } = useTranslation(['common']);
  const theme = useTheme<Theme>();
  const [startDate, updateStartDate] = useState<Date | undefined>(startDay);
  const [endDate, updateEndDate] = useState<Date | undefined>(endDay);
  const [isDateRange, updateIsDateRange] = useState<boolean>(endDate !== undefined);
  const [isStartDateRange, updateIsStartDateRange] = useState<boolean>(startDate !== undefined);

  useEffect(() => {
    updateStartDate(startDate);
    updateEndDate(endDate);
  }, [startDate, endDate]);

  const dateCalendarStyles = {
    '& .MuiPickersDay-root': {
      '&.Mui-selected': {
        backgroundColor: theme.palette.warning.main,
      },
    },
    '& .MuiPickersDay-root:focus': {
      '&.Mui-selected': {
        backgroundColor: theme.palette.warning.main,
      },
    },
    '& .MuiPickersYear-yearButton': {
      '&.Mui-selected': {
        backgroundColor: theme.palette.warning.main,
      },
    },
    '& .MuiIconButton-edgeStart:not(.Mui-disabled)': {
      color: theme.palette.warning.main,
    },
    '& .MuiIconButton-edgeEnd:not(.Mui-disabled)': {
      color: theme.palette.warning.main,
    },
    '& .MuiPickersCalendarHeader-switchViewButton:not(.Mui-disabled)': {
      color: theme.palette.warning.main,
    },
  };

  const endDateCalendarStyles = {
    '& .MuiPickersCalendarHeader-label': {
      opacity: isDateRange ? 'inherit' : '0.3 !important',
    },
  };

  const formControlLabel = {
    '& .MuiFormControlLabel-label': {
      fontSize: '16px',
      fontWeight: 600,
    },
  };

  return (
    <StyledDateRangeCalendar>
      <div>
        <SwitchWrapper>
          <FormControlLabel
            control={
              <ToggleSwitch
                sx={toggleStyles}
                onChange={(e) => {
                  const date = e.target.checked ? new Date() : undefined;
                  updateIsStartDateRange(e.target.checked);
                  updateStartDate(date);
                  onSelect(date, endDate);
                }}
                checked={isStartDateRange}
              />
            }
            disabled={activeStatus}
            label={t('dateRangePicker.startDate')}
            labelPlacement='end'
            sx={formControlLabel}
          />
        </SwitchWrapper>
        <DateCalendar
          sx={dateCalendarStyles}
          maxDate={endDate}
          value={startDate ?? new Date()}
          disablePast={true}
          onChange={(date) => {
            updateStartDate(date);
            onSelect(date, endDate);
          }}
          disabled={!isStartDateRange || activeStatus}
          disableFuture={!isStartDateRange}
        />
      </div>
      <div>
        <SwitchWrapper>
          <FormControlLabel
            control={
              <ToggleSwitch
                sx={toggleStyles}
                onChange={(e) => {
                  const date = e.target.checked ? (startDate ?? new Date()) : undefined;
                  updateEndDate(date);
                  updateIsDateRange(e.target.checked);
                  onSelect(startDate, date);
                }}
                checked={isDateRange}
              />
            }
            label={t('dateRangePicker.endDate')}
            labelPlacement='end'
            sx={formControlLabel}
          />
        </SwitchWrapper>
        <DateCalendar
          sx={{ ...dateCalendarStyles, ...endDateCalendarStyles }}
          minDate={startDate ?? new Date()}
          value={endDate ?? new Date()}
          disableHighlightToday
          disabled={!endDate}
          disablePast={true}
          onChange={(date) => {
            updateEndDate(date);
            onSelect(startDate, date);
          }}
        />
      </div>
    </StyledDateRangeCalendar>
  );
};

export default BookingDateCalendar;
