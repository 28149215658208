import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import Button from './Button';
import Modal from './Modal';

export type DeletionConfirmationModalProps = {
  labels: {
    title: string;
    description: string;
    buttonYes: string;
    buttonNo: string;
  };
  confirm: () => void;
  cancel: () => void;
};

type Props = {
  deletionConfirmationModalProps: DeletionConfirmationModalProps | undefined;
  loading: boolean;
};

const DeletionConfirmationModal = (props: Props) => {
  return (
    <Modal
      isOpen={props.deletionConfirmationModalProps != null}
      title={<Box sx={{ whiteSpace: 'nowrap' }}>{props.deletionConfirmationModalProps?.labels.title}</Box>}
      width={'min-content'}
    >
      <Box sx={{ marginTop: '15px', display: 'flex', flexDirection: 'column', rowGap: '25px' }}>
        <Box sx={{ fontSize: '16px' }}>{props.deletionConfirmationModalProps?.labels.description}</Box>
        <Box sx={{ display: 'flex', justifyContent: 'end', columnGap: '15px' }}>
          <Button
            label={props.deletionConfirmationModalProps?.labels.buttonNo}
            appearance={'dark'}
            variant={'outlined'}
            onClick={() => props.deletionConfirmationModalProps?.cancel()}
            disabled={props.loading}
          />
          <Button
            label={props.deletionConfirmationModalProps?.labels.buttonYes}
            appearance={'dark'}
            onClick={() => props.deletionConfirmationModalProps?.confirm()}
            icon={props.loading ? <CircularProgress size={20} color='inherit' sx={{ mr: '10px' }} /> : undefined}
            disabled={props.loading}
          />
        </Box>
      </Box>
    </Modal>
  );
};
export default DeletionConfirmationModal;
