import { BookingTestFilterValues } from './BookingTestFilterModel';
import { BookingTestDataRequest } from '../../../../api/bookingtest/BookingTestDataRequest';

export const bookingTestModelToForm = (form: BookingTestFilterValues): BookingTestDataRequest => {
  return {
    dateRange: {
      start: form.startDate,
      end: form.endDate,
    },
    booking: {
      minSquareMeters: form.minSquareMeters,
      maxSquareMeters: form.maxSquareMeters,
      minDistance: form.minDistance,
      maxDistance: form.maxDistance,
      relocationTypes: form.relocationTypes,
      bookingTour: form.bookingTour,
    },
  };
};
