import React from 'react';
import { PartnerDataProps } from '../MainData';
import { Box } from '@mui/material';
import { ContractFilters } from '../../../../api/contract/ContractFilters';
import { useContractLoader } from '../../../../components/table/schema/type/ContractSchema';
import UmzugEasyTable from '../../../../components/table/UmzugEasyTable';
import { loadNextPageIfPossible } from '../../../../components/table/util/UmzugEasyTableFunctions';

const ContractsData: React.FC<PartnerDataProps> = ({ partner }: PartnerDataProps) => {
  const filters: ContractFilters = {
    partnerCwid: partner?.cwid || '',
    isActiveOn: new Date().toISOString().split('T')[0],
  };
  const { tableProps, page, pageCount, loadNextPage } = useContractLoader(filters);

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'block',
        overflowY: 'auto',
      }}
    >
      <UmzugEasyTable
        {...tableProps}
        isHeaderSticky={true}
        onLastRowRendered={() => loadNextPageIfPossible(page, pageCount, loadNextPage)}
        height='500px'
        theme='simple'
      />
    </Box>
  );
};

export default ContractsData;
