import React, { FC, ReactElement } from 'react';
import { Formik, useFormikContext } from 'formik';
import DatePicker from '../../../../components/form/DatePicker';
import Button from '../../../../components/form/Button';
import { Box, CircularProgress, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getInitialPartnerLeadsFilters, useRequestsContext } from './RequestsContext';
import Label from '../../../../components/form/Label';
import { useTranslation } from 'react-i18next';

type LeadsFilterFormValues = {
  dateFrom: Date;
  dateTo: Date;
};

type LeadsFilterProps = {
  loading: boolean;
};

const OLDEST_SUPPORTED_DATE = new Date('2019-01-01T00:00:00');

const LeadsFilterForm = ({ loading }: LeadsFilterProps) => {
  const formik = useFormikContext<LeadsFilterFormValues>();
  const { t } = useTranslation('requests');

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ paddingTop: '22px' }}>
        <Grid container>
          <Grid>
            <Label label={t('leads.search.requestsRange')} sx={{ paddingRight: '1rem', paddingTop: '10px' }} />
          </Grid>
          <Grid container item xs={8} md={6}>
            <>
              <DatePicker
                initialValue={formik.getFieldProps('dateFrom').value}
                onChange={(dateFrom) => formik.setFieldValue('dateFrom', dateFrom)}
                disableFuture={true}
                isUTC={true}
                shouldDisableDate={(dateFrom) =>
                  dateFrom > formik.getFieldProps('dateTo').value || dateFrom < OLDEST_SUPPORTED_DATE
                }
              />
              <DatePicker
                initialValue={formik.getFieldProps('dateTo').value}
                onChange={(dateTo) => formik.setFieldValue('dateTo', dateTo)}
                disableFuture={true}
                isUTC={true}
                shouldDisableDate={(dateTo) =>
                  dateTo < formik.getFieldProps('dateFrom').value || dateTo < OLDEST_SUPPORTED_DATE
                }
              />
              <Button
                disabled={loading}
                sx={{ minWidth: '40px', padding: '0px', margin: '10px' }}
                type={'submit'}
                icon={
                  loading ? (
                    <CircularProgress size={20} color='inherit' />
                  ) : (
                    <SearchIcon sx={{ fontSize: '18px', width: 'unset', height: '80%', transform: 'scale(-1,1)' }} />
                  )
                }
              />
            </>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

const LeadsFilter: FC = (): ReactElement => {
  const { loading, actions } = useRequestsContext();
  const initialPartnerLeadsFilters = getInitialPartnerLeadsFilters();
  return (
    <Formik
      initialValues={{
        dateFrom: initialPartnerLeadsFilters.start,
        dateTo: initialPartnerLeadsFilters.end,
      }}
      onSubmit={(filter: LeadsFilterFormValues) =>
        actions.onFilterChange({ start: filter.dateFrom, end: filter.dateTo })
      }
    >
      <LeadsFilterForm loading={loading} />
    </Formik>
  );
};

export default LeadsFilter;
