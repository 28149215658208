import React, { CSSProperties, MouseEvent } from 'react';
import { Grid, Tooltip } from '@mui/material';

export type ActionIconProps = {
  icon: string;
  alt?: string;
  iconStyle?: CSSProperties;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  tooltip?: string;
  justifyContent?: 'flex-end';
  'data-testid'?: string;
};

const ActionImg: React.FC<ActionIconProps> = ({
  icon,
  alt,
  onClick,
  tooltip,
  iconStyle,
  justifyContent,
  'data-testid': dataTestId,
}: ActionIconProps) => {
  const iconStyles: CSSProperties = { cursor: 'pointer', ...(iconStyle || {}) };
  const displayedIcon: JSX.Element = <img src={icon} alt={alt} style={iconStyles} />;

  return (
    <Grid
      item
      onClick={onClick}
      data-testid={dataTestId}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: justifyContent,
      }}
    >
      {tooltip ? <Tooltip title={tooltip}>{displayedIcon}</Tooltip> : displayedIcon}
    </Grid>
  );
};

export default ActionImg;
