import { TableHeader, TableModel } from '../model/UmzugEasyTableModel';
import { TableSchema } from './SchemaModel';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export type SchemaUtilProps<T> = {
  groupSchema?: TableSchema<T>[];
  schema: TableSchema<T>[];
  rows: T[] | undefined;
  translationPrefix: string;
  translationFile: string;
  sxGroupHeaders?: SxProps<Theme>;
  sxHeaders?: SxProps<Theme>;
};

export const getUmzugEasyTableProps = <T extends unknown>(props: SchemaUtilProps<T>): TableModel<T> => {
  return {
    content: {
      groupHeaders: props.groupSchema?.map(
        (item): TableHeader<T> => ({
          key: item.key,
          name: `${props.translationPrefix}.${item.translationKey ? item.translationKey : item.key}`,
          nameParam: item.translationKeyParam,
          value: item.value,
          translateFile: props.translationFile,
          order: item.order,
          cellRenderer: item.cellRenderer,
          colSpan: item.colSpan,
        }),
      ),
      headers: props.schema.map(
        (item): TableHeader<T> => ({
          key: item.key,
          name: `${props.translationPrefix}.${item.translationKey ? item.translationKey : item.key}`,
          nameParam: item.translationKeyParam,
          value: item.value,
          translateFile: props.translationFile,
          order: item.order,
          cellRenderer: item.cellRenderer,
          onSortChange: item.onSortChange,
          sxColumn: item.sxColumn,
        }),
      ),
      sxGroupHeaders: props.sxGroupHeaders,
      sxHeaders: props.sxHeaders,
      rows: props.rows || [],
    },
  };
};
