import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useBookingContext } from './BookingContext';
import BookingListItem from './BookingListItem';
import ActionImg from '../../../../components/shared/ActionImg';
import Button from '../../../../components/form/Button';
import { getListOfRange } from '../../../../utils/NumbersUtil';

const ListItemSkeleton = () => (
  <Skeleton height={54} variant='rounded' sx={{ marginTop: '3px', marginLeft: '3px', marginRight: '3px' }} />
);

const NoBookings = () => {
  const { t } = useTranslation('bookings');
  const theme = useTheme<Theme>();
  return (
    <Box
      sx={{
        marginTop: '8px',
        fontSize: '18px',
        color: theme.palette.text.secondary,
        fontWeight: 600,
      }}
    >
      {t('booking.bookingList.noBooking')}
    </Box>
  );
};

const BookingList: FC = (): ReactElement => {
  const { bookings, actions, isLoading } = useBookingContext();
  const { t } = useTranslation('bookings');
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {!isLoading &&
          bookings.length > 0 &&
          bookings.map((booking) => <BookingListItem key={booking.id} booking={booking} />)}
        {!isLoading && bookings.length === 0 && <NoBookings />}
        {isLoading && getListOfRange(1, 3).map((index) => <ListItemSkeleton key={index} />)}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          icon={<ActionImg icon={'/icons/plus.svg'} iconStyle={{ marginRight: '5px' }} />}
          label={t('booking.bookingList.newBooking')}
          type={'button'}
          appearance={'link'}
          onClick={() => actions.onBookingAdd()}
        />
      </Box>
    </Box>
  );
};

export default BookingList;
