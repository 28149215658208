import React from 'react';
import { PartnerApiClient } from '../../../../api/partnerApi/PartnerApiAdminResource';
import { AlertProps } from '../../../../components/feedback/Alert';

export type ApiClientsContextProps = {
  apiClients: PartnerApiClient[];
  isLoading: boolean;
  alert?: AlertProps;
};

export const ApiClientsContext = React.createContext<ApiClientsContextProps>({
  apiClients: [],
  isLoading: false,
});
