import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { ShopBudget, ShopBudgetPage } from '../../../../../../api/shopPackage/ShopPackageResources';
import { getShopBudgetHistoryByPartnerCwid } from '../../../../../../api/shopPackage/ShopPackageApi';
import UmzugEasyBasicTable, { TableDataProps } from '../../../../../../components/basicTable/UmzugEasyBasicTable';
import MultipleRowSkeleton from '../../../../../../components/feedback/MultipleRowSkeleton';
import { PartnerDataProps } from '../../../MainData';

const columnsStyle = {
  period: {
    width: '300px',
    minWidth: '200px',
  },
  monthlyBudget: {
    width: '250px',
    minWidth: '100px',
  },
  usedBudget: {
    width: '250px',
    minWidth: '100px',
  },
  leadsBought: {
    width: '200px',
    minWidth: '100px',
  },
  leadsCancelled: {
    width: '200px',
    minWidth: '100px',
  },
};

const ShopBudgetList: React.FC<PartnerDataProps> = ({ partner }: PartnerDataProps) => {
  const { t } = useTranslation('bookings');
  const [shopBudgetPage, setShopBudgetPage] = useState<ShopBudgetPage | null>(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const pageSize = 20;

  useEffect(() => {
    if (partner?.cwid) {
      setLoading(true);
      getShopBudgetHistoryByPartnerCwid(partner.cwid, 0, pageSize)
        .then(setShopBudgetPage)
        .finally(() => setLoading(false));
    }
  }, [partner?.cwid]);
  const loadNextPage = async () => {
    if (!shopBudgetPage || page >= shopBudgetPage.page?.totalPages - 1 || !partner?.cwid) return;

    const nextPage = page + 1;
    setPage(nextPage);
    const newPage = await getShopBudgetHistoryByPartnerCwid(partner.cwid, nextPage, pageSize);

    setShopBudgetPage((prevPage) => ({
      ...newPage,
      _embedded: {
        contracts: [...(prevPage?._embedded?.contracts || []), ...(newPage._embedded?.contracts || [])],
      },
    }));
  };

  const header = [
    { data: t('shopPackage.history.period'), style: columnsStyle.period },
    { data: t('shopPackage.history.monthlyBudget'), style: columnsStyle.monthlyBudget },
    { data: t('shopPackage.history.usedBudget'), style: columnsStyle.usedBudget },
    { data: t('shopPackage.history.boughtLeads'), style: columnsStyle.leadsBought },
    { data: t('shopPackage.history.cancelledLeads'), style: columnsStyle.leadsCancelled },
  ];

  const rowOf = (budget: ShopBudget): TableDataProps[] => [
    { data: `${budget.budgetStartDate} - ${budget.budgetEndDate}`, style: columnsStyle.period },
    { data: `${budget.monthlyBudget} €`, style: columnsStyle.monthlyBudget },
    { data: `${budget.usedBudget} €`, style: columnsStyle.usedBudget },
    { data: budget.leadsBought, style: columnsStyle.leadsBought },
    { data: budget.leadsCancelled, style: columnsStyle.leadsCancelled },
  ];

  const rows = shopBudgetPage?._embedded?.contracts?.map(rowOf) || [];

  if (loading) {
    return (
      <Box sx={{ width: '1200px' }}>
        <MultipleRowSkeleton rows={20} fontSize='30px' styles={{ width: '100%', lineHeight: 1.5 }} />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <UmzugEasyBasicTable header={header} rows={rows} onLastRowRendered={loadNextPage} />
    </Box>
  );
};

export default ShopBudgetList;
