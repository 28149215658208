import React from 'react';
import { PartnerStatisticsRequestFilter } from '../../../api/statistics/PartnerStatisticsResource';
import { AlertProps } from '../../../components/feedback/Alert';
import { UmzugEasyTableProps } from '../../../components/table/UmzugEasyTable';
import { PartnerStatisticsWithIndex } from './PartnersReportContext';

const emptyFunction = () => {};

export type PartnersReportActionsProps = {
  onOpenPartnerDetails: (partnerCwid: string) => void;
  onLoadNextPage: () => void;
  onFilterChange: (filter: PartnerStatisticsRequestFilter) => void;
  onSortByChange: (sortField: string, direction: string) => void;
  onShowPreviousMonths: (active: boolean) => void;
};

export type PartnersReportContextProps = {
  partnersStatistics: PartnerStatisticsWithIndex[];
  partnersStatisticsFilter: PartnerStatisticsRequestFilter;
  loading: boolean;
  showPreviousMonths: boolean;
  page: number;
  totalPages: number;
  totalElements: number;
  tableProps?: UmzugEasyTableProps<PartnerStatisticsWithIndex>;
  alert?: AlertProps;
  actions: PartnersReportActionsProps;
};

export const PartnersReportContext = React.createContext<PartnersReportContextProps>({
  partnersStatistics: [],
  partnersStatisticsFilter: {} as PartnerStatisticsRequestFilter,
  loading: false,
  showPreviousMonths: false,
  page: 0,
  totalPages: 0,
  totalElements: 0,
  tableProps: undefined,
  actions: {
    onOpenPartnerDetails: emptyFunction,
    onLoadNextPage: emptyFunction,
    onFilterChange: emptyFunction,
    onSortByChange: emptyFunction,
    onShowPreviousMonths: emptyFunction,
  },
});
