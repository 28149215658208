import { Typography } from '@mui/material';
import React, { FC, ReactElement } from 'react';

export type FormLabelProps = {
  title: string;
  fontSize?: string;
  fontWeight?: number;

  customStyles?: React.CSSProperties;
};

const FormLabel: FC<FormLabelProps> = (props: FormLabelProps): ReactElement => (
  <Typography
    style={{
      textTransform: 'capitalize',
      color: 'black',
      marginLeft: '20px',
      fontSize: props.fontSize || '14px',
      ...(props?.customStyles || {}),
    }}
    data-testid={'formlabel'}
    sx={{
      fontWeight: props.fontWeight || 600,
      textAlign: 'left',
    }}
  >
    {props.title}
  </Typography>
);

export default FormLabel;
