import { Page } from '../Page';
import { apiVersion, getApiClient, mapFilters } from '../ApiUtils';
import { ContractResource, ContractResourcePage } from './ContractResourcePage';
import { ContractFilters } from './ContractFilters';

const config = { baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion };

export function getContracts(
  filters: ContractFilters,
  page: number = 0,
  size: number = 20,
): Promise<Page<{ contracts: ContractResource[] }>> {
  const filterString = mapFilters(filters);
  return getApiClient()
    .then((client) =>
      client.get<Page<ContractResourcePage>>(`/partners/contracts?page=${page}&size=${size}&${filterString}`, config),
    )
    .then((response) => response.data);
}

export function getContractsByPartnerCwid(partnerCwid: string): Promise<ContractResource[]> {
  return getApiClient()
    .then((client) => client.get<ContractResource[]>(`/partners/${partnerCwid}/contracts`, config))
    .then((response) => response.data);
}
