import { RelocationType } from '../../../../api/relocationRequest/RelocationRequestDetail';
import { BookingDirection, BookingTour } from '../../../../api/booking/BookingResource';

export type BookingTestFilterValues = {
  startDate: string;
  endDate: string;
  minSquareMeters: number;
  maxSquareMeters: number;
  minDistance: number;
  maxDistance: number;
  relocationTypes: RelocationType[];
  bookingTour?: BookingTour;
};

export const DEFAULT_BOOKING_TOUR: BookingTour = {
  name: 'DefaultTourName',
  direction: BookingDirection.TWO_WAY,
  surcharge: 0,
  bookingTourRegions: [],
  bookingTourPostalCodes: [],
};

const THIRTY_DAYS_AGO = new Date(new Date().setDate(new Date().getDate() - 30));

export const INITIAL_BOOKING_TEST_FILTER_VALUES: BookingTestFilterValues = {
  startDate: THIRTY_DAYS_AGO.toISOString(),
  endDate: new Date().toISOString(),
  minSquareMeters: 0,
  maxSquareMeters: 9999,
  minDistance: 0,
  maxDistance: 9999,
  relocationTypes: [RelocationType.RELOCATION, RelocationType.COMPANY_RELOCATION],
  bookingTour: DEFAULT_BOOKING_TOUR,
};
