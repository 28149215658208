import React, { FC } from 'react';
import { Box } from '@mui/material';
import ActionImg from '../../../../../components/shared/ActionImg';
import { ScheduledPartnerPauseResource } from '../../../../../api/scheduledPartnerPause/ScheduledPartnerPauseResource';
import { useFeatureSwitchContext } from '../../../../../utils/FeatureSwitchContext';

type ScheduledPauseTableRowProps = {
  isDeleting: boolean;
  onRemove: Function;
  scheduledPause: ScheduledPartnerPauseResource;
};
const ScheduledPauseTableRow: FC<ScheduledPauseTableRowProps> = ({ isDeleting, onRemove, scheduledPause }) => {
  const { isEditScheduledPauseDisabled } = useFeatureSwitchContext();
  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
      <ActionImg
        icon={'/icons/trash.svg'}
        iconStyle={{ marginLeft: '25px', marginRight: '10px' }}
        alt={'Remove'}
        onClick={(event) => {
          if (!isDeleting && !isEditScheduledPauseDisabled) {
            event.stopPropagation();
            onRemove(scheduledPause);
          }
        }}
      />
    </Box>
  );
};

export default ScheduledPauseTableRow;
