import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Box, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { DateType, PaymentType, RelocationType } from '../../../../api/relocationRequest/RelocationRequestDetail';
import DatePicker from '../../../../components/form/DatePicker';
import { SelectField, SelectFieldOption } from '../../../../components/form/SelectField';
import Button from '../../../../components/form/Button';
import FilterLabelWithContent from './form/FilterLabelWithContent';
import CountryCodeSelectField from './form/CountryCodeSelectField';
import StandardFilterTextField from './form/StandardFilterTextField';

const keysToSelectFieldOption = (keys: string[], prefix: string): SelectFieldOption[] => {
  const camelCase = (text: string) => text.toLowerCase().replace(/_./g, (x) => x[1].toUpperCase());
  return keys.map((key) => ({
    key: key,
    value: key,
    text: `${prefix}.${camelCase(key)}`,
  }));
};

export type RelocationRequestFiltersProps = {
  loading?: boolean;
};
const RelocationRequestFilters = ({ loading }: RelocationRequestFiltersProps) => {
  const formik = useFormikContext();
  const { t } = useTranslation('algotest');
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '1021px' }}>
      <Box sx={{ display: 'flex', alignItems: 'end' }}>
        <FilterLabelWithContent type={'date'}>
          <Box>
            <SelectField
              id={'flexibleDate'}
              onChange={formik?.handleChange}
              sx={{ minWidth: '120px' }}
              useTranslation={'algotest'}
              initialValue={formik.getFieldProps('flexibleDate').value || DateType.EXACT}
              options={keysToSelectFieldOption(Object.keys(DateType), 'form.flexibleDates')}
            />
            <DatePicker
              sx={{ marginTop: '5px !important' }}
              initialValue={formik.getFieldProps('date').value}
              onChange={(date) => formik?.setFieldValue('date', date)}
            />
          </Box>
        </FilterLabelWithContent>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'end' }}>
        <CountryCodeSelectField type={'fromCountry'} />
        <StandardFilterTextField type={'fromPostalCode'} hideLabel />
        <StandardFilterTextField type={'fromCity'} hideLabel isFlexGrow />
        <CountryCodeSelectField type={'toCountry'} />
        <StandardFilterTextField type={'toPostalCode'} hideLabel />
        <StandardFilterTextField type={'toCity'} hideLabel isFlexGrow />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'end' }}>
        <FilterLabelWithContent type={'relocationType'}>
          <SelectField
            id={'relocationType'}
            onChange={formik?.handleChange}
            sx={{ minWidth: '200px' }}
            useTranslation={'algotest'}
            initialValue={formik.getFieldProps('relocationType').value || 'RELOCATION'}
            options={keysToSelectFieldOption(Object.keys(RelocationType), 'form.relocationTypes')}
          />
        </FilterLabelWithContent>
        <StandardFilterTextField type={'squareMeters'} />
        <StandardFilterTextField type={'distance'} />

        <FilterLabelWithContent type={'payment'}>
          <SelectField
            id={'payment'}
            onChange={formik?.handleChange}
            sx={{ minWidth: '200px' }}
            useTranslation={'algotest'}
            initialValue={formik.getFieldProps('payment').value || 'PRIVATE'}
            options={keysToSelectFieldOption(Object.keys(PaymentType), 'form.payments')}
          />
        </FilterLabelWithContent>
        <StandardFilterTextField type={'affiliate'} isFlexGrow />
        <StandardFilterTextField type={'affiliateParameter'} isFlexGrow />

        <Box sx={{ padding: '10px' }}>
          <Button
            disabled={loading}
            type={'submit'}
            icon={
              loading ? (
                <CircularProgress size={20} color='inherit' sx={{ mr: '10px' }} />
              ) : (
                <SearchIcon sx={{ fontSize: '18px', marginRight: '10px' }} />
              )
            }
            label={t('form.search')}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RelocationRequestFilters;
