import { FC, ReactElement } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RelocationRequestDetail } from '../../../../../api/relocationRequest/RelocationRequestDetail';

export type AdditionalContactDataSubsectionProps = {
  relocationRequestDetail: RelocationRequestDetail;
};
const contactSectionKeyOf = (postfix: string): string => `requestDetails.detailsTab.sections.contact.${postfix}`;

const AdditionalContactDataSubsection: FC<AdditionalContactDataSubsectionProps> = ({
  relocationRequestDetail,
}: AdditionalContactDataSubsectionProps): ReactElement => {
  const { t } = useTranslation('relocationRequests');

  return (
    <Box>
      <Box>
        {relocationRequestDetail.distance ? (
          <>{t(contactSectionKeyOf('distance'), { distance: relocationRequestDetail.distance })}</>
        ) : (
          ''
        )}
      </Box>
      <Box>{t(contactSectionKeyOf('notOpenToThePublic'))} </Box>
      <Box>{t(contactSectionKeyOf('affiliate'), { partner: relocationRequestDetail.affiliatePartner })} </Box>
      <Box>
        {t(contactSectionKeyOf('affiliateParameter'), {
          param: relocationRequestDetail.affiliatePartnerParameter,
        })}{' '}
      </Box>
    </Box>
  );
};

export default AdditionalContactDataSubsection;
