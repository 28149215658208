import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Accordion, AccordionDetails, AccordionSummary, Box, styled } from '@mui/material';
import { getAlgotestSteps } from '../../../../api/algotest/AlgotestApi';
import Checkbox from '../../../../components/form/Checkbox';
import Label from '../../../../components/form/Label';
import { useDefaultTheme } from '../../../../themeConfig';

const useAlgotestStepsProvider = () => {
  const [steps, setSteps] = useState<string[]>([]);

  useEffect(() => {
    getAlgotestSteps().then((result) => setSteps(result));
  }, []);

  return { steps };
};
const StyledAccordion = styled(Accordion)(({ theme }) => ({
  [`&`]: {
    boxShadow: 'none',
    backgroundColor: theme.palette.common.white,
    overflowX: 'auto',
    margin: '10px !important',
    border: 'none',
    width: '100%',
    fontSize: '14px',
    fontStyle: 'normal',
    color: '#000',
    maxWidth: '1001px',
  },
}));

const StepOptions = (props: { checked: string[]; onCheckboxChecked: (stepName: string) => void }) => {
  const { steps } = useAlgotestStepsProvider();
  return (
    <>
      {steps.map((stepName) => (
        <Box key={stepName} sx={{ display: 'flex', columnGap: '10px', width: '30%' }}>
          <Checkbox
            id={stepName}
            onChange={() => props.onCheckboxChecked(stepName)}
            checked={props.checked.indexOf(stepName) !== -1}
          />
          <Label label={stepName} />
        </Box>
      ))}
    </>
  );
};

const AlgotestDisabledSteps: FC = (): ReactElement => {
  const [isExpanded, setExpanded] = useState(false);
  const [disabledSteps, setDisabledSteps] = useState<string[]>([]);
  const theme = useDefaultTheme();
  const formik = useFormikContext();

  const { t } = useTranslation('algotest');

  const handleOnStepChanged = (stepName: string) => {
    setDisabledSteps((prevState) => {
      const prevStateIndex = prevState.indexOf(stepName);
      return prevStateIndex === -1 ? [...prevState, stepName] : prevState.filter((step) => step !== stepName);
    });
  };

  useEffect(() => {
    formik.setFieldValue('disabledSteps', disabledSteps, true);
    // eslint-disable-next-line
  }, [disabledSteps]);

  return (
    <StyledAccordion expanded={isExpanded} onChange={() => setExpanded((prevState) => !prevState)}>
      <AccordionSummary
        sx={{
          paddingLeft: '16px',
          paddingRight: '16px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ minWidth: '230px' }}>{t('disabledSteps.title')}</Box>
          {!isExpanded && disabledSteps.length === 0 && <Box sx={{ color: theme.palette.text.disabled }}>-</Box>}
          {!isExpanded && disabledSteps.length > 0 && (
            <Box sx={{ color: theme.palette.text.disabled }}>{disabledSteps.join(', ')}</Box>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap',
          }}
        >
          <StepOptions checked={disabledSteps} onCheckboxChecked={(stepName) => handleOnStepChanged(stepName)} />
        </Box>
      </AccordionDetails>
    </StyledAccordion>
  );
};
export default AlgotestDisabledSteps;
