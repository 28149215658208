import React, { FC } from 'react';
import { Grid2 } from '@mui/material';
import { useDefaultTheme } from '../../../../../themeConfig';

type SelfPauseHistoryRowProps = {
  formattedDay: string;
  formattedTime: string;
  status: string;
};
const SelfPauseHistoryRow: FC<SelfPauseHistoryRowProps> = ({ formattedDay, formattedTime, status }) => {
  const theme = useDefaultTheme();
  return (
    <Grid2
      container
      size={12}
      sx={{
        alignItems: 'center',
        fontSize: '14px',
        color: theme.umzugEasy.palette.darkGray,
        textAlign: 'center',
        paddingTop: '10px',
        paddingBottom: '10px',
        borderBottom: `0.5px solid ${theme.palette.primary.contrastText}`,
      }}
    >
      <Grid2 size={4}>{formattedDay}</Grid2>
      <Grid2 size={4}>{formattedTime}</Grid2>
      <Grid2 size={4}>{status}</Grid2>
    </Grid2>
  );
};

export default SelfPauseHistoryRow;
