import React from 'react';
import { styled } from '@mui/material/styles';
import { chipClasses } from '@mui/material';
import StyledChip from '../../components/shared/StyledChip';

const ChipIcon = styled(StyledChip)<{ component?: React.ElementType }>(({ theme }) => ({
  height: '42px',
  width: '42px',
  borderRadius: '21px',
  ':hover': {
    backgroundColor: theme.palette.secondary.light,
  },
  [`& .${chipClasses.icon}`]: {
    margin: 0,
  },
  [`& .${chipClasses.label}`]: {
    padding: 0,
  },
}));

export default ChipIcon;
