import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios, { AxiosError } from 'axios';
import { Box, Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import { getContractsByPartnerCwid } from '../../api/contract/ContractsApi';
import { PartnerResource, PartnerStatus } from '../../api/partner/PartnerResource';
import { getPartner } from '../../api/partner/PartnerApi';
import { ContractResource } from '../../api/contract/ContractResourcePage';
import {
  getPartnerSettings,
  savePartnerSettings,
  setHasOverduePayments,
  setDeactivationDate,
  getPartnerStatus,
} from '../../api/partnerSettings/PartnerSettingsApi';
import {
  PartnerSettingsOverduePaymentsRequest,
  PartnerSettingsRequest,
  PartnerSettingsResource,
} from '../../api/partnerSettings/PartnerSettingsResource';
import PartnerNotFoundError from './components/mainData/PartnerNotFoundError';
import FlatButton from '../../components/form/FlatButton';
import LoadableComponent from '../../components/feedback/LoadableComponent';
import ContentNavigation from '../../components/navbar/ContentNavigation';
import ActionImg from '../../components/shared/ActionImg';
import { Routes, useActiveRoute } from '../../routes/Routes';
import { isContractValid } from '../../services/ContractService';
import { isPartnerOnline } from '../../services/PartnerService';
import PartnerHeader from './shared/PartnerHeader';
import { PartnerDetailContext } from './shared/PartnerContext';

const PartnerNavigation = (props: { cwid: string }) => {
  const { t } = useTranslation('partner');
  const value: { [key: string]: string } = {
    ':cwid': props.cwid,
  };
  return (
    <ContentNavigation value={value}>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <ActionImg
          icon={'/icons/comment.svg'}
          tooltip={t('details.comment')}
          iconStyle={{ width: '20px' }}
          onClick={() => console.log('show comments')}
        />
      </Box>
    </ContentNavigation>
  );
};

const PartnerDetails: FC = (): ReactElement => {
  const { t } = useTranslation('partner');
  const theme: Theme = useTheme();
  const { cwid } = useParams();
  const navigate = useNavigate();
  const { child } = useActiveRoute();
  const [partner, setPartner] = useState<PartnerResource | undefined>(undefined);
  const [partnerSettings, setPartnerSettings] = useState<PartnerSettingsResource | undefined>(undefined);
  const [contracts, setContracts] = useState<ContractResource[] | undefined>(undefined);
  const [isPartnerLoading, setIsPartnerLoading] = useState<boolean>(true);
  const [areSettingLoading, setAreSettingsLoading] = useState<boolean>(true);
  const [areContractsLoading, setAreContractsLoading] = useState<boolean>(true);
  const [error, setError] = useState<JSX.Element | undefined>(undefined);
  const [partnerStatus, setPartnerStatus] = useState<PartnerStatus | undefined>(undefined);

  const isLoading = () => isPartnerLoading || areSettingLoading || areContractsLoading;

  const getValidContracts: () => ContractResource[] = () =>
    contracts ? contracts.filter((c) => isContractValid(c)) : [];

  const isPartnerOnlineAndDefined: boolean =
    (partner && partnerSettings && isPartnerOnline(partner, partnerSettings, getValidContracts())) || false;

  const assignPartnerToGroup = (groupId?: number) => {
    setPartner((prevPartner) => {
      if (!prevPartner) {
        return undefined;
      } else {
        return {
          ...prevPartner!!,
          groupOfPartnersId: groupId,
        };
      }
    });
  };

  const updatePartnerSettings = async (cwid: string, newSettings: PartnerSettingsRequest) => {
    const savedSettings = await savePartnerSettings(cwid, newSettings).finally(() => getPartnerData(cwid));
    setPartnerSettings(savedSettings);
  };

  const toggleHasOverduePayments = async (cwid: string, request: PartnerSettingsOverduePaymentsRequest) => {
    const updatedSettings = await setHasOverduePayments(cwid, request).finally(() => getPartnerData(cwid));
    setPartnerSettings(updatedSettings);
  };

  const resetDeactivationDate = async (cwid: string) => {
    const updatedSettings = await setDeactivationDate(cwid).finally(() => getPartnerData(cwid));
    setPartnerSettings(updatedSettings);
  };

  const getPartnerStatusData = async (cwid: string) => {
    const statusData: PartnerStatus = await getPartnerStatus(cwid);
    statusData && setPartnerStatus(statusData);
  };

  const getPartnerData = (cwid: string) => {
    getPartner(cwid)
      .then((partner: PartnerResource) => {
        setPartner(partner);
        setIsPartnerLoading(false);
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          // Possibly user was deleted when someone tried accessing this page or uses old link
          if (error.response?.status === 404) {
            setError(<PartnerNotFoundError />);
            setIsPartnerLoading(false);
          }
        }
        // Error without known handling, e.g. 500
        // TODO navigate to error page which will log and report the error
      });
  };
  const assignNewPartnerSettings = (newSettings: PartnerSettingsResource) => {
    setPartnerSettings(newSettings);
  };

  useEffect(() => {
    if (isPartnerLoading || cwid !== partner?.cwid) {
      if (cwid) {
        getPartnerData(cwid);
        getPartnerStatusData(cwid);
      } else {
        // No CWID in URL means invalid link or changed routes config. Different handling?
        navigate(Routes.MAIN_PAGE);
      }
    }
    // eslint-disable-next-line
  }, [cwid, navigate]);

  useEffect(() => {
    if (areContractsLoading || cwid !== partner?.cwid) {
      if (cwid) {
        getContractsByPartnerCwid(cwid).then((contracts) => {
          setContracts(contracts);
          setAreContractsLoading(false);
        });
      } else {
        // No CWID in URL means invalid link or changed routes config. Different handling?
        navigate(Routes.MAIN_PAGE);
      }
    }
    // eslint-disable-next-line
  }, [cwid, navigate]);

  useEffect(() => {
    if (areSettingLoading || cwid !== partner?.cwid) {
      if (cwid) {
        getPartnerSettings(cwid).then((settings) => {
          setPartnerSettings(settings);
          setAreSettingsLoading(false);
        });
      } else {
        // No CWID in URL means invalid link or changed routes config. Different handling?
        navigate(Routes.MAIN_PAGE);
      }
    }
    // eslint-disable-next-line
  }, [cwid, navigate]);

  return (
    <PartnerDetailContext.Provider
      value={{
        partner,
        partnerSettings,
        partnerContracts: contracts,
        isLoading: isLoading(),
        error,
        isPartnerOnline: isPartnerOnlineAndDefined,
        getValidContracts,
        assignPartnerToGroup,
        updatePartnerSettings,
        toggleHasOverduePayments,
        resetDeactivationDate,
        assignNewPartnerSettings,
        partnerStatusData: partnerStatus,
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'block',
          color: 'black',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: theme.breakpoints.values.lg,
          width: '100%',
          margin: 'auto',
          paddingBottom: '16px',
        }}
      >
        <FlatButton onClick={() => navigate(Routes.PARTNERS_LIST_PAGE)}>
          <img src={'/icons/arrow_back.svg'} alt={'back'} style={{ paddingRight: '0.75rem' }} />
          {t('mainData.buttons.toPartnerList')}
        </FlatButton>

        <LoadableComponent isLoading={isPartnerLoading} errorMessage={error}>
          <>
            {partner && (
              <PartnerHeader
                partnerName={partner?.name}
                accountOwner={partner?.companyDetails.owner}
                scoutId={partner?.scoutId}
                email={partner?.email}
                isActive={partnerStatus?.isActive}
                isQualitySealActive={partnerStatus?.isQualitySealActive}
                hasRecommendationSeal={partnerStatus?.hasRecommendationSeal}
                isTopPartner={partnerStatus?.isTopPartner}
                isSubscribed={partnerStatus?.shop?.isSubscribed}
                leadsCountLast12Months={partnerStatus?.shop?.leadsCountLast12Months}
              />
            )}
          </>
          <>{partner?.cwid && <PartnerNavigation cwid={partner.cwid} />}</>
          <>{child?.nestedComponent && <child.nestedComponent />}</>
        </LoadableComponent>
      </Box>
    </PartnerDetailContext.Provider>
  );
};

export default PartnerDetails;
