import React, { ReactElement, useState, useImperativeHandle, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import { TableBody, TableHead, TableRow } from '@mui/material';
import MultipleRowSkeleton from '../../../components/feedback/MultipleRowSkeleton';
import {
  DecimalTableCell,
  IntegerTableCell,
  StyledTableCell,
  StyledTableRow,
} from '../../../components/reportTable/ReportTableComponents';
import {
  CountryRequestSourceStatistics,
  getRequestSourceStatisticsGroupedBySource,
  getAggregatedStatistics,
} from './RequestSourcesStatisticsService';

type RequestSourcesReportProps = {
  startDate: Date;
  endDate: Date;
  countryCodes: string[];
};

export type RequestSourcesReportRef = {
  fetchStatistics: () => void;
};

const RequestSourcesReport = forwardRef<RequestSourcesReportRef, RequestSourcesReportProps>(
  ({ startDate, endDate, countryCodes }, ref): ReactElement | null => {
    const { t } = useTranslation('reports');
    const headerOf = (column: string) => t(`partner.header.${column}`);
    const [countrySourceStatistics, setStatistics] = useState<Record<string, CountryRequestSourceStatistics[]>>({});
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isVisible, setVisible] = useState<boolean>(false);

    const headers = [
      'requestSource',
      'countryCode',
      'requests',
      'revenuePerRequest',
      'leads',
      'revenuePerLead',
      'multiplier',
      'totalRevenue',
    ].map(headerOf);

    const fetchStatistics = async (): Promise<void> => {
      setLoading(true);
      try {
        setStatistics(await getRequestSourceStatisticsGroupedBySource(countryCodes, startDate, endDate));
        setVisible(true);
      } finally {
        setLoading(false);
      }
    };

    useImperativeHandle(ref, () => ({
      fetchStatistics,
    }));

    if (!isVisible) {
      return null;
    }

    if (isLoading)
      return <MultipleRowSkeleton rows={5} fontSize={'35px'} styles={{ height: '200px', width: '640px' }} />;

    const renderCountryStatistics = (countryStatistics: CountryRequestSourceStatistics) => (
      <>
        <StyledTableCell>{countryStatistics.countryCode}</StyledTableCell>
        <IntegerTableCell value={countryStatistics.requestCount} />
        <DecimalTableCell value={countryStatistics.revenuePerRequest} />
        <IntegerTableCell value={countryStatistics.leadCount} />
        <DecimalTableCell value={countryStatistics.revenuePerLead} />
        <DecimalTableCell value={countryStatistics.multiplier} />
        <DecimalTableCell value={countryStatistics.revenue} />
      </>
    );

    const renderTotalCountryStatistics = (source: string) => {
      const totalStatistics = getAggregatedStatistics(countrySourceStatistics[source]);
      return (
        <>
          <StyledTableCell sx={{ fontWeight: 'bold' }}>{t('partner.aggregationLabel')}</StyledTableCell>
          <IntegerTableCell value={totalStatistics.totalRequests} />
          <DecimalTableCell value={totalStatistics.revenuePerRequest} />
          <IntegerTableCell value={totalStatistics.totalLeads} />
          <DecimalTableCell value={totalStatistics.revenuePerLead} />
          <DecimalTableCell value={totalStatistics.multiplier} />
          <DecimalTableCell value={totalStatistics.totalRevenue} />
        </>
      );
    };

    return (
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <StyledTableCell sx={{ height: '12px' }}>{header}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(countrySourceStatistics).map((sourceKey: string) => (
            <>
              <StyledTableRow>
                <StyledTableCell rowSpan={countrySourceStatistics[sourceKey].length + 1}>{sourceKey}</StyledTableCell>
                {renderCountryStatistics(countrySourceStatistics[sourceKey][0])}
              </StyledTableRow>
              {countrySourceStatistics[sourceKey].slice(1).map((countryStatistics: CountryRequestSourceStatistics) => (
                <StyledTableRow key={countryStatistics.countryCode}>
                  {renderCountryStatistics(countryStatistics)}
                </StyledTableRow>
              ))}
              {renderTotalCountryStatistics(sourceKey)}
            </>
          ))}
        </TableBody>
      </Table>
    );
  },
);

export default RequestSourcesReport;
