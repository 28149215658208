import React from 'react';
import LabeledField from '../../../../components/form/LabeledField';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { usePartnerContext } from '../../shared/PartnerContext';

const PartnerIdentification: React.FC = () => {
  const { t } = useTranslation('partner');
  const { partner } = usePartnerContext();

  return (
    <Grid container data-testid='partner-identification'>
      <Grid container item xs={12} md={6}>
        <LabeledField label={t('mainData.labels.owner')} children={<>{partner?.companyDetails?.owner}</>} />
        <LabeledField label={t('mainData.labels.customerNumber')} children={<>{partner?.scoutId}</>} />
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={6}
        sx={{
          alignItems: 'flex-start',
        }}
      >
        <LabeledField label={t('mainData.labels.accountCwid')} children={<>{partner?.cwid}</>} />
      </Grid>
    </Grid>
  );
};

export default PartnerIdentification;
