import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MultipleRowSkeleton from '../../../../components/feedback/MultipleRowSkeleton';
import RelocationRequestAnonymizationLink from './RelocationRequestAnonymizationLink';
import RelocationRequestDetailSection from '../RelocationRequestDetailSection';
import { RelocationRequestDetailTabsProps } from '../RelocationRequestDetailTabs';
import RelocationTypeSubsection from './subsections/RelocationTypeSubsection';
import ContactDataSubsection from './subsections/ContactDataSubsection';
import AdditionalContactDataSubsection from './subsections/AdditionalContactDataSubsection';
import RelocationFromSubsection from './subsections/RelocationFromSubsection';
import RelocationToSubsection from './subsections/RelocationToSubsection';
import RelocationLeadsSubsection from './subsections/RelocationLeadsSubsection';
import RelocationInventorySubsection from './subsections/RelocationInventorySubsection';

export type CustomStyles = {
  detailsTab?: React.CSSProperties;
  sections?: React.CSSProperties;
  actionsBar?: React.CSSProperties;
  link?: React.CSSProperties;
};

export type RelocationRequestDetailDetailsTabProps = RelocationRequestDetailTabsProps & {
  customStyles?: CustomStyles;
};

const relocationRequestDetailDetailsTabStyles = makeStyles(() => ({
  detailsTab: {
    display: 'flex',
    flexDirection: 'column',
  },
  sections: {
    height: 'calc(100vh - 500px)',
    overflowY: 'auto',
    paddingTop: '20px',
  },
}));

const RelocationRequestDetailDetailsTab: FC<RelocationRequestDetailDetailsTabProps> = (props): ReactElement => {
  const classes = relocationRequestDetailDetailsTabStyles();
  const { t } = useTranslation('relocationRequests');
  const keyPrefix = 'requestDetails.detailsTab';
  const sectionKeyOf = (infix: string): string => `${keyPrefix}.sections.${infix}.header`;
  const sectionTranslationOfKey = (infix: string): string => t(sectionKeyOf(infix));
  const displaySkeletonOnLoadingOr = (reactElement: ReactElement, rows?: number) => {
    return props.loading ? (
      <MultipleRowSkeleton rows={rows || 4} fontSize='14px' styles={{ width: '250px', marginRight: '20px' }} />
    ) : (
      reactElement
    );
  };

  return props.relocationRequestDetail ? (
    <Box className={classes.detailsTab} sx={props.customStyles?.detailsTab}>
      <Box className={classes.sections} sx={props.customStyles?.sections}>
        <RelocationRequestDetailSection
          header={sectionTranslationOfKey('relocationType')}
          leftContent={displaySkeletonOnLoadingOr(
            <RelocationTypeSubsection relocationRequestDetail={props.relocationRequestDetail} />,
            2,
          )}
          rightContent={displaySkeletonOnLoadingOr(
            <RelocationInventorySubsection relocationRequestDetail={props.relocationRequestDetail} />,
            2,
          )}
        />
        <RelocationRequestDetailSection
          header={sectionTranslationOfKey('contact')}
          leftContent={displaySkeletonOnLoadingOr(
            <ContactDataSubsection relocationRequestDetail={props.relocationRequestDetail} />,
          )}
          rightContent={displaySkeletonOnLoadingOr(
            <AdditionalContactDataSubsection relocationRequestDetail={props.relocationRequestDetail} />,
          )}
        />
        <RelocationRequestDetailSection
          header={sectionTranslationOfKey('fromTo')}
          leftContent={displaySkeletonOnLoadingOr(
            <RelocationFromSubsection relocationRequestDetail={props.relocationRequestDetail} />,
            15,
          )}
          rightContent={displaySkeletonOnLoadingOr(
            <RelocationToSubsection relocationRequestDetail={props.relocationRequestDetail} />,
            15,
          )}
        />
        <RelocationRequestDetailSection
          header={sectionTranslationOfKey('leads')}
          leftContent={displaySkeletonOnLoadingOr(<RelocationLeadsSubsection leads={props.leads || []} />)}
        />
      </Box>
      <RelocationRequestAnonymizationLink
        onAnonymizeRelocationRequest={props.onAnonymizeRelocationRequest}
        anonymizationConfirmationModal={props.anonymizationConfirmationModal}
        isAnonymizingRelocationRequest={props.isAnonymizingRelocationRequest}
        actionsBarStyle={props.customStyles?.actionsBar}
        linkStyle={props.customStyles?.link}
      />
    </Box>
  ) : (
    <Box />
  );
};
export default RelocationRequestDetailDetailsTab;
