import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { Box } from '@mui/material';
import { BookingTour } from '../../../../../../api/booking/BookingResource';
import TourFlex from './TourFlex';
import TourListItem from './TourListItem';

const TourList: FC = () => {
  const formik = useFormikContext();
  const fieldProps = formik.getFieldProps<BookingTour[]>('bookingTours');
  return (
    <Box sx={{ flexGrow: 1, overflowY: 'auto', overflowX: 'hidden' }}>
      {fieldProps.value.map((tour) => (
        <TourFlex
          key={tour.uuid}
          left={<TourListItem key={`${tour.uuid}-left`} tour={tour} isFrom={true} />}
          right={<TourListItem key={`${tour.uuid}-right`} tour={tour} isFrom={false} />}
        />
      ))}
    </Box>
  );
};
export default TourList;
