import React, { ReactElement } from 'react';
import { Box, CircularProgress } from '@mui/material';
import ActionImg from '../../../../components/shared/ActionImg';

type DocumentsActionsProps = {
  onRemove: () => void;
  onEdit: () => void;
  isSaving?: boolean;
};

const DocumentsActions: React.FC<DocumentsActionsProps> = ({
  onRemove,
  onEdit,
  isSaving,
}: DocumentsActionsProps): ReactElement => {
  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
      <ActionImg
        icon={'/icons/trash.svg'}
        iconStyle={{ marginLeft: '25px' }}
        onClick={(event) => {
          event.stopPropagation();
          onRemove();
        }}
      />
      {isSaving ? (
        <CircularProgress size={20} color='inherit' sx={{ marginLeft: '25px' }} />
      ) : (
        <ActionImg
          icon={'/icons/pencil.svg'}
          iconStyle={{ marginLeft: '25px' }}
          onClick={(event) => {
            event.stopPropagation();
            onEdit();
          }}
        />
      )}
    </Box>
  );
};
export default DocumentsActions;
