import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { ScheduledPartnerPauseResource } from '../../../../../api/scheduledPartnerPause/ScheduledPartnerPauseResource';
import {
  getScheduledPartnerPause,
  addScheduledPartnerPause,
} from '../../../../../api/scheduledPartnerPause/ScheduledPartnerPauseApi';
import AddScheduledPauseForm from './AddScheduledPauseForm';
import MultipleRowSkeleton from '../../../../../components/feedback/MultipleRowSkeleton';
import Alert, { AlertProps } from '../../../../../components/feedback/Alert';
import OpenScheduledPauseFormButton from './OpenScheduledPauseFormButton';
import { usePartnerContext } from '../../../shared/PartnerContext';
import ScheduledPauseTable from './ScheduledPauseTable';
import { formatToIsoTextWithLocalDate } from '../../../../../utils/DateUtil';

const ScheduledPause: React.FC = () => {
  const { partner } = usePartnerContext();
  const { t } = useTranslation('dashboard');
  const [scheduledPauses, setScheduledPauses] = useState<ScheduledPartnerPauseResource[]>([]);
  const [isLoadingScheduledPause, setLoadingScheduledPause] = useState(false);
  const [alert, setAlert] = useState<AlertProps | undefined>(undefined);
  const [startDate, setStartDate] = useState<Date | null | undefined>();
  const [endDate, setEndDate] = useState<Date | null | undefined>();
  const [showScheduledPauseForm, setShowScheduledPauseForm] = useState<boolean>(false);

  const partnerCwid = partner?.cwid;

  useEffect(() => {
    if (partnerCwid) {
      setLoadingScheduledPause(true);
      getScheduledPartnerPause(partnerCwid)
        .then((scheduledPause) => {
          setScheduledPauses(scheduledPause);
        })
        .finally(() => setLoadingScheduledPause(false));
    }
  }, [partnerCwid]);

  const showAlert = (translationKey: string) => {
    setAlert({
      color: 'error',
      open: true,
      autoHideDuration: 5000,
      text: t(`pause.schedule.alerts.${translationKey}`),
      handleClose: () => setAlert(undefined),
    });
  };

  const onScheduledPause = () => {
    setShowScheduledPauseForm(true);
    setStartDate(undefined);
    setEndDate(undefined);
  };

  const addScheduledPause = () => {
    if (partnerCwid) {
      if (!startDate) {
        showAlert('startDateEmpty');
        return;
      }
      if (endDate && endDate <= startDate) {
        showAlert('endDateBeforeStartDate');
        return;
      }

      const formattedStartDate = formatToIsoTextWithLocalDate(startDate);
      const formattedEndDate = endDate ? formatToIsoTextWithLocalDate(endDate) : undefined;

      addScheduledPartnerPause(partnerCwid, { start: formattedStartDate, end: formattedEndDate })
        .then((response) => {
          const scheduledPausesAfterSavingNew = scheduledPauses
            .concat(response)
            .sort((a, b) => (a.changeAt > b.changeAt ? -1 : 1));
          setScheduledPauses(scheduledPausesAfterSavingNew);
        })
        .catch(() => {
          showAlert('createScheduledPartnerPause');
        })
        .finally(() => {
          setShowScheduledPauseForm(false);
        });
    }
  };

  const openScheduledPauseFormButton: JSX.Element = (
    <OpenScheduledPauseFormButton onScheduledPause={onScheduledPause} label={t('pause.schedule.newPause')} />
  );

  const newScheduledPauseForm: JSX.Element = (
    <AddScheduledPauseForm setStartDate={setStartDate} setEndDate={setEndDate} addScheduledPause={addScheduledPause} />
  );

  const displaySkeletonOnLoadingOr = (reactElement: ReactElement) => {
    return isLoadingScheduledPause ? (
      <MultipleRowSkeleton
        dataTestid={'loading-skeleton'}
        rows={3}
        fontSize='30px'
        styles={{ width: '100%', lineHeight: 1.5 }}
      />
    ) : (
      reactElement
    );
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          paddingTop: '40px',
          paddingLeft: '25px',
        }}
      >
        {displaySkeletonOnLoadingOr(
          <ScheduledPauseTable
            scheduledPauses={scheduledPauses}
            setScheduledPauses={setScheduledPauses}
            showAlert={showAlert}
          />,
        )}
        {showScheduledPauseForm ? newScheduledPauseForm : openScheduledPauseFormButton}
      </Box>
      <Alert text={''} {...alert} />
    </>
  );
};

export default ScheduledPause;
