import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Grid, Theme } from '@mui/material';

export type LoadableComponentProps = {
  isLoading: boolean;
  errorMessage?: JSX.Element | string;
  children: JSX.Element[];
  styles?: {
    loadingContainer?: React.CSSProperties;
    childContainer?: React.CSSProperties;
    errorContainer?: React.CSSProperties;
  };
};

const containerStyles: (theme: Theme) => React.CSSProperties = (theme) => ({
  maxWidth: theme.breakpoints.values.lg,
  margin: 'auto',
  paddingTop: '3rem',
});

const loadableComponentStyles = makeStyles((theme: Theme) => ({
  loadingContainer: {
    ...containerStyles(theme),
    color: theme.palette.text.secondary,
    fontSize: '1.5rem',
  },
  paddedContainer: {
    padding: '0.5rem',
  },
  childContainer: {
    ...containerStyles(theme),
  },
  errorContainer: {
    ...containerStyles(theme),
    color: theme.palette.text.secondary,
    fontSize: '2.5rem',
    fontWeight: 'bold',
  },
}));

const LoadableComponent: React.FC<LoadableComponentProps> = (props: LoadableComponentProps) => {
  const { t } = useTranslation('common');

  const classes = loadableComponentStyles();

  if (props.isLoading) {
    return (
      <Grid container className={classes.loadingContainer} style={props.styles?.loadingContainer}>
        <Grid
          container
          className={classes.paddedContainer}
          sx={{
            justifyContent: 'center',
          }}
        >
          <CircularProgress color={'inherit'} size={'3rem'} />
        </Grid>
        <Grid
          container
          className={classes.paddedContainer}
          sx={{
            justifyContent: 'center',
          }}
        >
          {t('loadingData')}
        </Grid>
      </Grid>
    );
  }

  if (props.errorMessage) {
    return (
      <Grid
        container
        className={classes.errorContainer}
        style={props.styles?.errorContainer}
        sx={{
          justifyContent: 'center',
        }}
      >
        {props.errorMessage}
      </Grid>
    );
  }

  return (
    <Grid container className={classes.childContainer} style={props.styles?.childContainer}>
      {props.children}
    </Grid>
  );
};

export default LoadableComponent;
