import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { isAfter, parseISO } from 'date-fns';
import { ToggleHistoryResource } from '../../../../../api/toggleHistory/ToggleHistoryResource';
import { SelfPauseHistoryResource } from '../../../../../api/selfPauseHistory/SelfPauseHistoryResource';
import Label from '../../../../../components/form/Label';
import { formatToLocaleTextWithDateTime } from '../../../../../utils/DateUtil';

type OnlineReasonProps = {
  toggleHistory: ToggleHistoryResource[];
  selfPauseHistory: SelfPauseHistoryResource[];
};

const isToggleHistoryOnlineReason = (th: ToggleHistoryResource) => {
  return (
    (th.propertyName === 'IS_ACTIVE_IN_CRM' && th.newValue === 'true') ||
    (th.propertyName === 'PAUSED_BY_CUSTOMER_CARE' && th.newValue === 'false') ||
    (th.propertyName === 'DEACTIVATION_BECAUSE_OF_DUNNING' && th.newValue === 'false')
  );
};

const OnlineReason: FC<OnlineReasonProps> = ({ toggleHistory, selfPauseHistory }) => {
  const { t } = useTranslation('dashboard');

  const formatDateTimeIfAvailable = (date?: Date | string) => {
    return date ? formatToLocaleTextWithDateTime(date) : t('onlineReason.dateTimeNotAvailable');
  };

  const renderLabelOf = (reason: string) => <Label dataTestid='onlineReason' label={reason} />;

  const getLatestOnlineToggleHistoryReason = (toggleHistoryReason: ToggleHistoryResource): string => {
    if (toggleHistoryReason.propertyName === 'IS_ACTIVE_IN_CRM')
      return t('onlineReason.activeInCrm', { dateTime: formatDateTimeIfAvailable(toggleHistoryReason.modifiedAt) });
    if (toggleHistoryReason.propertyName === 'PAUSED_BY_CUSTOMER_CARE')
      return t('onlineReason.unpausedByCustomerCare', {
        dateTime: formatDateTimeIfAvailable(toggleHistoryReason.modifiedAt),
      });
    if (toggleHistoryReason.propertyName === 'DEACTIVATION_BECAUSE_OF_DUNNING')
      return t('onlineReason.noOverduePayments', {
        dateTime: formatDateTimeIfAvailable(toggleHistoryReason.modifiedAt),
      });
    return '';
  };

  const getLatestLastSelfUnpauseReason = (selfPauseHistoryReason: SelfPauseHistoryResource): string => {
    return t('onlineReason.selfUnpaused', { dateTime: formatDateTimeIfAvailable(selfPauseHistoryReason.changeDate) });
  };
  const toggleHistoryReason = toggleHistory
    .filter(isToggleHistoryOnlineReason)
    .sort((th1, th2) => parseISO(th1.modifiedAt).valueOf() - parseISO(th2.modifiedAt).valueOf())
    .slice(-1)[0];
  const lastSelfUnPause = selfPauseHistory
    .filter((sph) => !sph.paused)
    .sort((sp1, sp2) => parseISO(sp1.changeDate).valueOf() - parseISO(sp2.changeDate).valueOf())
    .slice(-1)[0];
  if (toggleHistoryReason && lastSelfUnPause) {
    if (isAfter(parseISO(toggleHistoryReason.modifiedAt), parseISO(lastSelfUnPause.changeDate))) {
      return renderLabelOf(getLatestOnlineToggleHistoryReason(toggleHistoryReason));
    }
    return renderLabelOf(getLatestLastSelfUnpauseReason(lastSelfUnPause));
  }
  if (toggleHistoryReason) {
    return renderLabelOf(getLatestOnlineToggleHistoryReason(toggleHistoryReason));
  }
  if (lastSelfUnPause) {
    return renderLabelOf(getLatestLastSelfUnpauseReason(lastSelfUnPause));
  }
  return renderLabelOf('');
};

export default OnlineReason;
