import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { IPublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import './App.css';
import '@fontsource/open-sans/index.css';
import { getMsalInstance } from './authConfig';
import Layout from './components/layout/Layout';
import Login from './pages/Login';
import { routes as appRoutes } from './routes/Routes';
import { useDefaultTheme } from './themeConfig';
import { FeatureSwitchContext, FeatureSwitchProps } from './utils/FeatureSwitchContext';

// TODO replace css with styled components - https://jira.scout24.com/browse/R2UFC-416
function App() {
  const [msalInstance, setMsalInstance] = useState<IPublicClientApplication | null>(null);
  const theme = useDefaultTheme();

  const routeList = appRoutes
    .map((route) => [route, ...(route.subRoutes || []), ...(route.subRoutesInContent || [])])
    .flat();

  const featureSwitchProps: FeatureSwitchProps = {
    isEditSelfPauseDisabled: false,
    isEditScheduledPauseDisabled: false,
    isEditPausedByCustomerCareDisabled: false,
    isReportsContentDisabled: process.env.REACT_APP_ENV === 'pro',
  };

  useEffect(() => {
    const initializeMsal = async () => {
      const instance = await getMsalInstance();
      setMsalInstance(instance);
    };
    initializeMsal();
  }, []);

  return (
    msalInstance && (
      <MsalProvider instance={msalInstance}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CssBaseline />
            <BrowserRouter>
              <Layout>
                <UnauthenticatedTemplate>
                  <Login />
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                  <FeatureSwitchContext.Provider value={featureSwitchProps}>
                    <Routes>
                      {routeList.map((route) => (
                        <Route key={route.key} path={route.path} element={<route.component />} />
                      ))}
                    </Routes>
                  </FeatureSwitchContext.Provider>
                </AuthenticatedTemplate>
              </Layout>
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </MsalProvider>
    )
  );
}

export default App;
