import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import { PartnerResource } from '../../../../../api/partner/PartnerResource';
import {
  ToggleHistoryPropertyName,
  ToggleHistoryResource,
} from '../../../../../api/toggleHistory/ToggleHistoryResource';
import { SelfPauseHistoryResource } from '../../../../../api/selfPauseHistory/SelfPauseHistoryResource';
import { PartnerSettingsResource } from '../../../../../api/partnerSettings/PartnerSettingsResource';
import { ContractResource } from '../../../../../api/contract/ContractResourcePage';
import Label from '../../../../../components/form/Label';
import { formatToLocaleTextWithDateTime } from '../../../../../utils/DateUtil';

type OfflineReasonProps = {
  partner: PartnerResource;
  partnerSettings: PartnerSettingsResource;
  validContracts: ContractResource[];
  toggleHistory: ToggleHistoryResource[];
  selfPauseHistory: SelfPauseHistoryResource[];
};

const OfflineReason: FC<OfflineReasonProps> = ({
  partner,
  partnerSettings,
  validContracts,
  toggleHistory,
  selfPauseHistory,
}) => {
  const { t } = useTranslation('dashboard');

  const getLatestToggleHistoryDateTimeForProperty = (
    toggleHistory: ToggleHistoryResource[],
    propertyName: ToggleHistoryPropertyName,
    newValue: string,
  ): string => {
    const latestProperyChange = toggleHistory
      .filter((th) => propertyName === th.propertyName && newValue === th.newValue)
      .sort((th1, th2) => parseISO(th1.modifiedAt).valueOf() - parseISO(th2.modifiedAt).valueOf())
      .slice(-1)[0];
    return latestProperyChange ? formatToLocaleTextWithDateTime(latestProperyChange.modifiedAt) : 'N/A';
  };

  const getLatestSelfPauseDateTime = (selfPauseHistory: SelfPauseHistoryResource[]) => {
    const lastSelfPause = selfPauseHistory
      .filter((sph) => sph.paused)
      .sort((sp1, sp2) => parseISO(sp1.changeDate).valueOf() - parseISO(sp2.changeDate).valueOf())
      .slice(-1)[0];
    return lastSelfPause
      ? formatToLocaleTextWithDateTime(lastSelfPause.changeDate)
      : t('offlineReason.dateTimeNotAvailable');
  };

  const renderLabelOf = (reasonTranslation: string, dateTime?: string) => (
    <Label dataTestid={'offlineReason'} label={t(reasonTranslation, { dateTime })} />
  );

  if (!partner.isActiveInCrm) {
    const dateTime = getLatestToggleHistoryDateTimeForProperty(toggleHistory, 'IS_ACTIVE_IN_CRM', 'false');
    return renderLabelOf('offlineReason.notActiveInCrm', dateTime);
  }
  if (partner.isSelfPaused) {
    const dateTime = getLatestSelfPauseDateTime(selfPauseHistory);
    return renderLabelOf('offlineReason.selfPaused', dateTime);
  }
  if (partner.isPausedByCustomerCare) {
    const dateTime = getLatestToggleHistoryDateTimeForProperty(toggleHistory, 'PAUSED_BY_CUSTOMER_CARE', 'true');
    return renderLabelOf('offlineReason.pausedByCustomerCare', dateTime);
  }
  if (validContracts.length === 0) {
    return renderLabelOf('offlineReason.invalidContract');
  }
  if (partnerSettings?.hasOverduePayments === true) {
    const dateTime = getLatestToggleHistoryDateTimeForProperty(
      toggleHistory,
      'DEACTIVATION_BECAUSE_OF_DUNNING',
      'true',
    );
    return renderLabelOf('offlineReason.overduePayments', dateTime);
  }
  return renderLabelOf('');
};

export default OfflineReason;
