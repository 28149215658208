import {
  formLabelClasses,
  inputBaseClasses,
  inputLabelClasses,
  styled,
  TextField as MuiTextField,
  textFieldClasses,
} from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';

const StyledTextField = styled(MuiTextField)(({ theme, ...props }) => ({
  [`&`]: {
    margin: '10px',
  },
  [`&.${textFieldClasses.root}`]: {
    borderRadius: '5px',
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    height: props.multiline ? undefined : '40px',
  },
  [`&.${textFieldClasses.root}:has(.Mui-disabled)`]: {
    backgroundColor: '#d9d9d9',
  },
  [`& .${inputBaseClasses.root}`]: {
    borderRadius: '5px',
    color: theme.palette.common.black,
    height: props.multiline ? undefined : '40px',
  },
  [`& .${textFieldClasses.root} > label`]: {
    display: 'none',
  },
  [`& .${inputBaseClasses.root} input, textarea`]: {
    fontSize: '14px',
  },
  [`& .${inputBaseClasses.root} fieldset`]: {
    borderColor: `${props.error ? theme.palette.error.main : 'transparent'} !important`,
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
  },
  [`& .${inputLabelClasses.root}`]: {
    fontSize: '14px',
    top: '-5px',
  },
  [`& .${inputLabelClasses.root}.${inputLabelClasses.focused}`]: {
    display: 'none',
  },
  [`& .${inputLabelClasses.root}.${formLabelClasses.filled}`]: {
    display: 'none',
  },
  [`& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button`]: {
    webkitAppearance: 'none',
    margin: 0,
  },
  [`& input[type=number]`]: {
    mozAppearance: 'textfield',
  },
}));
export const TextField = (props: TextFieldProps) => {
  return <StyledTextField {...props} inputProps={{ ...props.inputProps, autoComplete: 'off' }}></StyledTextField>;
};
