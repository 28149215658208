import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Theme, useTheme } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import StyledChip from '../../../components/shared/StyledChip';
import ChipIcon from '../../../components/shared/ChipIcon';
import { DateRangeType } from './useDateRanges';

type DateRangeTypePickerProps = {
  dateRangeType: DateRangeType;
  onClickPrevious: () => void;
  onClickNext: () => void;
  onDateRangeTypeChange: (dateRangeType: DateRangeType) => void;
};

const DateRangeTypePicker: FC<DateRangeTypePickerProps> = ({
  dateRangeType,
  onClickPrevious,
  onClickNext,
  onDateRangeTypeChange,
}) => {
  const theme = useTheme<Theme>();
  const reportsTranslations = useTranslation('reports').t;
  const createDateRangeTypeSetter = (dateRangeType: DateRangeType) => {
    return () => {
      onDateRangeTypeChange(dateRangeType);
    };
  };
  const chipColorOf = (chipDateRangeType: DateRangeType) => {
    return chipDateRangeType === dateRangeType ? 'primary' : 'default';
  };

  const dayRangeTypeSetter = createDateRangeTypeSetter('DAY');
  const weekRangeTypeSetter = createDateRangeTypeSetter('WEEK');
  const monthRangeTypeSetter = createDateRangeTypeSetter('MONTH');
  const yearRangeTypeSetter = createDateRangeTypeSetter('YEAR');

  return (
    <Box sx={{ display: 'flex', columnGap: '10px', justifyContent: { xs: 'center', lg: 'flex-start' } }}>
      <ChipIcon
        onClick={onClickPrevious}
        icon={<NavigateBeforeIcon sx={{ height: '26px', fill: theme.palette.common.white }} />}
      />
      <StyledChip
        color={chipColorOf('DAY')}
        label={reportsTranslations('dateRangeTypePicker.day')}
        onClick={dayRangeTypeSetter}
      />
      <StyledChip
        color={chipColorOf('WEEK')}
        label={reportsTranslations('dateRangeTypePicker.week')}
        onClick={weekRangeTypeSetter}
      />
      <StyledChip
        color={chipColorOf('MONTH')}
        label={reportsTranslations('dateRangeTypePicker.month')}
        onClick={monthRangeTypeSetter}
      />
      <StyledChip
        color={chipColorOf('YEAR')}
        label={reportsTranslations('dateRangeTypePicker.year')}
        onClick={yearRangeTypeSetter}
      />
      <ChipIcon
        onClick={onClickNext}
        icon={<NavigateNextIcon sx={{ height: '26px', fill: theme.palette.common.white }} />}
      />
    </Box>
  );
};

export default DateRangeTypePicker;
