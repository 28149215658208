import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Box, styled, Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import { Booking } from '../../../../api/booking/BookingResource';
import { useBookingContext } from './BookingContext';
import BookingDetails from './BookingDetails';
import BookingTitleAndActions from './BookingTitleAndActions';
import BookingTourList from './BookingTourList';
import ActionImg from '../../../../components/shared/ActionImg';
import Button from '../../../../components/form/Button';

export type BookingListItemProps = {
  booking: Booking;
};

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  [`&`]: {
    boxShadow: 'none',
    backgroundColor: theme.palette.background.default,
    overflowX: 'auto',
    border: 'none',
  },
}));

const BookingListItem: FC<BookingListItemProps> = ({ booking }: BookingListItemProps): ReactElement => {
  const { showDetails, actions } = useBookingContext();
  const [isExpanded, setExpanded] = useState(false);
  const { t } = useTranslation('bookings');
  useEffect(() => setExpanded(showDetails), [showDetails]);
  const theme = useTheme<Theme>();
  return (
    <StyledAccordion expanded={isExpanded} onChange={() => setExpanded((prevState) => !prevState)}>
      <AccordionSummary
        sx={{
          paddingLeft: '10px',
          paddingRight: '10px',
          '& .MuiAccordionSummary-content': {
            margin: '0 !important',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
          }}
        >
          <BookingTitleAndActions booking={booking} />
          {(showDetails || isExpanded) && <BookingDetails booking={booking} />}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <BookingTourList booking={booking} />
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            icon={<ActionImg icon={'/icons/plus.svg'} iconStyle={{ marginRight: '5px' }} />}
            label={t('booking.tours.list.newTour')}
            type={'button'}
            appearance={'link'}
            onClick={() => booking.id != null && actions.onTourAdd(booking.id)}
          />
        </Box>
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default BookingListItem;
