import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Popover, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import { SelectFieldIcon, StyledMenuItem, StyledSelect } from '../../../../../../components/form/SelectField';
import ActionImg from '../../../../../../components/shared/ActionImg';
import { TextField } from '../../../../../../components/form/TextField';
import { useShopSubscriptionSettingsContext } from './ShopSubscriptionSettingsContext';
import { getCountryCodes, getPopularCountryCodes } from '../../../../../../utils/CountryUtil';

const POSTAL_CODE_MAX_LENGTH = 3;

const ShopSubscribedRegionsPopover: React.FC = (): ReactElement => {
  const { selectedShopSubscribedRegion, selectedHtmlElement, actions } = useShopSubscriptionSettingsContext();
  const theme = useTheme<Theme>();
  const open = Boolean(selectedHtmlElement);
  const { t } = useTranslation('bookings');

  return (
    <Popover
      open={open}
      onClose={actions.onSaveShopSubscribedRegion}
      anchorEl={selectedHtmlElement}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{ sx: { overflowY: 'hidden' } }}
      data-testid={'shopSubscribedRegionsPopover'}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 15px', columnGap: '10px' }}>
        <Box>
          <RegionAndCountrySelect />
        </Box>
        {selectedShopSubscribedRegion?.country && (
          <Box>
            <TextField
              sx={{
                width: '90px',
                border: `1px solid ${theme.palette.background.default}`,
              }}
              defaultValue={selectedShopSubscribedRegion.postalCode}
              onChange={(event) => actions.onChangePostalCode(event.target.value)}
              placeholder={t('booking.edit.popover.selectCountryOrRegion.postalCode')}
              inputProps={{ maxLength: POSTAL_CODE_MAX_LENGTH }}
              data-testid={'shopSubscribedRegionsPopoverPostalCode'}
            />
          </Box>
        )}
        <Box sx={{ paddingLeft: '20px' }}>
          <ActionImg
            icon={'/icons/trash.svg'}
            onClick={() => actions.onRemoveShopSubscribedRegion()}
            data-testid={'shopSubscribedRegionsPopoverTrashIcon'}
          />
        </Box>
      </Box>
    </Popover>
  );
};

type SectionTitleProps = {
  children: string | JSX.Element;
  sx?: SxProps<Theme>;
  onClick?: () => void;
  isFirstScreen: boolean;
};
const SectionTitle = ({ children, sx, onClick, isFirstScreen }: SectionTitleProps) => (
  <>
    {isFirstScreen && (
      <Typography
        onClick={() => onClick && onClick()}
        sx={{
          padding: '8px 16px',
          fontWeight: 600,
          fontSize: '14px',
          fontStyle: 'normal',
          minHeight: '24px',
          ...(sx || {}),
        }}
      >
        {children}
      </Typography>
    )}
  </>
);

const RegionAndCountrySelect: React.FC = (): ReactElement => {
  const { selectedShopSubscribedRegion, allRegions, actions } = useShopSubscriptionSettingsContext();
  const [isFirstScreen, setFirstScreen] = useState(true);
  const popularCountryCodes = getPopularCountryCodes();
  const countryCodes = getCountryCodes();
  const { t } = useTranslation('bookings');

  useEffect(() => {
    const defaultValue = selectedShopSubscribedRegion?.country || selectedShopSubscribedRegion?.region?.id;
    if (defaultValue != null) {
      const isRegionPresentOnFirstScreen =
        typeof defaultValue === 'number' && allRegions.find((item) => item.id === defaultValue) != null;
      const isCountryPresentOnPopularCountriesList =
        typeof defaultValue === 'string' &&
        popularCountryCodes.find((item) => item.code === defaultValue.toUpperCase()) != null;
      setFirstScreen(isRegionPresentOnFirstScreen || isCountryPresentOnPopularCountriesList);
      actions.onChangeSelectedCountryOrRegion(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedShopSubscribedRegion?.country, selectedShopSubscribedRegion?.region]);

  const value = selectedShopSubscribedRegion?.country || selectedShopSubscribedRegion?.region?.id?.toString();

  return (
    <Box>
      <StyledSelect
        sx={{ minWidth: '120px' }}
        IconComponent={SelectFieldIcon}
        value={value}
        onOpen={() => {
          const shouldResetValue = !isFirstScreen;
          if (shouldResetValue) {
            actions.onChangeSelectedCountryOrRegion('');
          }
          setFirstScreen(true);
        }}
        onChange={(change) => actions.onChangeSelectedCountryOrRegion(change.target.value)}
        data-testid={'shopSubscribedRegionsPopoverSelect'}
      >
        <SectionTitle isFirstScreen={isFirstScreen}>
          {t('booking.edit.popover.selectCountryOrRegion.countries')}
        </SectionTitle>
        {isFirstScreen &&
          popularCountryCodes.map((item) => (
            <StyledMenuItem key={item.code} value={item.code}>
              {item.name}
            </StyledMenuItem>
          ))}
        <SectionTitle isFirstScreen={isFirstScreen}>
          {t('booking.edit.popover.selectCountryOrRegion.regions')}
        </SectionTitle>
        {isFirstScreen &&
          allRegions.map((item) => (
            <StyledMenuItem key={item.id} value={item.id}>
              {t(`booking.edit.regions.${item.name.toLowerCase()}`)}
            </StyledMenuItem>
          ))}
        <SectionTitle
          isFirstScreen={isFirstScreen}
          sx={{ cursor: 'pointer', display: 'flex', columnGap: '15px' }}
          onClick={() => setFirstScreen(false)}
        >
          <>
            {t('booking.edit.popover.selectCountryOrRegion.moreCountries')}{' '}
            <ActionImg icon={'/icons/chevron.svg'} iconStyle={{ transform: 'rotate(-90deg)' }} />
          </>
        </SectionTitle>
        {!isFirstScreen &&
          countryCodes.map((item) => (
            <StyledMenuItem key={item.code} value={item.code}>
              {item.name}
            </StyledMenuItem>
          ))}
      </StyledSelect>
    </Box>
  );
};

export default ShopSubscribedRegionsPopover;
