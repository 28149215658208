import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LabeledField from '../../../../../components/form/LabeledField';
import { BookingTestSummary } from '../../../../../components/table/schema/type/BookingTestResponseSchema';

interface ResultDataProps {
  summary: BookingTestSummary;
}

const ResultData: React.FC<ResultDataProps> = ({ summary }) => {
  const { t } = useTranslation('bookingTest');

  return (
    <Grid container>
      <Grid container item xs={12} md={20}>
        <LabeledField
          label={t('result.summary.numberOfRequests')}
          children={
            <>
              {summary.numberOfRequests +
                t('result.summary.numberOfRequestsSummary', {
                  numberOfUndistributedRequests: summary.numberOfUndistributedRequests,
                })}
            </>
          }
        />
        <LabeledField label={t('result.summary.numberOfPartners')} children={<>{summary.numberOfUniquePartners}</>} />
        <LabeledField label={t('result.summary.multiplier')} children={<>{summary.averageLeadsPerRequest}</>} />
      </Grid>
    </Grid>
  );
};

export default ResultData;
