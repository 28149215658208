import React, { FC, ReactElement, useEffect, useState } from 'react';
import BookingList from './bookings/BookingList';
import BookingSection from './bookings/BookingSection';
import { Box } from '@mui/material';
import { usePartnerContext } from '../shared/PartnerContext';
import { useInitBookingContext } from './bookings/BookingContext';
import { BookingContext } from './bookings/BookingContext.model';
import { useTranslation } from 'react-i18next';
import BookingModal from './bookings/modal/BookingModal';
import DeletionConfirmationModal from './bookings/modal/DeletionConfirmationModal';
import Alert from '../../../components/feedback/Alert';
import BookingsSettings from './bookings/bookingsSettings/BookingsSettings';
import { useInitBookingsSettingsContext } from './bookings/bookingsSettings/BookingsSettingsContext';
import { BookingsSettingsContext } from './bookings/bookingsSettings/BookingsSettingsContext.model';

const Bookings: FC = (): ReactElement => {
  const [partnerCwid, setPartnerCwid] = useState<string | undefined>(undefined);

  const { partner } = usePartnerContext();
  useEffect(() => setPartnerCwid(partner?.cwid), [partner?.cwid]);

  const { t } = useTranslation('bookings');
  const bookingCtx = useInitBookingContext(partnerCwid);
  const bookingsSettingsCtx = useInitBookingsSettingsContext(partnerCwid);

  return (
    <Box sx={{ width: '100%' }}>
      <BookingsSettingsContext.Provider value={bookingsSettingsCtx}>
        <BookingContext.Provider value={bookingCtx}>
          <BookingSection
            title={t('booking.bookingList.title')}
            action={{
              text: t('booking.bookingList.switchMode'),
              onClick: () => bookingCtx.actions.onShowDetailsInList(),
            }}
            isModalSection={false}
          >
            <BookingList />
          </BookingSection>
          <BookingsSettings />
          <BookingModal />
          {bookingCtx.deletionConfirmationModal && (
            <DeletionConfirmationModal {...bookingCtx.deletionConfirmationModal} />
          )}
          <Alert text={''} {...bookingCtx.alert} />
        </BookingContext.Provider>
      </BookingsSettingsContext.Provider>
    </Box>
  );
};

export default Bookings;
