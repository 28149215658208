import React, { FC, ReactElement } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const DateRangeHeader: FC = (): ReactElement => {
  const theme = useTheme<Theme>();
  const { t } = useTranslation('bookings');
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 10px',
        height: '54px',
        borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
      }}
    >
      <Typography sx={{ fontSize: '14px', color: theme.palette.text.secondary, fontStyle: 'italic' }}>
        {t('booking.bookingsSettings.exclusionDates.tableHeader')}
      </Typography>
    </Box>
  );
};

export default DateRangeHeader;
