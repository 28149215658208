import { BookingDirection, BookingTour } from '../../../../../api/booking/BookingResource';

export const getUuid = () => crypto.randomUUID();
export const getEmptyTour = (): BookingTour => ({
  id: undefined,
  uuid: getUuid(),
  direction: BookingDirection.TWO_WAY,
  name: '',
  surcharge: 0,
  bookingTourRegions: [],
  bookingTourPostalCodes: [],
});
