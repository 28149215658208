import React, { FC } from 'react';
import { Popover } from '@mui/material';
import { useBookingModalContext } from '../../BookingModalContext';
import { useInitBookingPopoverContext } from './BookingPopoverContext';
import { BookingPopoverContext } from './BookingPopoverContext.model';
import PopoverContent from './PopoverContent';

const TourEditPopover: FC = () => {
  const ctx = useInitBookingPopoverContext();
  const { editedTour } = useBookingModalContext();

  const open = Boolean(editedTour?.element);
  const handleElementChanged = () => {
    if (editedTour?.editedRegionOrPostalCode.region != null) {
      ctx.popoverActions.saveRegion(
        editedTour?.originalRegionOrPostalCodeToEdit,
        editedTour?.editedRegionOrPostalCode.region,
      );
    } else if (editedTour?.editedRegionOrPostalCode.postalCode != null) {
      ctx.popoverActions.savePostalCode(
        editedTour?.originalRegionOrPostalCodeToEdit,
        editedTour?.editedRegionOrPostalCode.postalCode,
      );
    }
  };

  return (
    <BookingPopoverContext.Provider value={ctx}>
      <Popover
        open={open}
        onClose={() => handleElementChanged()}
        anchorEl={editedTour?.element}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{ sx: { overflowY: 'hidden' } }}
      >
        {editedTour && <PopoverContent />}
      </Popover>
    </BookingPopoverContext.Provider>
  );
};

export default TourEditPopover;
