import React, { FC, ReactElement } from 'react';
import { Box } from '@mui/material';
import { AlgotestFilterValues, INITIAL_ALGOTEST_FILTER_VALUES } from '../shared/AlgotestFilterModel';
import { Formik, useFormikContext } from 'formik';
import RequestIdFilter from './RequestIdFilter';
import RelocationRequestFilters from './RelocationRequestFilters';
import { algotestModelToForm } from '../shared/AlgotestModelConverter';
import { AlgotestRelocationRequest } from '../../../../api/algotest/AlgotestRelocationRequest';
import AlgotestDisabledSteps from './AlgotestDisabledSteps';

type AlgotestFiltersProps = {
  onRelocationRequestChanged: (request: AlgotestRelocationRequest) => void;
  loading?: boolean;
};

type AlgotestFormProps = {
  loading?: boolean;
};

const AlgotestForm = ({ loading }: AlgotestFormProps) => {
  const formik = useFormikContext();
  if (formik == null) {
    return <></>;
  }
  return (
    <form onSubmit={formik.handleSubmit} style={{ width: 'max-content' }}>
      <RequestIdFilter />
      <RelocationRequestFilters loading={loading} />
      <AlgotestDisabledSteps />
    </form>
  );
};

const AlgotestFilters: FC<AlgotestFiltersProps> = (props: AlgotestFiltersProps): ReactElement => {
  const handleOnSubmit = (value: AlgotestFilterValues) => {
    props.onRelocationRequestChanged(algotestModelToForm(value));
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        padding: '55px 0',
      }}
    >
      <Formik initialValues={INITIAL_ALGOTEST_FILTER_VALUES} onSubmit={handleOnSubmit}>
        <AlgotestForm loading={props.loading} />
      </Formik>
    </Box>
  );
};
export default AlgotestFilters;
