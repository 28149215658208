import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Section from '../../../../components/form/Section';
import SelfPause from './SelfPause';
import ScheduledPause from './scheduledPause/ScheduledPause';

const SPACING = 3;
const ROW_ALIGN = 'center';
const Pause: React.FC = () => {
  const { t } = useTranslation('dashboard');

  return (
    <Section dataTestId={'pause'} title={t('pause.selfPause.title')}>
      <Grid
        container
        item
        xs={6}
        spacing={SPACING}
        sx={{
          alignItems: ROW_ALIGN,
        }}
      >
        <SelfPause />
      </Grid>
      <Grid
        container
        spacing={SPACING}
        sx={{
          alignItems: ROW_ALIGN,
        }}
      >
        <ScheduledPause />
      </Grid>
    </Section>
  );
};

export default Pause;
