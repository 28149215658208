import React, { FC } from 'react';
import { Box } from '@mui/material';
import LabeledField from '../../../../../components/form/LabeledField';
import DatePicker from '../../../../../components/form/DatePicker';
import { useFormikContext } from 'formik';
import { DocumentsModalForm } from '../DocumentsContext.model';

type ExpirationDateProps = {
  label: string;
};
const ExpirationDate: FC<ExpirationDateProps> = (props: ExpirationDateProps) => {
  const formik = useFormikContext<DocumentsModalForm>();

  return (
    <Box sx={{ display: 'flex' }}>
      <LabeledField label={props.label}>
        <DatePicker
          initialValue={formik.values.expirationDate}
          onChange={(e) => formik.setFieldValue('expirationDate', e)}
        />
      </LabeledField>
    </Box>
  );
};

export default ExpirationDate;
