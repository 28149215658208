import React from 'react';
import { Box, Grid, styled, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export type ActionIconProps = {
  IconElement: IconType;
  sx?: SxProps<Theme>;
  onClick?: () => void;
  tooltip?: string;
  justifyContent?: 'flex-end';
  color?: string;
};

export type IconType = React.ReactElement;

type IconWrapperProps = {
  fill?: string;
};

const IconWrapper = styled(Box)<IconWrapperProps>(({ theme, fill }) => ({
  [`&`]: {
    display: 'flex',
    cursor: 'pointer',
  },
  [`& path`]: {
    fill: fill || theme.umzugEasy.palette.darkGray,
  },
}));

const ActionIcon: React.FC<ActionIconProps> = ({
  IconElement,
  onClick,
  tooltip,
  sx,
  color,
  justifyContent,
}: ActionIconProps) => {
  const displayedIcon: JSX.Element = (
    <IconWrapper fill={color} sx={sx}>
      {IconElement}
    </IconWrapper>
  );

  return (
    <Grid
      item
      onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: justifyContent,
      }}
    >
      {tooltip ? <Tooltip title={tooltip}>{displayedIcon}</Tooltip> : displayedIcon}
    </Grid>
  );
};

export default ActionIcon;
