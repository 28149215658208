import { RelocationStorageResponse } from '../../../../../api/relocationRequest/RelocationRequestDetail';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { formatToLocaleTextWithDate } from '../../../../../utils/DateUtil';

export type StorageSubsectionProps = {
  storage: RelocationStorageResponse;
};

const StorageSubsection: FC<StorageSubsectionProps> = ({ storage }: StorageSubsectionProps): ReactElement => {
  const { t } = useTranslation('relocationRequests');
  const translationPathPrefix = 'requestDetails.detailsTab.sections.fromTo.storage';
  const storageKeyOf = (postfix: string) => `${translationPathPrefix}.${postfix}`;

  return (
    <>
      <Box>{t(storageKeyOf('datesSubheader'))}</Box>
      <Box>{t(storageKeyOf('startDate'), { date: formatToLocaleTextWithDate(storage.startDate) })}</Box>
      <Box>{t(storageKeyOf('endDate'), { date: formatToLocaleTextWithDate(storage.endDate) })}</Box>
      <Box sx={{ mt: 2.5 }}>{t(storageKeyOf('dimensionsSubheader'))}</Box>
      <Box>{t(storageKeyOf('length'), { dimensionInCm: storage.length })}</Box>
      <Box>{t(storageKeyOf('width'), { dimensionInCm: storage.width })}</Box>
      <Box>{t(storageKeyOf('height'), { dimensionInCm: storage.height })}</Box>
    </>
  );
};

export default StorageSubsection;
