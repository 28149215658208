import { SelfPauseHistoryResource } from './SelfPauseHistoryResource';
import { apiVersion, getApiClient } from '../ApiUtils';

export function getSelfPauseHistory(partnerCwid: string): Promise<SelfPauseHistoryResource[]> {
  return getApiClient()
    .then((client) =>
      client.get<SelfPauseHistoryResource[]>(`/partners/${partnerCwid}/self/history`, {
        baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion,
      }),
    )
    .then((response) => response.data);
}
