import React, { FC, ReactElement } from 'react';
import { Box } from '@mui/material';
import Alert from '../../../components/feedback/Alert';
import { useInitPartnersReportsContext } from './PartnersReportContext';
import { PartnersReportContext } from './PartnersReportContext.model';
import PartnersReportList from '../../../pages/reports/partners/PartnersReportList';
import PartnersReportFilter from '../../../pages/reports/partners/PartnersReportFilter';

const PartnersReport: FC = (): ReactElement => {
  const ctx = useInitPartnersReportsContext();

  return (
    <Box sx={{ width: '100%' }}>
      <PartnersReportContext.Provider value={ctx}>
        <PartnersReportFilter />
        <PartnersReportList />
        <Alert text={''} {...ctx.alert} />
      </PartnersReportContext.Provider>
    </Box>
  );
};

export default PartnersReport;
