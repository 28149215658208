import React, { ReactElement, useState, useImperativeHandle, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getRequestTypesStatistics } from '../../../api/statistics/StatisticsApi';
import { RequestTypesStatisticsResource } from '../../../api/statistics/Statistics';
import ReportTable, {
  Formatter,
  ReportTableRow,
  ReportTableRowValues,
} from '../../../components/reportTable/ReportTable';
import MultipleRowSkeleton from '../../../components/feedback/MultipleRowSkeleton';
import { currencyFormatter, fractionFormatter, toStringFormatter } from '../utils/formatters';
import { getAggregatedStatistics } from './RequestSourcesStatisticsService';

type RequestTypesReportProps = {
  startDate: Date;
  endDate: Date;
};

export type RequestTypesReportRef = {
  fetchStatistics: () => void;
};

const RequestTypesReport = forwardRef<RequestTypesReportRef, RequestTypesReportProps>(
  ({ startDate, endDate }, ref): ReactElement | null => {
    const { t } = useTranslation('reports');
    const headerOf = (column: string) => t(`partner.header.${column}`);
    const [statistics, setStatistics] = useState<RequestTypesStatisticsResource[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isVisible, setVisible] = useState<boolean>(false);

    const headers = [
      'requestType',
      'requests',
      'revenuePerRequest',
      'leads',
      'revenuePerLead',
      'multiplier',
      'totalRevenue',
    ].map(headerOf);

    const formatters: Array<Formatter> = [
      toStringFormatter,
      currencyFormatter,
      toStringFormatter,
      currencyFormatter,
      fractionFormatter,
      currencyFormatter,
    ];

    const aggregationFunction = (rowValues: ReportTableRowValues[]) => {
      const aggregatedStatistics = getAggregatedStatistics(
        rowValues.map((values: ReportTableRowValues) => ({
          requestCount: values[0],
          leadCount: values[2],
          revenue: values[5],
        })),
      );
      return [
        aggregatedStatistics.totalRequests,
        aggregatedStatistics.revenuePerRequest,
        aggregatedStatistics.totalLeads,
        aggregatedStatistics.revenuePerLead,
        aggregatedStatistics.multiplier,
        aggregatedStatistics.totalRevenue,
      ];
    };

    const aggregationSettings = {
      aggregationRowLabel: t('partner.aggregationLabel'),
      aggregationFunction,
    };

    const rows: ReportTableRow[] = statistics.map((s) => ({
      id: s.requestType,
      values: [
        s.requestCount,
        s.revenue / s.requestCount,
        s.leadCount,
        s.revenue / s.leadCount,
        s.leadCount / s.requestCount,
        s.revenue,
      ],
    }));

    const fetchStatistics = async (): Promise<void> => {
      setLoading(true);
      try {
        setStatistics(await getRequestTypesStatistics(startDate, endDate));
        setVisible(true);
      } finally {
        setLoading(false);
      }
    };

    useImperativeHandle(ref, () => ({
      fetchStatistics,
    }));

    if (!isVisible) {
      return null;
    }

    if (isLoading)
      return <MultipleRowSkeleton rows={5} fontSize={'35px'} styles={{ height: '200px', width: '640px' }} />;

    return (
      <ReportTable headers={headers} rows={rows} formatters={formatters} aggregationSettings={aggregationSettings} />
    );
  },
);

export default RequestTypesReport;
