import { RelocationRequest, RelocationRequestConsumer } from './RelocationRequest';

export type RelocationRequestDetailObjectResponse = {
  direction: RelocationDirection;
  city: string;
  postalCode: string;
  iso2CountryCode: string;
  additionalInfo?: string;
  relocationDate?: Date;
  dateType?: DateType;
  street?: string;
  hasElevator?: Boolean;
  realEstateType?: RealEstateType;
  livingSpaceAreaSqm?: number;
  floor?: Floor;
  rooms?: Rooms;
  persons?: number;
  workstations?: number;
  offices?: number;
  storage?: number;
};

export type RelocationStorageResponse = {
  startDate: Date;
  endDate: Date;
  length: number;
  height: number;
  width: number;
};
export enum InventoryRoom {
  LOUNGE = 'LOUNGE',
  BEDROOM = 'BEDROOM',
  WORKROOM = 'WORKROOM',
  CHILDROOM = 'CHILDROOM',
  BATHROOM = 'BATHROOM',
  KITCHEN = 'KITCHEN',
  BASEMENT = 'BASEMENT',
  TERRACE = 'TERRACE',
  MISC = 'MISC',
}

export type InventoryItem = {
  name: string;
  value: number;
  itemId: number;
  cubicMeter: number;
  totalVolume: number;
  room: InventoryRoom;
  roomName: string;
};

export type InventoryList = {
  totalVolume: number;
  itemCount: number;
  items: InventoryItem[];
};

export type PhotoInventory = {
  photoId: string;
  title: string;
  extension: string;
  uri: string;
};

export type RelocationRequestInventory = {
  freeTextInventoryList: string;
  photoInventoryListComment: string;
  inventoryList: InventoryList;
  photoInventory: PhotoInventory[];
};
export type RelocationRequestDetail = {
  requestId: string;
  anfrageId: string;
  type: RelocationType;
  ssoId?: string;
  affiliatePartner?: string;
  affiliatePartnerParameter?: string;
  consumer: RelocationRequestConsumer;
  cwid?: string;
  additionalInformation?: string;
  hasInventoryList?: boolean;
  hasPhotoInventory?: boolean;
  distance?: number;
  createdAt: Date;
  payment?: PaymentType;
  features: Array<RelocationFeature>;
  fromObject: RelocationRequestDetailObjectResponse;
  toObject?: RelocationRequestDetailObjectResponse;
  storage?: RelocationStorageResponse;
  freeTextInventoryList: string;
  requestType?: string;
};

export enum RelocationType {
  RELOCATION = 'RELOCATION',
  COMPANY_RELOCATION = 'COMPANY_RELOCATION',
  STORAGE = 'STORAGE',
  CLEAR_OUT = 'CLEAR_OUT',
  PIANO = 'PIANO',
  EXTRA_CARGO = 'EXTRA_CARGO',
}

export const allRelocationTypes: RelocationType[] = [
  RelocationType.RELOCATION,
  RelocationType.COMPANY_RELOCATION,
  RelocationType.EXTRA_CARGO,
  RelocationType.STORAGE,
  RelocationType.CLEAR_OUT,
  RelocationType.PIANO,
];

export enum PaymentType {
  PRIVATE = 'PRIVATE',
  COMPANY = 'COMPANY',
  AUTHORITY = 'AUTHORITY',
}

export const allPaymentTypes: PaymentType[] = [PaymentType.PRIVATE, PaymentType.COMPANY, PaymentType.AUTHORITY];

export enum RequestType {
  BASIC = 'BASIC',
  BASIC_PLUS = 'BASIC_PLUS',
  DETAIL = 'DETAIL',
  DETAIL_PLUS = 'DETAIL_PLUS',
}

export const allRequestTypes: RequestType[] = [
  RequestType.BASIC,
  RequestType.BASIC_PLUS,
  RequestType.DETAIL,
  RequestType.DETAIL_PLUS,
];

export enum Floor {
  BASEMENT = 'BASEMENT',
  GROUND_FLOOR = 'GROUND_FLOOR',
  FIRST_FLOOR = 'FIRST_FLOOR',
  SECOND_FLOOR = 'SECOND_FLOOR',
  THIRD_FLOOR = 'THIRD_FLOOR',
  FOURTH_FLOOR = 'FOURTH_FLOOR',
  FIFTH_FLOOR = 'FIFTH_FLOOR',
  SIXTH_FLOOR = 'SIXTH_FLOOR',
  SEVENTH_FLOOR = 'SEVENTH_FLOOR',
  EIGHTH_FLOOR = 'EIGHTH_FLOOR',
  NINTH_FLOOR = 'NINTH_FLOOR',
  TENTH_FLOOR = 'TENTH_FLOOR',
  ABOVE_TENTH_FLOOR = 'ABOVE_TENTH_FLOOR',
}

export enum Rooms {
  ONE = 'ONE',
  ONE_AND_A_HALF = 'ONE_AND_A_HALF',
  TWO = 'TWO',
  TWO_AND_A_HALF = 'TWO_AND_A_HALF',
  THREE = 'THREE',
  THREE_AND_A_HALF = 'THREE_AND_A_HALF',
  FOUR = 'FOUR',
  FOUR_AND_A_HALF = 'FOUR_AND_A_HALF',
  FIVE = 'FIVE',
  FIVE_AND_A_HALF = 'FIVE_AND_A_HALF',
  SIX = 'SIX',
  SEVEN = 'SEVEN',
  EIGHT = 'EIGHT',
  NINE = 'NINE',
  TEN = 'TEN',
  ABOVE_TEN = 'ABOVE_TEN',
}

export enum RelocationFeature {
  // features common for private and company
  FURNITURE_ASSEMBLY = 'FURNITURE_ASSEMBLY',
  FURNITURE_DISASSEMBLY = 'FURNITURE_DISASSEMBLY',
  PACK = 'PACK',
  UNPACK = 'UNPACK',
  NO_PARKING_ZONE_FROM = 'NO_PARKING_ZONE_FROM',
  NO_PARKING_ZONE_TO = 'NO_PARKING_ZONE_TO',

  // features specific for private relocation
  KITCHEN_ASSEMBLY = 'KITCHEN_ASSEMBLY',
  KITCHEN_DISASSEMBLY = 'KITCHEN_DISASSEMBLY',
  CELLAR_ATTIC_INCLUDED = 'CELLAR_ATTIC_INCLUDED',
  ATTIC_INCLUDED = 'ATTIC_INCLUDED',
  STORAGE = 'STORAGE',
  ARRANGE_VIEWING = 'ARRANGE_VIEWING',

  // features specific for company relocation
  OFFICE_EQUIPMENT_OR_MACHINE_ASSEMBLY = 'OFFICE_EQUIPMENT_OR_MACHINE_ASSEMBLY',
  OFFICE_EQUIPMENT_OR_MACHINE_DISASSEMBLY = 'OFFICE_EQUIPMENT_OR_MACHINE_DISASSEMBLY',
  DISPOSAL_FROM = 'DISPOSAL_FROM',
  DISPOSAL_TO = 'DISPOSAL_TO',
  HEAVY_GOODS_TRANSPORT = 'HEAVY_GOODS_TRANSPORT',
}

export enum RealEstateType {
  FLAT = 'FLAT',
  HOUSE = 'HOUSE',
  ROOM = 'ROOM',
}

export enum RelocationDirection {
  FROM = 'FROM',
  TO = 'TO',
}

export enum DateType {
  EXACT = 'EXACT',
  START = 'START',
}

export const relocationRequestDetailToRelocationRequest = (
  relocationRequestDetail: RelocationRequestDetail,
): RelocationRequest => ({
  requestId: relocationRequestDetail.requestId,
  anfrageId: relocationRequestDetail.anfrageId,
  consumer: relocationRequestDetail.consumer,
  affiliatePartner: relocationRequestDetail.affiliatePartner,
  affiliatePartnerParameter: relocationRequestDetail.affiliatePartnerParameter,
  from: {
    city: relocationRequestDetail.fromObject.city,
    street: relocationRequestDetail.fromObject.street,
    postalCode: relocationRequestDetail.fromObject.postalCode,
    iso2CountryCode: relocationRequestDetail.fromObject.iso2CountryCode,
  },
  to: relocationRequestDetail.toObject
    ? {
        city: relocationRequestDetail.toObject.city,
        street: relocationRequestDetail.toObject.street,
        postalCode: relocationRequestDetail.toObject.postalCode,
        iso2CountryCode: relocationRequestDetail.toObject.iso2CountryCode,
      }
    : undefined,
});
