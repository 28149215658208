import { DateType, PaymentType, RelocationType } from '../../../../api/relocationRequest/RelocationRequestDetail';

export type AlgotestFilterValues = {
  date: Date;
  flexibleDate: DateType;
  relocationType: RelocationType;
  payment: PaymentType;
  squareMeters: number;
  distance: number;
  affiliate: string;
  affiliateParameter: string;
  fromCountry: string;
  fromPostalCode: string;
  fromCity: string;
  toCountry: string;
  toPostalCode: string;
  toCity: string;
  disabledSteps: string[];
};

export const INITIAL_ALGOTEST_FILTER_VALUES: AlgotestFilterValues = {
  date: new Date(),
  flexibleDate: DateType.EXACT,
  relocationType: RelocationType.RELOCATION,
  payment: PaymentType.PRIVATE,
  squareMeters: 60,
  distance: 0,
  affiliate: '',
  affiliateParameter: '',
  fromCountry: 'DE',
  fromCity: '',
  fromPostalCode: '',
  toCity: '',
  toCountry: 'DE',
  toPostalCode: '',
  disabledSteps: [],
};
