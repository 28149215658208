import React, { FC, ReactElement } from 'react';
import { Grid2 } from '@mui/material';
import General from './General';
import Pause from './Pause';
import ToggleHistory from './ToggleHistory';
import DunningState from './DunningState';

const Dashboard: FC = (): ReactElement => {
  return (
    <Grid2 container data-testid={'dashboard'}>
      <General />
      <DunningState />
      <Pause />
      <ToggleHistory />
    </Grid2>
  );
};

export default Dashboard;
