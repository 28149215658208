import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton } from '@mui/material';
import { useFormikContext } from 'formik';
import Checkbox from '../../../../../components/form/Checkbox';
import { insertOrRemoveValue } from '../../../../../utils/FormUtils';
import Label from '../../../../../components/form/Label';
import BookingSection from '../../../../partner/components/bookings/BookingSection';

const RelocationTypeSettings: FC = () => {
  const { t } = useTranslation('bookingTest');
  const formik = useFormikContext();
  const fieldProps = formik.getFieldProps('relocationTypes');
  const relocationTypes = ['relocation', 'company_relocation'];
  const checkboxOf = (relocationType: string) => (
    <Checkbox
      id={fieldProps.name}
      onChange={() => {
        formik?.setFieldValue(fieldProps.name, insertOrRemoveValue(fieldProps.value, relocationType.toUpperCase()));
        formik?.submitForm();
      }}
      checked={fieldProps.value !== undefined && fieldProps.value?.indexOf(relocationType.toUpperCase()) !== -1}
    />
  );

  return (
    <BookingSection title={t('form.relocationTypes.title')} isModalSection={false}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          gap: '50px',
          paddingTop: '16px',
        }}
      >
        {relocationTypes.map((relocationType) => (
          <Box key={relocationType} sx={{ display: 'flex' }}>
            {fieldProps.value === undefined ? (
              <Skeleton>{checkboxOf(relocationType)}</Skeleton>
            ) : (
              checkboxOf(relocationType)
            )}
            <Label label={t(`form.relocationTypes.inputs.${relocationType}`)} />
          </Box>
        ))}
      </Box>
    </BookingSection>
  );
};

export default RelocationTypeSettings;
