import {
  BookingTour,
  BookingTourPostalCode,
  BookingTourRegion,
  BookingTourType,
} from '../../../../../../../api/booking/BookingResource';
import { RegionAndPostalCode } from '../../BookingModalContext.model';
import { PopoverContent } from './BookingPopoverContext.model';

export const regionAndPostalCodeToPopoverContent = (
  regionAndPostalCode: RegionAndPostalCode | undefined,
): PopoverContent => {
  const type = regionAndPostalCode?.postalCode?.type || regionAndPostalCode?.region?.type;
  return {
    id: regionAndPostalCode?.postalCode?.id || regionAndPostalCode?.region?.id,
    regionId: regionAndPostalCode?.region?.regionId,
    postalCode: regionAndPostalCode?.postalCode?.postalCode,
    type,
    country: regionAndPostalCode?.postalCode?.country,
    isExcluded: type && [BookingTourType.TO_EXCLUDED, BookingTourType.FROM_EXCLUDED].indexOf(type) !== -1,
  };
};

export const isPostalCodeEqual = (
  oldPostalCode: BookingTourPostalCode | undefined,
  newPostalCode: BookingTourPostalCode | undefined,
): boolean => {
  return (
    oldPostalCode?.country === newPostalCode?.country &&
    oldPostalCode?.postalCode === newPostalCode?.postalCode &&
    oldPostalCode?.type === newPostalCode?.type
  );
};
export const isRegionEqual = (
  oldRegion: BookingTourRegion | undefined,
  newRegion: BookingTourRegion | undefined,
): boolean => {
  return oldRegion?.regionId === newRegion?.regionId && oldRegion?.type === newRegion?.type;
};

export const getTourWithoutPostalCodeOrRegion = (
  tour: BookingTour,
  toRemove: RegionAndPostalCode | undefined,
): BookingTour => {
  return {
    ...tour,
    bookingTourPostalCodes: tour.bookingTourPostalCodes.filter(
      (item) => !isPostalCodeEqual(toRemove?.postalCode, item),
    ),
    bookingTourRegions: tour.bookingTourRegions.filter((item) => !isRegionEqual(toRemove?.region, item)),
  };
};
export const getNewExclusionType = (
  currentType: BookingTourType | undefined,
  exclude: boolean,
): BookingTourType | undefined => {
  if (currentType == null) {
    return currentType;
  }
  const isFrom = [BookingTourType.FROM, BookingTourType.FROM_EXCLUDED].indexOf(currentType) !== -1;
  if (isFrom) {
    return exclude ? BookingTourType.FROM_EXCLUDED : BookingTourType.FROM;
  } else {
    return exclude ? BookingTourType.TO_EXCLUDED : BookingTourType.TO;
  }
};

export const getRegionFromPopoverContent = (
  shouldIncludeId: boolean,
  popoverContent: PopoverContent | undefined,
): BookingTourRegion | undefined => {
  const isRegion = popoverContent?.regionId != null;
  let newRegion: BookingTourRegion | undefined = undefined;
  if (isRegion && popoverContent?.type != null && popoverContent.regionId != null) {
    newRegion = {
      id: shouldIncludeId ? popoverContent?.id : undefined,
      type: popoverContent?.type,
      regionId: popoverContent?.regionId,
    };
  }
  return newRegion;
};
export const getPostalCodeFromPopoverContent = (
  shouldIncludeId: boolean,
  popoverContent: PopoverContent | undefined,
): BookingTourPostalCode | undefined => {
  const isRegion = popoverContent?.regionId != null;
  let newPostalCode: BookingTourPostalCode | undefined = undefined;
  if (!isRegion && popoverContent?.type != null && popoverContent?.country != null) {
    newPostalCode = {
      id: shouldIncludeId ? popoverContent?.id : undefined,
      type: popoverContent?.type,
      country: popoverContent.country,
      postalCode: popoverContent.postalCode,
    };
  }
  return newPostalCode;
};
