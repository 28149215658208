import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid2 } from '@mui/material';
import Section from '../../../../components/form/Section';
import OnlineStatus from '../../../../pages/partner/components/dashboard/onlineStatus/OnlineStatus';
import PartnerUfcProfile from '../../../../pages/partner/components/dashboard/PartnerUfcProfile';
import { usePartnerContext } from '../../shared/PartnerContext';
import QualitySeal from './QualitySeal';

const General: React.FC = () => {
  const { partner, partnerSettings, isLoading, isPartnerOnline, getValidContracts, updatePartnerSettings } =
    usePartnerContext();
  const { t } = useTranslation('dashboard');
  const SPACING = 3;
  const ROW_ALIGN = 'center';

  return (
    <>
      <Section
        dataTestId={'general'}
        title={t('general.title')}
        styles={{
          container: {
            paddingBottom: '0',
          },
        }}
      >
        <Grid2
          container
          size={6}
          spacing={SPACING}
          direction={'row'}
          sx={{
            alignItems: ROW_ALIGN,
          }}
        >
          <OnlineStatus
            partner={partner}
            partnerSettings={partnerSettings}
            updatePartnerSettingsCallback={updatePartnerSettings}
            isLoading={isLoading}
            isPartnerOnline={isPartnerOnline}
            validContracts={getValidContracts()}
          />
        </Grid2>
        <Grid2
          container
          size={6}
          spacing={SPACING}
          sx={{
            alignItems: ROW_ALIGN,
          }}
        >
          <QualitySeal
            label={t('general.qualitySealLabel')}
            tooltipText={t('general.tooltip')}
            dateLabel={t('general.datePickerLabel')}
            isQualitySealActive={partnerSettings?.isQualitySealActive}
            qualitySealValidTo={partnerSettings?.qualitySealValidTo}
          />
        </Grid2>
      </Section>
      {partnerSettings?.ufcProfileUrl && (
        <Section
          dataTestId={'ufcProfile'}
          styles={{
            container: {
              paddingTop: '0',
              paddingBottom: '0',
            },
          }}
        >
          <Grid2
            container
            size={6}
            spacing={SPACING}
            sx={{
              alignItems: ROW_ALIGN,
              padding: '0',
            }}
          />
          <Grid2
            container
            size={6}
            spacing={SPACING}
            sx={{
              alignItems: ROW_ALIGN,
              padding: '0',
            }}
          >
            <PartnerUfcProfile label={t('general.partnerUfcProfile')} ufcProfileUrl={partnerSettings?.ufcProfileUrl} />
          </Grid2>
        </Section>
      )}
    </>
  );
};

export default General;
