import { Box, styled } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export type ErrorMessageProps = {
  text: string;
  show: boolean;
  sx?: SxProps<Theme>;
};
const StyledBox = styled(Box)(({ theme }) => ({
  [`&`]: {
    color: theme.palette.error.main,
    fontSize: '12px',
  },
}));

const ErrorMessage = (props: ErrorMessageProps) => {
  if (!props.show) {
    return <></>;
  }
  return <StyledBox sx={props.sx}>{props.text}</StyledBox>;
};
export default ErrorMessage;
