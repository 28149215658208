import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Link, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DeletionConfirmationModal, {
  DeletionConfirmationModalProps,
} from '../../../../components/form/DeletionConfirmationModal';
import Alert from '../../../../components/feedback/Alert';

export type RelocationRequestAnonymizationProps = {
  onAnonymizeRelocationRequest: () => void;
  anonymizationConfirmationModal: DeletionConfirmationModalProps | undefined;
  isAnonymizingRelocationRequest: boolean;
  actionsBarStyle?: React.CSSProperties;
  linkStyle?: React.CSSProperties;
};

const relocationRequestAnonymizationLinkStyles = makeStyles((theme: Theme) => ({
  actionsBar: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '20px',
    marginTop: '20px',
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
    fontSize: '14px',
    color: theme.palette.warning.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const RelocationRequestAnonymizationLink: FC<RelocationRequestAnonymizationProps> = (props): ReactElement => {
  const { t } = useTranslation('relocationRequests');
  const classes = relocationRequestAnonymizationLinkStyles();

  const buttonKeyOf = (postfix: string): string => `requestDetails.detailsTab.buttons.${postfix}`;

  return (
    <Grid container className={classes.actionsBar} sx={props.actionsBarStyle}>
      <Link underline='none' sx={props.linkStyle} onClick={() => props.onAnonymizeRelocationRequest()}>
        <span className={classes.link}>{t(buttonKeyOf('anonymize'))}</span>
      </Link>
      <DeletionConfirmationModal
        deletionConfirmationModalProps={props.anonymizationConfirmationModal}
        loading={props.isAnonymizingRelocationRequest}
      />
      <Alert text={''} {...alert} />
    </Grid>
  );
};

export default RelocationRequestAnonymizationLink;
