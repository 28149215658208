import React, { FC } from 'react';
import { Box } from '@mui/material';
import Button from '../../../../../components/form/Button';

type CloseModalButtonProps = {
  label: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const CloseModalButton: FC<CloseModalButtonProps> = ({ label, setOpen }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'end',
        paddingTop: '30px',
        paddingBottom: '20px',
        paddingRight: '40px',
      }}
    >
      <Button label={label} type={'button'} onClick={() => setOpen(false)} />
    </Box>
  );
};

export default CloseModalButton;
