import React, { FC, ReactElement, useCallback } from 'react';
import { Box } from '@mui/material';
import UmzugEasyTable from '../../../components/table/UmzugEasyTable';
import { loadNextPageIfPossible } from '../../../components/table/util/UmzugEasyTableFunctions';
import { usePartnersReportsContext } from './PartnersReportContext';

const PartnersReportList: FC = (): ReactElement => {
  const { tableProps, page, totalPages, actions } = usePartnersReportsContext();

  // Memoize the callback to avoid redundant executions
  const handleLastRowRendered = useCallback(() => {
    loadNextPageIfPossible(page, totalPages, actions.onLoadNextPage);
  }, [page, totalPages, actions]);

  return (
    <Box>
      {tableProps && (
        <UmzugEasyTable
          {...tableProps}
          onLastRowRendered={handleLastRowRendered}
          isHeaderSticky={true}
          isGroupHeaderSticky={true}
        />
      )}
    </Box>
  );
};

export default PartnersReportList;
