import React, { FC, ReactElement } from 'react';
import { Grid2, Link } from '@mui/material';
import Label from '../../../../components/form/Label';

export type PartnerUfcProfileProps = {
  label: string;
  ufcProfileUrl: string | null | undefined;
};
const PartnerUfcProfile: FC<PartnerUfcProfileProps> = ({ label, ufcProfileUrl }): ReactElement => {
  const baseUrl = process.env.REACT_APP_UFC_URL;

  return (
    <>
      <Grid2 size={4} sx={{ paddingTop: '0 !important' }}>
        <Label label={label} sx={{ textAlign: 'right' }} />
      </Grid2>
      <Grid2 size={4} sx={{ paddingTop: '0 !important' }}>
        <Link
          underline='none'
          color='#86A800'
          href={`${baseUrl}${ufcProfileUrl}`}
          target={'_blank'}
          sx={{
            fontSize: '14px',
            paddingBottom: '1px',
            '&:hover': {
              paddingBottom: '0px',
              borderBottom: 'solid 1px',
              borderRadius: '0px',
              color: '#1B353C',
            },
          }}
        >
          Umzugsfirmen-Check.de
        </Link>
      </Grid2>
    </>
  );
};

export default PartnerUfcProfile;
