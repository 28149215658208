import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useUndistributedRequestsContext } from './UndistributedRequestsContext';
import { LeadGenerationResult } from '../../../../api/lead/LeadResources';
import { TableDataProps } from '../../../../components/basicTable/UmzugEasyBasicTable';
import { loadNextPageIfPossible } from '../../../../components/table/util/UmzugEasyTableFunctions';
import MultipleRowSkeleton from '../../../../components/feedback/MultipleRowSkeleton';
import UmzugEasyCollapsibleTable, {
  CollapsibleTableCellProps,
  CollapsibleTableRowProps,
} from '../../../../components/collapsibleTable/UmzugEasyCollapsibleTable';
import RelocationRequestDetailDetailsTab from '../../../../pages/relocationRequest/relocationRequestDetail/relocationRequestDetailsTab/RelocationRequestDetailDetailsTab';
import { formatToLocaleTextWithDateTime } from '../../../../utils/DateUtil';
import { formatNumberToEuroCurrency } from '../../../../utils/CurrencyUtil';
import { mapToCamelCasedString } from '../../../../utils/StringUtils';

const columnsStyle = {
  number: {
    width: '50px',
    minWidth: '50px',
  },
  requestId: {
    width: '200px',
    minWidth: '200px',
  },
  relocationType: {
    width: '100px',
    minWidth: '100px',
  },
  date: {
    width: '150px',
    minWidth: '150px',
  },
  price: {
    width: '75px',
    minWidth: '75px',
  },
  from: {
    width: '100px',
    minWidth: '100px',
  },
  to: {
    width: '100px',
    minWidth: '100px',
  },
  livingSpace: {
    width: '75px',
    minWidth: '75px',
  },
  reason: {
    width: '300px',
    minWidth: '300px',
  },
};

const cellPropsOf = (
  data: any,
  style?: any,
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify',
): CollapsibleTableCellProps => ({
  data,
  style,
  align,
});

const camelCase = (text: string) => text.toLowerCase().replace(/_./g, (x) => x[1].toUpperCase());
const getTranslationKey = (prefix: string, value?: string) => (value ? `${prefix}.${camelCase(value)}` : '');

const UndistributedLeadsList: FC = (): ReactElement => {
  const { t } = useTranslation(['undistributedRequests', 'algotest']);
  const {
    actions,
    anonymizationConfirmationModal,
    isAnonymizingRelocationRequest,
    leadDetailsByRequestId,
    leadsByRequestId,
    loading,
    openLeadDetailsId,
    pageDetails,
    rejectedLeads,
  } = useUndistributedRequestsContext();

  const displaySkeletonOnLoadingOr = (reactElement: ReactElement) => {
    return loading ? (
      <MultipleRowSkeleton rows={20} fontSize='30px' styles={{ width: '100%', lineHeight: 1.5 }} />
    ) : (
      reactElement
    );
  };
  const headerOf = (key?: string): TableDataProps => ({
    data: key ? t(`rejectedLeads.table.header.${key}`) : '',
    style: { paddingLeft: '0px' },
  });
  const header = [
    headerOf('no'),
    headerOf('requestId'),
    headerOf('relocationType'),
    headerOf('date'),
    headerOf('price'),
    headerOf('from'),
    headerOf('to'),
    headerOf('livingSpace'),
    headerOf('reason'),
  ];

  const rowOf = (rejectedLead: LeadGenerationResult, index: number): CollapsibleTableRowProps<string> => {
    const cells = [
      cellPropsOf(index + 1, columnsStyle.number),
      cellPropsOf(rejectedLead.relocationRequestId, columnsStyle.requestId),
      cellPropsOf(
        t(`rejectedLeads.table.relocationType.${mapToCamelCasedString(rejectedLead.relocationType)}`),
        columnsStyle.relocationType,
      ),
      cellPropsOf(formatToLocaleTextWithDateTime(rejectedLead.createdAt), columnsStyle.date),
      cellPropsOf(formatNumberToEuroCurrency(rejectedLead.price), columnsStyle.price),
      cellPropsOf(rejectedLead.fromZipCode, columnsStyle.from),
      cellPropsOf(rejectedLead.toZipCode, columnsStyle.to),
      cellPropsOf(`${rejectedLead.livingSpace} m²`, columnsStyle.livingSpace),
      cellPropsOf(
        t(getTranslationKey('list.table.rejectionReason', rejectedLead.rejectionReason), { ns: 'algotest' }),
        columnsStyle.reason,
      ),
    ];
    const details = () => {
      return cellPropsOf(
        <RelocationRequestDetailDetailsTab
          relocationRequestDetail={leadDetailsByRequestId}
          loading={loading}
          onAnonymizeRelocationRequest={actions.onAnonymizeRelocationRequest}
          anonymizationConfirmationModal={anonymizationConfirmationModal}
          isAnonymizingRelocationRequest={isAnonymizingRelocationRequest}
          leads={leadDetailsByRequestId ? leadsByRequestId.get(leadDetailsByRequestId.requestId) : []}
          customStyles={{
            sections: {
              height: 'auto',
              paddingTop: 'unset',
            },
            actionsBar: {
              marginTop: '0px',
              margin: '6px',
              paddingRight: '15px',
            },
          }}
        />,
      );
    };

    return {
      id: rejectedLead.relocationRequestId,
      cells: cells,
      // eslint-disable-next-line
      details: details,
    };
  };

  const rows = rejectedLeads.map((rejectedLead, index) => rowOf(rejectedLead, index));

  return (
    <Box sx={{ width: '100%' }}>
      {displaySkeletonOnLoadingOr(
        <UmzugEasyCollapsibleTable
          header={header}
          rows={rows}
          onLastRowRendered={() =>
            loadNextPageIfPossible(pageDetails.number, pageDetails.totalPages, actions.onLoadNextPage)
          }
          open={openLeadDetailsId}
          onRowClick={actions.onOpenRejectedLeadDetails}
        />,
      )}
    </Box>
  );
};

export default UndistributedLeadsList;
