import React from 'react';
import { Lead, LeadGenerationResult, PartnerLead } from '../../../../api/lead/LeadResources';
import { PageDetails } from '../../../../api/Page';
import { RelocationRequestDetail } from '../../../../api/relocationRequest/RelocationRequestDetail';
import { AlertProps } from '../../../../components/feedback/Alert';
import { DeletionConfirmationModalProps } from '../../../../components/form/DeletionConfirmationModal';

export type UndistributedRequestsActionsProps = {
  onLoadNextPage: () => void;
  onOpenRejectedLeadDetails: (requestId: string) => void;
  onAnonymizeRelocationRequest: () => void;
};

export type UndistributedRequestsContextProps = {
  actions: UndistributedRequestsActionsProps;
  alert?: AlertProps;
  anonymizationConfirmationModal: DeletionConfirmationModalProps | undefined;
  isAnonymizingRelocationRequest: boolean;
  leadDetailsByRequestId: RelocationRequestDetail | undefined;
  leadsByRequestId: Map<string, Lead[]>;
  loading: boolean;
  openLeadDetailsId: string | undefined;
  pageDetails: PageDetails;
  partnerLeads: PartnerLead[];
  rejectedLeads: LeadGenerationResult[];
};

export const initPageDetails: PageDetails = { size: 0, totalElements: 0, totalPages: 0, number: 0 };

export const UndistributedRequestsContext = React.createContext<UndistributedRequestsContextProps>({
  actions: {
    onLoadNextPage: () => {},
    onOpenRejectedLeadDetails: () => {},
    onAnonymizeRelocationRequest: () => {},
  },
  anonymizationConfirmationModal: undefined,
  isAnonymizingRelocationRequest: false,
  leadDetailsByRequestId: undefined,
  leadsByRequestId: new Map(),
  loading: false,
  openLeadDetailsId: undefined,
  pageDetails: initPageDetails,
  partnerLeads: [],
  rejectedLeads: [],
});
