import React from 'react';
import { Alert as MuiAlert, alertClasses, Slide, Snackbar, styled } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { AlertColor } from '@mui/material/Alert/Alert';

const DEFAULT_AUTO_HIDE_DURATION = 2000;

export type AlertProps = {
  text: string;
  open?: boolean;
  autoHideDuration?: number;
  color?: AlertColor;
  handleClose?: () => void;
  horizontal?: 'left' | 'center' | 'right' | undefined;
};

const AlertSlide = (props: TransitionProps & { children: React.ReactElement<any, any> }) => (
  <Slide {...props} direction='left' children={props.children} />
);

const StyledAlert = styled(MuiAlert)(({ theme }) => ({
  '&': {
    width: '100%',
    padding: '6px 24px',
    fontSize: '14px',
  },
  [`&.${alertClasses.filled}.${alertClasses.colorError}`]: {
    backgroundColor: theme.palette.error.main,
  },
})) as typeof MuiAlert;

const Alert: React.FC<AlertProps> = ({ text, open, autoHideDuration, color, handleClose, horizontal }: AlertProps) => {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: horizontal || 'right',
      }}
      onClose={handleClose}
      TransitionComponent={AlertSlide}
      autoHideDuration={autoHideDuration || DEFAULT_AUTO_HIDE_DURATION}
    >
      <StyledAlert severity={color || 'error'} variant={'filled'}>
        {text}
      </StyledAlert>
    </Snackbar>
  );
};
export default Alert;
