import React, { useContext } from 'react';

export type FeatureSwitchProps = {
  isEditSelfPauseDisabled: boolean; // To be removed after switching Re-write to production usage and removing UmzugEasy PHP
  isEditScheduledPauseDisabled: boolean; // To be removed after switching Re-write to production usage and removing UmzugEasy PHP
  isEditPausedByCustomerCareDisabled: boolean; // Probably to be removed after switching Re-write to production usage and removing UmzugEasy PHP
  isReportsContentDisabled: boolean; // TODO jbrylak: to be removed after finishing R2UE-73
};

export const featureSwitchDefaultProps: FeatureSwitchProps = {
  isEditSelfPauseDisabled: true,
  isEditScheduledPauseDisabled: true,
  isEditPausedByCustomerCareDisabled: true,
  isReportsContentDisabled: true,
};

export const FeatureSwitchContext = React.createContext<FeatureSwitchProps>(featureSwitchDefaultProps);

export function useFeatureSwitchContext(): FeatureSwitchProps {
  return useContext(FeatureSwitchContext);
}
