import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Box, Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import { allBookingTypes } from '../../../../../../api/booking/BookingResource';
import RadioButton from '../../../../../../components/form/RadioButton';
import Label from '../../../../../../components/form/Label';
import { mapToCamelCasedString } from '../../../../../../utils/StringUtils';

const BookingTypes = () => {
  const { t } = useTranslation('bookings');
  const formik = useFormikContext();
  const fieldProps = formik.getFieldProps('bookingType');
  const theme = useTheme<Theme>();
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '50px',
        padding: '10px 0',
        borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
      }}
    >
      {allBookingTypes.map((type) => (
        <Box
          key={type}
          sx={{ display: 'flex', cursor: 'pointer' }}
          onClick={() => formik?.setFieldValue(fieldProps.name, type)}
        >
          <RadioButton
            id={fieldProps.name}
            onChange={() => formik?.setFieldValue(fieldProps.name, type)}
            checked={fieldProps.value === type}
          />
          <Label label={t(`bookingType.${mapToCamelCasedString(type)}`)} />
        </Box>
      ))}
    </Box>
  );
};
export default BookingTypes;
