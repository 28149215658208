import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Box, Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import { PartnerDocument, PartnerDocumentType } from '../../../../api/partnerDocuments/PartnerDocumentsResource';
import { uploadPartnerDocument } from '../../../../api/partnerDocuments/PartnerDocumentsApi';
import Modal from '../../../../components/form/Modal';
import ActionImg from '../../../../components/shared/ActionImg';
import { useDocumentsContext } from './DocumentsContext';
import { DocumentsModalForm } from './DocumentsContext.model';
import Name from './modal/Name';
import UploadFile from './modal/UploadFile';
import LicenseNumber from './modal/LicenseNumber';
import ExpirationDate from './modal/ExpirationDate';
import ActionButtons from './modal/ActionButtons';
import { usePartnerContext } from '../../shared/PartnerContext';

export type ModalProps = {
  isModalOpen: boolean;
  onClose: Function;
  document?: PartnerDocument;
  documentType?: PartnerDocumentType;
  translatedDocumentType?: string;
};

type ModalErrors = {
  nameTooLong: boolean;
  extension: boolean;
};

const DocumentModal = (props: ModalProps) => {
  const { isModalOpen, onClose, document, documentType, translatedDocumentType } = props;
  const { t } = useTranslation('partnerDocuments');
  const theme = useTheme<Theme>();
  const formik = useFormikContext<DocumentsModalForm>();
  const { partner } = usePartnerContext();
  const { actions } = useDocumentsContext();
  const [errors, setErrors] = useState<ModalErrors>({ nameTooLong: false, extension: false });

  useEffect(() => {
    formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    formik.setFieldValue('type', documentType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentType]);

  const DocumentModalTitle: FC = () => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box>{t(`editModal.title.documentUpload`)}</Box>
      <ActionImg
        icon={'/icons/plus.svg'}
        iconStyle={{ marginLeft: '25px', rotate: '45deg' }}
        onClick={(event) => {
          event.stopPropagation();
          handleClose();
        }}
      />
    </Box>
  );

  const DocumentType: FC = () => {
    return (
      <Box
        sx={{
          borderBottom: `1px solid ${theme?.palette.primary.contrastText}`,
        }}
      >
        <Box
          sx={{
            color: theme.palette.warning.main,
            fontSize: '14px',
            fontWeight: 'bold',
            marginTop: '10px',
          }}
        >
          {translatedDocumentType || ''}
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    const date = document?.expirationDate ? new Date(document.expirationDate) : undefined;
    formik.setFieldValue('name', document?.fileName);
    formik.setFieldValue('licenseNumber', document?.licenceNumber);
    formik.setFieldValue('expirationDate', date);
    formik.setFieldValue('documentId', document?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  const onUpload = () => {
    if (partner) {
      const { values } = formik;
      onClose(documentType, true);
      uploadPartnerDocument(partner.cwid, { ...values }, values.documentId).then((res) => {
        actions.onPartnerDocumentUpload(res, values.documentId);
        actions.onEditModalClose();
        onClose(documentType, false);
      });
    }
  };

  const isSubmittable = () => {
    return (formik.getFieldProps('file').value || document?.fileName) && !errors.nameTooLong && !errors.extension;
  };

  return (
    <Modal isOpen={isModalOpen} title={<DocumentModalTitle />} width={'900px'}>
      <Box>
        <DocumentType />
        <UploadFile label={t('editModal.content.uploadFile')} document={document} />
        <Name label={t('editModal.content.newFilename')} document={document} errors={errors} setErrors={setErrors} />
        <LicenseNumber
          label={t('editModal.content.licenseNumber')}
          buttonLabel={t('editModal.content.duplicateCheck')}
        />
        <ExpirationDate label={t('editModal.content.expiryDate')} />
        <ActionButtons
          closeButtonLabel={t('editModal.actions.close')}
          submitButtonLabel={t('editModal.actions.upload')}
          isSubmitButtonDisabled={!isSubmittable()}
          onUpload={onUpload}
          onClose={handleClose}
        />
      </Box>
    </Modal>
  );
};

export default DocumentModal;
