import { Tab, styled, TabProps } from '@mui/material';

const UmzugEasyTab = styled(Tab)<TabProps>(({ theme }) => ({
  textTransform: 'none',
  fontSize: '14px',
  color: theme.palette.primary.light,
  '&.Mui-selected': {
    color: theme.palette.warning.main,
  },
}));

export default UmzugEasyTab;
