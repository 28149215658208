import { useEffect, useState } from 'react';
import { PartnerResource } from '../../../../api/partner/PartnerResource';
import { getPartners } from '../../../../api/partner/PartnerApi';
import { PartnerFilters } from '../../../../api/partner/PartnerFilters';
import { getUmzugEasyTableProps } from '../SchemaUtil';
import { TableSchema } from '../SchemaModel';
import { UmzugEasyTableProps } from '../../UmzugEasyTable';
import {
  DefaultRenderer,
  EmailRenderer,
  LabelWithMarkRenderer,
  PartnerCompanyNameRenderer,
  PartnerLinkRenderer,
} from '../../util/UmzugEasyCellRenderers';
import { calculatePercentage } from '../../../../utils/NumbersUtil';

export const usePartnerLoader = (autoInit?: boolean) => {
  const [partners, setPartners] = useState<PartnerResource[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(-1);
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState<PartnerFilters | undefined>(undefined);
  const [tableProps, setTableProps] = useState<UmzugEasyTableProps<PartnerResource>>(getPartnerTableSchema());
  const [loading, setLoading] = useState(false);

  const loadPartners = (pageForLoad: number, filtersForLoad: PartnerFilters) => {
    setLoading(true);
    // TODO Temporary changed size argument. Temporary workaround. Fix in the future.
    // TODO https://gs24.atlassian.net/browse/R2UE-936
    getPartners(pageForLoad, 60, filtersForLoad).then((partners) => {
      if (pageForLoad === 0) {
        setPartners(partners._embedded?.partners || []);
      } else {
        setPartners((prevState) => [...prevState, ...(partners._embedded?.partners || [])]);
      }
      setCount(partners.page.totalElements);
      setPageCount(partners.page.totalPages);
      setPage(pageForLoad);
      setLoading(false);
    });
  };

  const loadNextPage = () => {
    if (page + 1 < pageCount) {
      loadPartners(page + 1, filters || {});
    }
  };

  const changeFilters = (value: PartnerFilters) => {
    setFilters(value);
    loadPartners(0, value);
  };

  useEffect(() => {
    if (autoInit) {
      loadPartners(0, {});
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => setTableProps(getPartnerTableSchema(partners)), [partners]);

  return { tableProps, count, pageCount, page, filters, loadNextPage, changeQuery: changeFilters, loading };
};

const prepareLastInvokeCall = (partner?: PartnerResource): string =>
  partner?.lastInvoiceAmount != null && partner.lastInvoiceDate != null
    ? `${partner.lastInvoiceDate} \\ ${partner.lastInvoiceAmount} €`
    : ``;

const prepareCancelledLeadsRate = (partner: PartnerResource): string =>
  `${partner.leadsCancelledCount}  (${calculatePercentage(partner.leadsCancelledCount, partner.leadsCount)}%)`;

const stickySchema: TableSchema<PartnerResource>[] = [
  {
    key: 'cwid',
    order: 1,
    cellRenderer: (partner) =>
      PartnerLinkRenderer(partner.cwid, LabelWithMarkRenderer(partner.isActive ? 'green' : 'red', partner.cwid)),
  },
  {
    key: 'customerId',
    order: 2,
    value: (row) => row.scoutId?.toString() || '',
    cellRenderer: (partner) => PartnerLinkRenderer(partner.cwid, DefaultRenderer(partner.scoutId?.toString() || '')),
  },
  {
    key: 'companyName',
    order: 3,
    cellRenderer: (partner) => PartnerLinkRenderer(partner.cwid, PartnerCompanyNameRenderer(partner.name)),
  },
];

const schema: TableSchema<PartnerResource>[] = [
  {
    key: 'postalCode',
    order: 4,
    value: (row) => row.billingAddress.postalCode,
    cellRenderer: (partner) => PartnerLinkRenderer(partner.cwid, DefaultRenderer(partner.billingAddress.postalCode)),
  },
  {
    key: 'city',
    order: 5,
    value: (row) => row.billingAddress.city,
    cellRenderer: (partner) => PartnerLinkRenderer(partner.cwid, DefaultRenderer(partner.billingAddress.city)),
  },
  { key: 'emailAddress', order: 6, cellRenderer: (partner) => EmailRenderer(partner.email) },
  {
    key: 'additionalInformation',
    order: 7,
    cellRenderer: (partner) => PartnerLinkRenderer(partner.cwid, DefaultRenderer(partner.additionalInformation)),
  },
  {
    key: 'requestsLast30Days',
    order: 8,
    cellRenderer: (partner) => PartnerLinkRenderer(partner.cwid, DefaultRenderer(partner.leadsCount)),
  },
  {
    key: 'cancellationRateLast30Days',
    order: 9,
    cellRenderer: (partner) => PartnerLinkRenderer(partner.cwid, DefaultRenderer(prepareCancelledLeadsRate(partner))),
  },
  {
    key: 'lastInvoice',
    order: 10,
    cellRenderer: (partner) => PartnerLinkRenderer(partner.cwid, DefaultRenderer(prepareLastInvokeCall(partner))),
  },
  {
    key: 'streetAndStreetNumber',
    order: 11,
    value: (row) => row.billingAddress.street,
    cellRenderer: (partner) => PartnerLinkRenderer(partner.cwid, DefaultRenderer(partner.billingAddress.street)),
  },
  {
    key: 'phoneNumber',
    order: 12,
    value: (row) => row.phone,
    cellRenderer: (partner) => PartnerLinkRenderer(partner.cwid, DefaultRenderer(partner.phone)),
  },
];

export const getPartnerTableSchema = (partners?: PartnerResource[]): UmzugEasyTableProps<PartnerResource> => {
  return {
    stickyTable: getUmzugEasyTableProps<PartnerResource>({
      schema: stickySchema,
      rows: partners,
      translationPrefix: 'list.table.header',
      translationFile: 'partner',
    }),
    table: getUmzugEasyTableProps<PartnerResource>({
      schema: schema,
      rows: partners,
      translationPrefix: 'list.table.header',
      translationFile: 'partner',
    }),
    noDataMessageKey: 'partner:list.table.noData',
  };
};
