import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { PartnerSettingsRequest } from '../../../../api/partnerSettings/PartnerSettingsResource';
import { SelfPauseHistoryResource } from '../../../../api/selfPauseHistory/SelfPauseHistoryResource';
import { getSelfPauseHistory } from '../../../../api/selfPauseHistory/SelfPauseHistoryApi';
import Label from '../../../../components/form/Label';
import ToggleSwitch from '../../../../components/form/ToggleSwitch';
import Button from '../../../../components/form/Button';
import SelfPauseHistoryModal from './modal/SelfPauseHistoryModal';
import { usePartnerContext } from '../../shared/PartnerContext';
import { useFeatureSwitchContext } from '../../../../utils/FeatureSwitchContext';

const SelfPause: React.FC = () => {
  const { partner, partnerSettings, updatePartnerSettings } = usePartnerContext();
  const { isEditSelfPauseDisabled } = useFeatureSwitchContext();
  const [canSelfPause, setCanSelfPause] = useState<boolean>(!!partnerSettings?.canSelfPause);
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation('dashboard');
  const [selfPauseHistory, setSelfPauseHistory] = useState<SelfPauseHistoryResource[]>([]);
  const [isLoading, setLoading] = useState(false);

  const partnerCwid = partner?.cwid;

  useEffect(() => {
    if (partnerCwid && isOpen) {
      setLoading(true);
      getSelfPauseHistory(partnerCwid)
        .then((selfPauseHistory) => {
          setSelfPauseHistory(selfPauseHistory);
        })
        .finally(() => setLoading(false));
    }
  }, [isOpen, partnerCwid]);

  useEffect(() => {
    setCanSelfPause(!!partnerSettings?.canSelfPause);
  }, [partnerSettings]);

  const handleChange = (value: React.ChangeEvent<HTMLInputElement>) => {
    if (partner?.cwid != null && partnerSettings != null) {
      const newValue = value.target.checked;
      const newSettings: PartnerSettingsRequest = {
        ...partnerSettings,
        canSelfPause: newValue,
        pausedByCustomerCare: partner.isPausedByCustomerCare,
      };
      updatePartnerSettings(partner?.cwid, newSettings);
      setCanSelfPause(newValue);
    }
  };
  const onModalOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Grid item xs={3}>
        <Label sx={{ textAlign: 'right' }} label={t('pause.selfPause.label')} />
      </Grid>
      <Grid item xs={1}>
        <ToggleSwitch
          checked={canSelfPause}
          onClick={(event) => event.stopPropagation()}
          onChange={(value) => handleChange(value)}
          disabled={isEditSelfPauseDisabled}
        />
      </Grid>
      <Grid item xs={8}>
        <Button
          sx={{
            paddingBottom: '1px',
            '&:hover': {
              paddingBottom: '0px',
              borderBottom: 'solid 1px',
              borderRadius: '0px',
            },
          }}
          label={t('pause.selfPause.historyLink')}
          type={'button'}
          appearance={'link'}
          onClick={() => onModalOpen()}
        />
      </Grid>
      <SelfPauseHistoryModal
        setOpen={setOpen}
        isOpen={isOpen}
        isLoading={isLoading}
        selfPauseHistory={selfPauseHistory}
      />
    </>
  );
};

export default SelfPause;
