import React, { createRef, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import ActionImg from '../../../../../../components/shared/ActionImg';
import { ChipItem } from '../../../../../../components/shared/ChipItem';
import ToggleSwitch from '../../../../../../components/form/ToggleSwitch';
import Label from '../../../../../../components/form/Label';
import RadioButton from '../../../../../../components/form/RadioButton';
import Alert from '../../../../../../components/feedback/Alert';
import BookingSection from '../../../../../../pages/partner/components/bookings/BookingSection';
import { useShopSubscriptionSettingsContext } from './ShopSubscriptionSettingsContext';
import ShopSubscribedRegionsPopover from '../../../../../../pages/partner/components/bookings/bookingsSettings/shop/ShopSubscribedRegionsPopover';

const MAX_SUBSCRIBED_REGIONS = 6;

const ShopSubscriptionSettings: React.FC = (): ReactElement => {
  const { t } = useTranslation('bookings');
  const { alert } = useShopSubscriptionSettingsContext();

  return (
    <BookingSection title={t('booking.bookingsSettings.shopNewsletter.title')} isModalSection={false}>
      <>
        <GeneralConfiguration />
        <SubscribedRegionsList />
        <ShopSubscribedRegionsPopover />
        <Alert text={''} {...alert} />
      </>
    </BookingSection>
  );
};

export const GeneralConfiguration: React.FC = (): ReactElement => {
  const { t } = useTranslation('bookings');
  const { shopSubscription, actions } = useShopSubscriptionSettingsContext();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'start',
        gap: '50px',
        paddingTop: '16px',
      }}
    >
      <Grid sx={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
        <Label label={t('booking.bookingsSettings.shopNewsletter.inputs.switchLabel')} />
        <ToggleSwitch
          checked={shopSubscription?.isSubscribed || false}
          onClick={(event) => event.stopPropagation()}
          onChange={(value) => actions.onChangeShopNewsletter(value.target.checked)}
        />
      </Grid>
      <Grid sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <RadioButton
          checked={shopSubscription?.frequencyPerDay === 1}
          onClick={(_) => actions.onChangeShopNewsletterFrequency(1)}
          data-testid={'shopNewsletterFrequency1'}
        />
        <>
          <Label label={t('booking.bookingsSettings.shopNewsletter.inputs.1perDay')} />
        </>
      </Grid>
      <Grid sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <RadioButton
          checked={shopSubscription?.frequencyPerDay === 2}
          onClick={(_) => actions.onChangeShopNewsletterFrequency(2)}
          data-testid={'shopNewsletterFrequency2'}
        />
        <>
          <Label label={t('booking.bookingsSettings.shopNewsletter.inputs.2perDay')} />
        </>
      </Grid>
    </Box>
  );
};

export const SubscribedRegionsList: React.FC = (): ReactElement => {
  const { t } = useTranslation('bookings');
  const ref = createRef<HTMLDivElement>();
  const { shopSubscription, actions } = useShopSubscriptionSettingsContext();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'start',
        gap: '25px',
        paddingTop: '12px',
      }}
    >
      <ChipItem sx={{ justifyContent: 'center', backgroundColor: 'transparent' }} ref={ref}>
        {shopSubscription && shopSubscription.regions.length < MAX_SUBSCRIBED_REGIONS ? (
          <ActionImg
            icon={'/icons/plus.svg'}
            onClick={() => actions.onAddNewShopSubscribedRegion(ref.current)}
            data-testid={'addNewShopSubscribedRegionIcon'}
          />
        ) : (
          <ActionImg
            icon={'/icons/plus-disabled.svg'}
            tooltip={t('booking.bookingsSettings.shopNewsletter.inputs.max6subscribedRegions')}
            data-testid={'addNewShopSubscribedRegionDisabledIcon'}
          />
        )}
      </ChipItem>
      {shopSubscription?.regions.map((region, index) => (
        <ChipItem
          key={`key${index}`}
          isRed={false}
          onClick={(event) => actions.onEditShopSubscribedRegion(region, event.currentTarget)}
          data-testid={`shopSubscribedRegionItems`}
        >
          <>
            {region.country ? `${region.country}-${region.postalCode ? region.postalCode : ''}` : region.region?.name}
          </>
        </ChipItem>
      ))}
    </Box>
  );
};

export default ShopSubscriptionSettings;
