import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import RequestsSections from './requests/RequestsSections';
import { usePartnerContext } from '../shared/PartnerContext';
import { useInitRequestsContext } from './requests/RequestsContext';
import { RequestContext } from './requests/RequestsContext.model';
import Alert from '../../../components/feedback/Alert';

const Requests: FC = (): ReactElement => {
  const [partnerCwid, setPartnerCwid] = useState<string | undefined>(undefined);

  const { partner } = usePartnerContext();
  useEffect(() => setPartnerCwid(partner?.cwid), [partner?.cwid]);

  const ctx = useInitRequestsContext(partnerCwid);

  return (
    <Box sx={{ width: '100%' }}>
      <RequestContext.Provider value={ctx}>
        <RequestsSections />
        <Alert text={''} {...ctx.alert} />
      </RequestContext.Provider>
    </Box>
  );
};

export default Requests;
