import React, { FC } from 'react';
import { useTheme } from '@mui/styles';
import { Box, Theme } from '@mui/material';
import { Booking, PriceGroupType } from '../../../../../../api/booking/BookingResource';
import FormInput from '../../../../../../components/form/FormInput';
import FieldIcon from '../../../../../../components/form/FieldIcon';

export type PricesAndDetailsProps = {
  booking: Booking | undefined;
};

const PricesAndDetails: FC<PricesAndDetailsProps> = ({ booking }: PricesAndDetailsProps) => {
  const theme = useTheme<Theme>();
  const translationNameSpace = 'bookings';
  const priceGroupType = booking?.pricesByApartmentArea.priceGroupType;
  const minFormInputWidth = '100px';
  const minDistanceFormInputWidth = '110px';
  const priceFieldWidth = '280px';
  const distanceAndSpaceFieldWidth = '220px';
  const toFieldWidth = '160px';

  return (
    <Box
      sx={{
        display: 'flex',
        paddingTop: '16px',
        [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          [theme.breakpoints.down('sm')]: { width: '380px', maxWidth: '100%', alignItems: 'end' },
        }}
      >
        <FormInput
          translateKey={`booking.edit.priceType.${priceGroupType?.toLowerCase()}.priceGroup1`}
          translationNameSpace={translationNameSpace}
          formField={'priceGroup1'}
          type={'number'}
          width={priceFieldWidth}
          minFormWidth={minFormInputWidth}
          endAdornment={<FieldIcon type={'euro'} />}
        />
        <FormInput
          translateKey={`booking.edit.priceType.${priceGroupType?.toLowerCase()}.priceGroup2`}
          translationNameSpace={translationNameSpace}
          formField={'priceGroup2'}
          type={'number'}
          width={priceFieldWidth}
          minFormWidth={minFormInputWidth}
          endAdornment={<FieldIcon type={'euro'} />}
        />
        {priceGroupType === PriceGroupType.STANDARD && (
          <FormInput
            translateKey={`booking.edit.priceType.${priceGroupType.toLowerCase()}.priceGroup3`}
            translationNameSpace={translationNameSpace}
            formField={'priceGroup3'}
            type={'number'}
            width={priceFieldWidth}
            minFormWidth={minFormInputWidth}
            endAdornment={<FieldIcon type={'euro'} />}
          />
        )}
        <FormInput
          translateKey={`booking.edit.priceType.${priceGroupType?.toLowerCase()}.priceGroupOther`}
          translationNameSpace={translationNameSpace}
          formField={'priceGroupOther'}
          type={'number'}
          width={priceFieldWidth}
          minFormWidth={minFormInputWidth}
          endAdornment={<FieldIcon type={'euro'} />}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          [theme.breakpoints.down('sm')]: { width: '380px', maxWidth: '100%', alignItems: 'end' },
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <FormInput
            translateKey={`booking.edit.distance`}
            translationNameSpace={translationNameSpace}
            formField={'minDistance'}
            width={distanceAndSpaceFieldWidth}
            minFormWidth={minDistanceFormInputWidth}
            type={'number'}
            endAdornment={<FieldIcon type={'km'} />}
          />
          <FormInput
            translateKey={`booking.edit.to`}
            translationNameSpace={translationNameSpace}
            formField={'maxDistance'}
            width={toFieldWidth}
            minFormWidth={minDistanceFormInputWidth}
            type={'number'}
            endAdornment={<FieldIcon type={'km'} />}
          />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <FormInput
            translateKey={`booking.edit.squareMeters`}
            translationNameSpace={translationNameSpace}
            formField={'minSquareMeters'}
            minFormWidth={minDistanceFormInputWidth}
            type={'number'}
            width={distanceAndSpaceFieldWidth}
            endAdornment={<FieldIcon type={'m2'} />}
          />
          <FormInput
            translateKey={`booking.edit.to`}
            translationNameSpace={translationNameSpace}
            formField={'maxSquareMeters'}
            width={toFieldWidth}
            minFormWidth={minDistanceFormInputWidth}
            type={'number'}
            endAdornment={<FieldIcon type={'m2'} />}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default PricesAndDetails;
