import { apiVersion, getApiClient } from '../ApiUtils';
import {
  PartnerSettingsOverduePaymentsRequest,
  PartnerSettingsRequest,
  PartnerSettingsResource,
} from './PartnerSettingsResource';
import { PartnerStatus } from '../partner/PartnerResource';

export function getPartnerSettings(partnerCwid: string): Promise<PartnerSettingsResource> {
  return getApiClient()
    .then((client) =>
      client.get<PartnerSettingsResource>(`/partners/${partnerCwid}/settings`, {
        baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion,
      }),
    )
    .then((response) => response.data);
}

export function savePartnerSettings(partnerCwid: string, request: PartnerSettingsRequest) {
  return getApiClient()
    .then((client) =>
      client.post<PartnerSettingsResource>(`/partners/${partnerCwid}/settings`, request, {
        baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion,
      }),
    )
    .then((response) => response.data);
}

export function updatePartnerSettings(
  partnerCwid: string,
  partnerSettings: PartnerSettingsResource,
): Promise<PartnerSettingsResource> {
  return getApiClient()
    .then((client) =>
      client.put(`/partners/${partnerCwid}/settings`, partnerSettings, {
        baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion,
      }),
    )
    .then((response) => response.data);
}

export function setHasOverduePayments(partnerCwid: string, request: PartnerSettingsOverduePaymentsRequest) {
  return getApiClient()
    .then((client) =>
      client.post<PartnerSettingsResource>(`/partners/${partnerCwid}/settings/has-overdue-payments`, request, {
        baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    )
    .then((response) => response.data);
}

export function setDeactivationDate(partnerCwid: string) {
  return getApiClient()
    .then((client) =>
      client.post<PartnerSettingsResource>(`/partners/${partnerCwid}/settings/reset-deactivation-date`, null, {
        baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion,
      }),
    )
    .then((response) => response.data);
}

export function getPartnerStatus(partnerCwid: string) {
  return getApiClient()
    .then((client) =>
      client.get<PartnerStatus>(`/partners/${partnerCwid}/status`, {
        baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion,
      }),
    )
    .then((response) => response.data);
}
