import { FC, ReactElement } from 'react';
import { Typography } from '@mui/material';
import { formatToLocaleTextWithDate } from '../../../../utils/DateUtil';
import { DateRange } from '../../../../api/bookingsSettings/BookingsSettingsResource';

interface DateLabelProps {
  dateRange: DateRange;
}

const DateLabel: FC<DateLabelProps> = ({ dateRange: { start, end } }: DateLabelProps): ReactElement => {
  const labelOf = (start: string, end: string) => {
    if (start === end) {
      return `${formatToLocaleTextWithDate(start)}`;
    }
    return `${formatToLocaleTextWithDate(start)} - ${formatToLocaleTextWithDate(end)}`;
  };

  return <Typography sx={{ fontSize: '14px' }}>{labelOf(start, end)}</Typography>;
};

export default DateLabel;
