import React, { useEffect } from 'react';
import {
  formLabelClasses,
  inputBaseClasses,
  inputLabelClasses,
  MenuItem,
  menuItemClasses,
  Select as MuiSelect,
  SelectChangeEvent,
  selectClasses,
  styled,
  textFieldClasses,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system/styleFunctionSx';

export type SelectFieldOption = {
  key: string;
  text: string;
  value: string;
};

export type SelectFieldProps = {
  id?: string;
  options: SelectFieldOption[];
  emptyText?: string;
  initialValue?: string;
  useTranslation?: string;
  disabled?: boolean;

  onChange?: (value: SelectChangeEvent) => void;
  onValueChange?: (value: string) => void;
  sx?: SxProps<Theme>;
};

export const StyledSelect = styled(MuiSelect<string>)(({ theme }) => ({
  [`&.${inputBaseClasses.root}`]: {
    borderRadius: '5px',
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    height: '40px',
    border: 'none',
    margin: '10px',
  },
  [`&.${inputBaseClasses.root} fieldset`]: {
    borderColor: `transparent !important`,
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
  },
  [`& .${textFieldClasses.root} > label`]: {
    display: 'none',
  },
  [`&.${inputBaseClasses.root} .${selectClasses.outlined}`]: {
    fontSize: '14px',
    fontStyle: 'normal',
  },
  [`& .${inputLabelClasses.root}.${inputLabelClasses.focused}`]: {
    display: 'none',
  },
  [`& .${inputLabelClasses.root}.${formLabelClasses.filled}`]: {
    display: 'none',
  },
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  [`&.${menuItemClasses.root}`]: {
    fontSize: '14px',
    fontStyle: 'normal',
    minHeight: '24px',
  },
}));

const additionalStyles = makeStyles((theme: Theme) => ({
  emptyItem: {
    color: theme.palette.text.secondary,
  },
  selectFieldIconContainer: {
    paddingRight: '14px',
    cursor: 'pointer',
    paddingBottom: '4px',
  },
  selectFieldIconOpen: {
    transform: 'scaleY(-1)',
  },
  selectFieldIcon: {
    transition: 'transform .15s ease-out',
  },
}));

const EmptyItem = (props: { children: any }) => {
  const classes = additionalStyles();
  return <span className={classes.emptyItem}>{props.children}</span>;
};

export const SelectFieldIcon = (props: { className: string }) => {
  const isOpen = props.className.indexOf(selectClasses.iconOpen) !== -1;
  const classes = additionalStyles();
  return (
    <div className={`${classes.selectFieldIconContainer}`}>
      <img
        className={`${classes.selectFieldIcon} ${isOpen && classes.selectFieldIconOpen}`}
        src={'/icons/chevron.svg'}
        alt={'Show options'}
      />
    </div>
  );
};

const useTranslateOption = (conf: SelectFieldProps) => {
  const { t } = useTranslation(conf.useTranslation || 'common');
  const translate = (text: string) => (conf.useTranslation ? t(text) : text);
  return { translate };
};

export const SelectField = (props: SelectFieldProps) => {
  const [value, setValue] = React.useState(props.initialValue);
  const { translate } = useTranslateOption(props);

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
    props.onChange && props.onChange(event);
  };

  useEffect(() => {
    props.onValueChange && props.onValueChange(value || '');
  }, [value, props]);

  useEffect(() => {
    props.initialValue && setValue(props.initialValue);
  }, [props.initialValue]);

  return (
    <StyledSelect
      inputProps={{ id: props.id, name: props.id }}
      value={value}
      sx={props.sx}
      IconComponent={SelectFieldIcon}
      onChange={handleChange}
      disabled={props.disabled}
      displayEmpty={props.emptyText != null}
    >
      {props.emptyText && (
        <StyledMenuItem value=''>
          <EmptyItem>{translate(props.emptyText)}</EmptyItem>
        </StyledMenuItem>
      )}
      {props.options.map((item) => (
        <StyledMenuItem key={item.key} value={item.value}>
          {translate(item.text)}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );
};
