import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export type PartnerAdditionalDataProps = {
  accountOwner?: string;
  scoutId?: number;
  email?: string;
};

const partnerAdditionalDataStyles = makeStyles((theme: Theme) => ({
  companyAdditionalData: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '30px',
    color: theme.palette.text.secondary,
  },
}));

const PartnerAdditionalData: React.FC<PartnerAdditionalDataProps> = ({ accountOwner, scoutId, email }) => {
  const classes = partnerAdditionalDataStyles();

  return (
    <Grid item className={classes.companyAdditionalData} data-testid='partner-additional-data'>
      {[accountOwner, scoutId, email].filter((s) => s).join(' / ')}
    </Grid>
  );
};

export default PartnerAdditionalData;
