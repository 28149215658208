import React from 'react';
import { Grid, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Routes } from '../../../../routes/Routes';

const partnerNotFoundErrorStyles = makeStyles((theme: Theme) => ({
  errorMessage: {
    justifyContent: 'center',
    fontSize: '2.5rem',
    color: theme.palette.text.secondary,
    padding: '1.5rem 0',
  },
  homePageButton: {
    color: theme.palette.secondary.contrastText,
    fontSize: '1rem',
    border: 'none',
    background: theme.palette.secondary.light,
    borderRadius: '0.25rem',
    cursor: 'pointer',
    width: '15rem',
    height: '2rem',
  },
}));

const PartnerNotFoundError: React.FC = () => {
  const { t } = useTranslation('partner');

  const navigate = useNavigate();

  const classes = partnerNotFoundErrorStyles();

  return (
    <Grid container>
      <Grid container item xs={12} className={classes.errorMessage}>
        {/* TODO {kprzybylo} get proper message from Franzi/Torsten */}
        {t('mainData.errors.partnerNotFound')}
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={{
          justifyContent: 'center',
        }}
      >
        <button onClick={() => navigate(Routes.MAIN_PAGE)} className={classes.homePageButton}>
          {/* TODO {kprzybylo} get proper caption from Franzi/Torsten */}
          {t('common:buttons.toHomePage')}
        </button>
      </Grid>
    </Grid>
  );
};

export default PartnerNotFoundError;
