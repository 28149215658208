import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import { getAlgotestRelocationRequests } from '../../../../api/algotest/AlgotestApi';
import { AlgotestRelocationRequest } from '../../../../api/algotest/AlgotestRelocationRequest';
import { TextField } from '../../../../components/form/TextField';
import FilterLabelWithContent from './form/FilterLabelWithContent';
import useDebounceTime from '../../../../hooks/DebounceTime';
import { algotestFormToModel } from '../shared/AlgotestModelConverter';

const REQUEST_ID_LENGTH = 64;
const ANFRAGE_ID_LENGTH = 20;

const useRequestIdProvider = () => {
  const formik = useFormikContext();
  const [requestId, setRequestId] = useState('');
  const [algotestRelocationRequest, setAlgotestRelocationRequest] = useState<AlgotestRelocationRequest | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState(false);
  const [requestNotFound, setRequestNotFound] = useState(false);

  const fetchRelocationRequest = () => {
    setAlgotestRelocationRequest(undefined);
    updateRequestNotFound();
    if (requestId.trim().length === REQUEST_ID_LENGTH || requestId.trim().length === ANFRAGE_ID_LENGTH) {
      setLoading(true);
      getAlgotestRelocationRequests(requestId)
        .then((relocationRequest) => setAlgotestRelocationRequest(relocationRequest))
        .finally(() => setLoading(false));
    }
  };

  const fillFormBasedOnAlgotestRelocationRequest = (relocationRequest: AlgotestRelocationRequest) => {
    formik.setValues(algotestFormToModel(relocationRequest), false);
  };

  const updateRequestNotFound = () =>
    setRequestNotFound(algotestRelocationRequest == null && requestId.trim().length > 0 && !loading);

  useEffect(() => {
    algotestRelocationRequest && fillFormBasedOnAlgotestRelocationRequest(algotestRelocationRequest);
    updateRequestNotFound();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [algotestRelocationRequest]);
  useDebounceTime(() => fetchRelocationRequest(), [requestId]);

  return { loading, requestId, setRequestId, requestNotFound };
};

const RequestIdFilter = () => {
  const { t } = useTranslation('algotest');
  const theme: Theme = useTheme();
  const { loading, requestId, setRequestId, requestNotFound } = useRequestIdProvider();

  return (
    <Box sx={{ display: 'flex', alignItems: 'end' }}>
      <FilterLabelWithContent type={'requestId'}>
        <TextField
          label={t('form.requestId')}
          sx={{ marginTop: '5px !important', display: 'inline-block' }}
          type={'text'}
          defaultValue={requestId}
          onChange={(event) => setRequestId(event.target.value)}
        />
      </FilterLabelWithContent>
      <Typography
        sx={{
          fontWeight: 400,
          textAlign: 'center',
          fontSize: '12px',
          color: theme.palette.primary.dark,
          lineHeight: '60px',
          paddingLeft: '16px',
        }}
      >
        {t('form.or')}
      </Typography>
      {loading && (
        <Box
          sx={{
            lineHeight: '55px',
            marginLeft: '20px',
          }}
        >
          <CircularProgress size={20} />
        </Box>
      )}
      {requestNotFound && (
        <Typography
          sx={{
            fontWeight: 400,
            textAlign: 'center',
            fontSize: '12px',
            color: theme.palette.info.light,
            lineHeight: '60px',
            paddingLeft: '32px',
          }}
        >
          {t('form.requestNotFound')}
        </Typography>
      )}
    </Box>
  );
};
export default RequestIdFilter;
