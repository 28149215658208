import React, { useState } from 'react';
import {
  Autocomplete,
  autocompleteClasses,
  formLabelClasses,
  inputBaseClasses,
  inputLabelClasses,
  paperClasses,
  Popper,
  styled,
  svgIconClasses,
  TextField,
  textFieldClasses,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { getPartners } from '../../../api/partner/PartnerApi';
import { PartnerResource } from '../../../api/partner/PartnerResource';
import { useNavigate } from 'react-router-dom';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';
import useDebounceTime from '../../../hooks/DebounceTime';

export type PartnerQuickSearchHookResult = {
  setQuery: (query: string) => void;
  handleClick: (resource: PartnerResource) => void;
  partnerList: PartnerResource[];
  query: string;
};

export type AutocompleteOptionProps = {
  attributes: React.HTMLAttributes<HTMLLIElement>;
  option: PartnerResource;
  quickSearchHook: PartnerQuickSearchHookResult;
};

export type QuickSearchTextFieldProps = {
  renderParams: AutocompleteRenderInputParams;
  quickSearchHook: PartnerQuickSearchHookResult;
};

const StyledQuickSearch = styled(TextField)(({ theme }) => ({
  [`&.${textFieldClasses.root}`]: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: '5px',
    color: theme.palette.common.white,
    height: '40px',
    width: '180px',
  },
  [`&.${textFieldClasses.root} fieldset`]: {
    display: 'none',
  },
  [`& .${inputBaseClasses.root}`]: {
    height: '40px',
    color: theme.palette.common.white,
    borderRadius: '5px',
  },
  [`& .${inputBaseClasses.root} input`]: {
    textAlign: 'center',
    fontSize: '14px',
  },
  [`& .${inputLabelClasses.root}`]: {
    color: theme.palette.primary.contrastText,
    width: '100%',
    textAlign: 'center',
    height: '100%',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    lineHeight: '16px',
    paddingTop: '4px',
  },
  [`& .${inputLabelClasses.root}.${inputLabelClasses.focused}`]: {
    display: 'none',
  },
  [`& .${inputLabelClasses.root}.${formLabelClasses.filled}`]: {
    display: 'none',
  },
  [`& .${inputLabelClasses.root} .${svgIconClasses.root}`]: {
    fontSize: '18px',
    marginRight: '12px',
  },
}));

const StyledAutocomplete = styled(Autocomplete<PartnerResource, false, false, true>)(() => ({
  [`& .${autocompleteClasses.endAdornment}`]: {
    display: 'none',
  },
  [`& .${autocompleteClasses.inputRoot}`]: {
    paddingRight: '6px',
  },
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
  [`&`]: {
    paddingTop: '5px',
  },
  [`& .${paperClasses.root}`]: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: '5px',
    color: theme.palette.common.white,
    fontSize: '12px',
  },
  [`& .${paperClasses.root} li:hover`]: {
    backgroundColor: theme.palette.secondary.light,
  },
}));

const SEARCH_DELAY = 800;
const usePartnerQuickSearch = (): PartnerQuickSearchHookResult => {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [partnerList, setPartnerList] = useState<PartnerResource[]>([]);

  const search = () => {
    if (query && query.trim().length > 0) {
      getPartners(0, 10, { listQuery: query }).then((partners) => setPartnerList(partners._embedded?.partners || []));
    }
  };

  const handleClick = (resource: PartnerResource) => {
    navigate(`/partner/${resource.cwid}/dashboard`);
    setPartnerList([]);
    setQuery('');
  };

  useDebounceTime(() => search(), [query], SEARCH_DELAY);
  return { setQuery, handleClick, partnerList, query };
};

const AutocompleteOption = (props: AutocompleteOptionProps) => {
  return (
    <li {...props.attributes} onClick={() => props.quickSearchHook.handleClick(props.option)}>
      {props.option?.name}
    </li>
  );
};

const QuickSearchTextField = (props: QuickSearchTextFieldProps) => {
  const handleOnBlur = () => props.quickSearchHook.setQuery('');

  return (
    <StyledQuickSearch
      {...props.renderParams}
      id='quick-search'
      onBlur={handleOnBlur}
      inputProps={{ ...props.renderParams.inputProps, autoComplete: 'off' }}
      label={<QuickSearchPlaceholder />}
      onChange={(event) => props.quickSearchHook.setQuery(event.target.value)}
      size='small'
      variant='outlined'
    />
  );
};

const QuickSearchPlaceholder = () => {
  const { t } = useTranslation('common');
  return (
    <>
      <SearchIcon />
      {t('quickSearch')}
    </>
  );
};

const QuickSearch = () => {
  const quickSearchHook = usePartnerQuickSearch();
  return (
    <StyledAutocomplete
      id='quick-partner-search'
      freeSolo={true}
      PopperComponent={(props) => <StyledPopper {...props} />}
      filterOptions={(options) => options}
      options={quickSearchHook.partnerList.map((option) => option)}
      renderInput={(params) => <QuickSearchTextField renderParams={params} quickSearchHook={quickSearchHook} />}
      open={quickSearchHook.query.trim().length > 0}
      value={quickSearchHook.query}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option?.name || '')}
      renderOption={(props, option) => (
        <AutocompleteOption attributes={props} option={option} key={option?.cwid} quickSearchHook={quickSearchHook} />
      )}
    />
  );
};

export default QuickSearch;
