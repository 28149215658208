import React, { FC, ReactElement } from 'react';
import { Box, Theme } from '@mui/material';
import DateLabel from './DateLabel';
import DateRowActions from './DateRowActions';
import { useTheme } from '@mui/styles';
import { DateRange } from '../../../../api/bookingsSettings/BookingsSettingsResource';

interface DateRangeProps {
  dateRange: DateRange;
}

const DateRangeRow: FC<DateRangeProps> = ({ dateRange }: DateRangeProps): ReactElement => {
  const theme = useTheme<Theme>();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 10px',
        height: '54px',
        borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
      }}
    >
      <DateLabel dateRange={dateRange} />
      <DateRowActions dateRange={dateRange} />
    </Box>
  );
};

export default DateRangeRow;
