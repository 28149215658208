import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import { SelectedContactEmail } from '../../../../api/partner/PartnerResource';
import LabeledField from '../../../../components/form/LabeledField';
import RadioButton from '../../../../components/form/RadioButton';
import FormEditableTextField from '../../../../components/form/FormEditableTextField';

export type ContactEmailsProps = {
  salesforceEmail?: string;
  alternativeEmail?: string;
  selectedContactEmail?: SelectedContactEmail;
};

const ContactEmails: React.FC<ContactEmailsProps> = ({
  salesforceEmail,
  alternativeEmail,
  selectedContactEmail,
}: ContactEmailsProps) => {
  const { t } = useTranslation('partner');
  const formik = useFormikContext();
  const fieldProps = formik.getFieldHelpers('alternativeMailSelected');

  const handleSelectedEmailChange = (selectedEmail: SelectedContactEmail) => {
    if (selectedEmail !== selectedContactEmail) {
      fieldProps.setValue(selectedEmail === SelectedContactEmail.Alternative, true);
      formik.submitForm();
    }
  };

  return (
    <>
      <LabeledField label={t('mainData.labels.salesforceEmail')}>
        <Grid
          container
          item
          xs={12}
          sx={{
            display: 'flex',
          }}
        >
          <RadioButton
            disabled={!salesforceEmail || salesforceEmail === ''}
            checked={selectedContactEmail === SelectedContactEmail.Salesforce}
            onClick={(_) => handleSelectedEmailChange(SelectedContactEmail.Salesforce)}
          />
          <>{salesforceEmail}</>
        </Grid>
      </LabeledField>
      <LabeledField label={t('mainData.labels.alternativeEmail')}>
        <Grid
          container
          item
          xs={12}
          sx={{
            display: 'flex',
          }}
        >
          <RadioButton
            disabled={!alternativeEmail || alternativeEmail === ''}
            checked={selectedContactEmail === SelectedContactEmail.Alternative}
            onClick={(_) => handleSelectedEmailChange(SelectedContactEmail.Alternative)}
          />
          <FormEditableTextField value={alternativeEmail} formikName={'alternativeMail'} submitOnChange={true} />
        </Grid>
      </LabeledField>
    </>
  );
};

export default ContactEmails;
