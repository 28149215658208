import React from 'react';
import { FormikContextType } from 'formik';
import { array, boolean, number, object, string } from 'yup';
import { Region } from '../../../../api/region/RegionResource';
import {
  Booking,
  BookingPricesByApartmentArea,
  BookingTour,
  BookingTourPostalCode,
  BookingType,
  emptyBookingPricesByApartmentArea,
  PriceGroupType,
} from '../../../../api/booking/BookingResource';
import { SelectableValue } from '../../../../api/SelectableValue';
import { PaymentType, RelocationType, RequestType } from '../../../../api/relocationRequest/RelocationRequestDetail';
import { AlertProps } from '../../../../components/feedback/Alert';
import { Shape } from '../../../../components/form/FormInput';
import { DeletionConfirmationModalProps } from './modal/DeletionConfirmationModal';

export interface BookingModalForm extends Shape {
  name: string;
  bookingType: BookingType;
  priceGroup1: number;
  priceGroup2: number;
  priceGroup3: number | null;
  priceGroupOther: number;
  minDistance: number;
  maxDistance: number;
  minSquareMeters: number;
  maxSquareMeters: number;
  relocationTypes: RelocationType[];
  paymentTypes: PaymentType[];
  bookingTours: BookingTour[];
  maxCompetitors: number | null;
  maxCompetitorsSelected: boolean;
  startDate?: Date;
  endDate?: Date;
}

export const bookingToBookingEditForm = (booking?: Booking): BookingModalForm => {
  const maxPartners = booking?.maxPartners.value;
  return {
    name: booking?.name || '',
    bookingType: booking?.type || BookingType.FILTER,
    priceGroup1: booking?.pricesByApartmentArea.priceGroup1 || 0,
    priceGroup2: booking?.pricesByApartmentArea.priceGroup2 || 0,
    priceGroup3: booking?.pricesByApartmentArea.priceGroup3 || null,
    priceGroupOther: booking?.pricesByApartmentArea.priceGroupOther || 0,
    minDistance: booking?.minDistance || 0,
    maxDistance: booking?.maxDistance || 0,
    minSquareMeters: booking?.minSquareMeters || 0,
    maxSquareMeters: booking?.maxSquareMeters || 0,
    relocationTypes: booking?.relocationTypes || [],
    paymentTypes: booking?.paymentTypes || [],
    bookingTours:
      booking?.bookingTours.map(
        (item: BookingTour): BookingTour => ({
          ...item,
          uuid: crypto.randomUUID(),
        }),
      ) || [],
    maxCompetitors: maxPartners ? maxPartners - 1 : 4,
    maxCompetitorsSelected: booking?.maxPartners.selected || false,
    activationDate: booking?.activationDate,
    deactivationDate: booking?.deactivationDate,
    requestTypes: booking?.requestTypes || [],
  };
};

const errorKeys = {
  isRequired: 'booking.edit.errors.isRequired',
  priceMin: 'booking.edit.errors.priceMin',
  distanceRange: 'booking.edit.errors.distanceRange',
  squareMetersRange: 'booking.edit.errors.squareMetersRange',
  min0: 'booking.edit.errors.min0',
  integer: 'booking.edit.errors.integer',
  notEmpty: 'booking.edit.sections.requestType.error',
};
export const schema = object<BookingModalForm>().shape({
  name: string().min(1).defined(errorKeys.isRequired),
  bookingType: string().defined(errorKeys.isRequired),
  priceGroup1: number().min(0.1, errorKeys.priceMin).defined(errorKeys.isRequired),
  priceGroup2: number().min(0.1, errorKeys.priceMin).defined(errorKeys.isRequired),
  priceGroup3: number().min(0.1, errorKeys.priceMin).nullable(),
  priceGroupOther: number().min(0.1, errorKeys.priceMin).defined(errorKeys.isRequired),
  minDistance: number()
    .min(0, errorKeys.distanceRange)
    .max(9999, errorKeys.distanceRange)
    .defined(errorKeys.isRequired),
  maxDistance: number()
    .min(0, errorKeys.distanceRange)
    .max(9999, errorKeys.distanceRange)
    .defined(errorKeys.isRequired),
  minSquareMeters: number()
    .min(0, errorKeys.squareMetersRange)
    .max(9999, errorKeys.squareMetersRange)
    .defined(errorKeys.isRequired),
  maxSquareMeters: number()
    .min(0, errorKeys.squareMetersRange)
    .max(9999, errorKeys.squareMetersRange)
    .defined(errorKeys.isRequired),
  relocationTypes: array().defined(errorKeys.isRequired),
  paymentTypes: array().defined(errorKeys.isRequired),
  bookingTours: array().defined(errorKeys.isRequired),
  maxCompetitors: number().when('maxCompetitorsSelected', {
    is: true,
    then: (schema) => schema.required(errorKeys.isRequired).integer(errorKeys.integer).min(0, errorKeys.min0),
    otherwise: (schema) => schema.nullable().integer(errorKeys.integer).min(0, errorKeys.min0),
  }),
  maxCompetitorsSelected: boolean().defined(errorKeys.isRequired),
  requestTypes: array().min(1, errorKeys.notEmpty).defined(errorKeys.isRequired),
});

export const emptyBooking = (partnerCwid: string, prices: BookingPricesByApartmentArea | undefined): Booking => ({
  id: undefined,
  minDistance: 0,
  maxDistance: 9999,
  minSquareMeters: 0,
  maxSquareMeters: 9999,
  pricesByApartmentArea: prices || emptyBookingPricesByApartmentArea(),
  bookingTours: [],
  paymentTypes: [PaymentType.PRIVATE],
  relocationTypes: [RelocationType.RELOCATION],
  type: BookingType.FILTER,
  name: '',
  partnerCwid: partnerCwid,
  active: false,
  maxPartners: { value: 5, selected: false },
  activationDate: undefined,
  deactivationDate: undefined,
  requestTypes: [RequestType.BASIC],
});

export const formToBooking = (booking: Booking, formik: FormikContextType<BookingModalForm>): Booking => {
  const extractPostalCodes = (bookingTourPostalCodes: BookingTourPostalCode[]) =>
    bookingTourPostalCodes.flatMap((bookingTourPostalCode) => {
      if (!bookingTourPostalCode.postalCode) {
        return bookingTourPostalCode;
      }
      return bookingTourPostalCode.postalCode
        .replace(/\s+/g, '')
        .split(',')
        .map(
          (code) =>
            ({
              ...bookingTourPostalCode,
              postalCode: code,
            }) as BookingTourPostalCode,
        );
    });
  const maxCompetitors = formik.getFieldProps<number | null>('maxCompetitors').value;
  const maxCompetitorsSelected = formik.getFieldProps<boolean>('maxCompetitorsSelected').value;
  const bookingType = formik.getFieldProps<BookingType>('bookingType').value;
  const bookingTours = formik.getFieldProps<BookingTour[]>('bookingTours').value || [];
  const extractedBookingTours = bookingTours.map(
    (bookingTour: BookingTour) =>
      ({
        ...bookingTour,
        bookingTourPostalCodes: extractPostalCodes(bookingTour.bookingTourPostalCodes),
      }) as BookingTour,
  );

  return {
    ...booking,
    name: formik.getFieldProps<string>('name').value,
    paymentTypes: formik.getFieldProps<PaymentType[]>('paymentTypes').value || [],
    relocationTypes: formik.getFieldProps<RelocationType[]>('relocationTypes').value || [],
    type: bookingType || BookingType.FILTER,
    pricesByApartmentArea: {
      priceGroup1: +formik.getFieldProps<number>('priceGroup1').value,
      priceGroup2: +formik.getFieldProps<number>('priceGroup2').value,
      priceGroup3:
        booking.pricesByApartmentArea.priceGroupType === PriceGroupType.STANDARD
          ? +formik.getFieldProps<number>('priceGroup3').value
          : null,
      priceGroupOther: +formik.getFieldProps<number>('priceGroupOther').value,
      priceGroupType: PriceGroupType.STANDARD,
    },
    activationDate: formik.getFieldProps<Date>('activationDate').value,
    deactivationDate: formik.getFieldProps<Date>('deactivationDate').value,
    minDistance: +formik.getFieldProps<number>('minDistance').value,
    maxDistance: +formik.getFieldProps<number>('maxDistance').value,
    minSquareMeters: +formik.getFieldProps<number>('minSquareMeters').value,
    maxSquareMeters: +formik.getFieldProps<number>('maxSquareMeters').value,
    bookingTours: extractedBookingTours,
    maxPartners: {
      value: maxCompetitors != null && maxCompetitorsSelected ? maxCompetitors + 1 : null,
      selected: maxCompetitorsSelected,
    } as SelectableValue<number | null>,
    requestTypes: formik.getFieldProps<RequestType[]>('requestTypes').value || [],
  };
};

const emptyFunction = () => {};
export type BookingActionsProps = {
  onShowDetailsInList: () => void;

  onBookingAdd: () => void;
  onBookingEdit: (bookingId: number) => void;
  onBookingRemove: (bookingId: number) => void;

  onTourAdd: (bookingId: number) => void;
  onTourEdit: (bookingId: number, tourId: number) => void;
  onTourRemove: (bookingId: number, tourId: number) => void;

  updateBooking: (bookingId: number, booking: Booking) => void;
  createBooking: (booking: Booking) => void;
  changeBookingActive: (bookingId: number, isActive: boolean) => void;

  onCloseModal: () => void;
};
export type BookingModalType = 'booking' | 'tour';
export type BookingModalAction = 'edit' | 'add' | 'remove';
export type BookingModalProps = {
  type: BookingModalType;
  action: BookingModalAction;
  bookingId?: number;
  bookingTourId?: number;
};
export type BookingContextProps = {
  bookings: Booking[];
  regions: Region[];
  partnerCwid?: string;
  showDetails: boolean;
  isLoading: boolean;
  isSaving: boolean;
  actions: BookingActionsProps;
  modal?: BookingModalProps;
  alert?: AlertProps;
  deletionConfirmationModal: DeletionConfirmationModalProps | undefined;
};
export const BookingContext = React.createContext<BookingContextProps>({
  bookings: [],
  regions: [],
  partnerCwid: undefined,
  showDetails: false,
  isLoading: false,
  isSaving: false,
  deletionConfirmationModal: undefined,
  actions: {
    onShowDetailsInList: emptyFunction,

    onBookingAdd: emptyFunction,
    onBookingEdit: emptyFunction,
    onBookingRemove: emptyFunction,

    onTourAdd: emptyFunction,
    onTourEdit: emptyFunction,
    onTourRemove: emptyFunction,

    updateBooking: emptyFunction,
    createBooking: emptyFunction,
    changeBookingActive: emptyFunction,

    onCloseModal: emptyFunction,
  },
});
