import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import Button from '../../../../../../components/form/Button';
import ActionImg from '../../../../../../components/shared/ActionImg';

type BookingFormButtonsProps = {
  remove?: {
    onClick: () => void;
    translationKey: string;
  };
  cancel: {
    onClick: () => void;
    translationKey: string;
  };
  save: {
    onClick: () => void;
    translationKey: string;
    disabled: boolean;
  };
};
const BookingFormButtons: FC<BookingFormButtonsProps> = ({ remove, cancel, save }: BookingFormButtonsProps) => {
  const { t } = useTranslation('bookings');
  return (
    <Box
      sx={{
        display: 'flex',
        marginTop: '60px',
      }}
    >
      {remove != null && (
        <Box sx={{ display: 'flex' }}>
          <Button
            sx={{ textTransform: 'none' }}
            icon={<ActionImg iconStyle={{ width: '12px', marginRight: '12px' }} icon={'/icons/trash.svg'} />}
            label={t(remove.translationKey)}
            appearance={'dark'}
            variant={'outlined'}
            onClick={() => remove?.onClick()}
          />
        </Box>
      )}
      <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'end', gap: '20px' }}>
        <Button
          label={t(cancel.translationKey)}
          appearance={'dark'}
          variant={'outlined'}
          onClick={() => cancel.onClick()}
        />
        <Button
          label={t(save.translationKey)}
          appearance={'dark'}
          onClick={() => save.onClick()}
          disabled={save.disabled}
        />
      </Box>
    </Box>
  );
};
export default BookingFormButtons;
