import React, { FC, ReactElement, useState } from 'react';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { PartnerDocument, PartnerDocumentType } from '../../../../api/partnerDocuments/PartnerDocumentsResource';
import Section from '../../../../components/form/Section';
import UmzugEasyBasicTable, { TableDataProps } from '../../../../components/basicTable/UmzugEasyBasicTable';
import ToggleSwitch from '../../../../components/form/ToggleSwitch';
import DocumentsActions from './DocumentsActions';
import { useDocumentsContext } from './DocumentsContext';
import { initializeDocumentForm, schema } from './DocumentsContext.model';
import { DocumentsProps } from '../Documents';
import DocumentModal from './DocumentModal';
import { formatToLocaleTextWithDate } from '../../../../utils/DateUtil';

const rowOf = (
  data: any,
  style?: any,
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify',
): TableDataProps => ({
  data,
  style,
  align,
});

const StandardDocumentsList: FC<DocumentsProps> = (props: DocumentsProps): ReactElement => {
  const { t } = useTranslation('partnerDocuments');
  const { standardPartnerDocuments, actions, document, documentType, documentDisplayType, isEditModalOpen } =
    useDocumentsContext();
  const [isSavingDocuments, setIsSavingDocuments] = useState(
    Object.fromEntries(Object.values(PartnerDocumentType).map((type) => [type, false])),
  );

  const buildRow = (type: PartnerDocumentType, displayType: string): TableDataProps[] => {
    const linkToFileFrom = (displayText: string, document: PartnerDocument) => (
      <Link
        underline='hover'
        color='inherit'
        href='#'
        onClick={() => actions.onPartnerDocumentOpen(document.partnerCwid, document.id)}
      >
        {displayText}
      </Link>
    );
    const document = standardPartnerDocuments.find((document) => document.type === type);

    const openModal = () => {
      actions.onEditModalOpen(document, type, displayType);
    };

    return [
      rowOf(
        <ToggleSwitch
          id='isAvailable'
          onClick={(event) => event.stopPropagation()}
          onChange={(value) => actions.onChangePartnerDocumentAvailability(value.target.checked, type, document?.id)}
          checked={document ? document.available : false}
        />,
        {
          paddingLeft: '10px',
          width: props.documentsColumnsWidth.available,
          minWidth: props.documentsColumnsWidth.available,
        },
      ),
      rowOf(document ? linkToFileFrom(displayType, document) : displayType, {
        width: props.documentsColumnsWidth.document,
        minWidth: props.documentsColumnsWidth.document,
      }),
      rowOf(document && linkToFileFrom(document.fileName, document), {
        width: props.documentsColumnsWidth.filename,
        minWidth: props.documentsColumnsWidth.filename,
      }),
      rowOf(document?.modifiedBy, {
        width: props.documentsColumnsWidth.confirmedBy,
        minWidth: props.documentsColumnsWidth.confirmedBy,
      }),
      rowOf(formatToLocaleTextWithDate(document?.expirationDate), {
        width: props.documentsColumnsWidth.expiryDate,
        minWidth: props.documentsColumnsWidth.expiryDate,
      }),
      rowOf(
        <DocumentsActions
          onRemove={() => actions.onPartnerDocumentRemove(document?.id)}
          onEdit={() => {
            openModal();
          }}
          isSaving={isSavingDocuments[type]}
        />,
      ),
    ];
  };

  const headerOf = (key?: string): TableDataProps => ({
    data: key ? t(`header.${key}`) : '',
    style: { paddingLeft: '0px' },
  });
  const header = [
    headerOf('available'),
    headerOf('document'),
    headerOf('filename'),
    headerOf('confirmedBy'),
    headerOf('expiryDate'),
    headerOf(),
  ];

  const documentTypeDataOf = (type: PartnerDocumentType, displayType: string) => ({
    type,
    displayType: t(`types.${displayType}`),
  });

  const documentsData = [
    documentTypeDataOf(PartnerDocumentType.EU_LICENSE, 'euLicense'),
    documentTypeDataOf(PartnerDocumentType.BUSINESS_REGISTRATION, 'businessRegistration'),
    documentTypeDataOf(PartnerDocumentType.AMOE_MEMBER, 'amoeMember'),
    documentTypeDataOf(PartnerDocumentType.MOE_FORM, 'moeForm'),
    documentTypeDataOf(PartnerDocumentType.TUEV_CERTIFICATE, 'tuevCertificate'),
    documentTypeDataOf(PartnerDocumentType.ISO_CERTIFICATE, 'isoCertificate'),
    documentTypeDataOf(PartnerDocumentType.FIDI_MEMBER, 'fidiMember'),
  ].map((documentTypeData) => buildRow(documentTypeData.type, documentTypeData.displayType));

  const handleClose = (documentType: PartnerDocumentType, isSaving: boolean) => {
    setIsSavingDocuments({ ...isSavingDocuments, [documentType]: isSaving });
    actions.onEditModalClose();
  };

  return (
    <>
      <Formik initialValues={initializeDocumentForm(document)} validationSchema={schema} onSubmit={() => {}}>
        <DocumentModal
          isModalOpen={isEditModalOpen}
          document={document}
          translatedDocumentType={documentDisplayType}
          documentType={documentType}
          onClose={handleClose}
        />
      </Formik>
      <Section title={t('standardDocuments')} styles={props.tableSectionStyles}>
        <UmzugEasyBasicTable header={header} rows={documentsData} />
      </Section>
    </>
  );
};

export default StandardDocumentsList;
