import { useState } from 'react';
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  differenceInCalendarDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';

export type DateRangeType = 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';

export type UseDateRangesResult = {
  dateRangeType: DateRangeType;
  startDate: Date;
  endDate: Date;
  comparisonStartDate: Date;
  comparisonEndDate: Date;
  goBack: () => void;
  goForward: () => void;
  setDateRangeType: (dateRangeType: DateRangeType) => void;
  setCustomRange: (startDate: Date, endDate: Date) => void;
};

const useDateRanges = (initialStartDate: Date = new Date()): UseDateRangesResult => {
  const beginningOfStartDate = startOfDay(initialStartDate);

  const [dateRangeType, setDateRangeType] = useState<DateRangeType>('DAY');
  const [startDate, setStartDate] = useState<Date>(beginningOfStartDate);
  const [endDate, setEndDate] = useState<Date>(endOfDay(initialStartDate));
  const [comparisonStartDate, setComparisonStartDate] = useState<Date>(subWeeks(beginningOfStartDate, 1));
  const [comparisonEndDate, setComparisonEndDate] = useState<Date>(endOfDay(subWeeks(initialStartDate, 1)));

  const setDateRangeTypeTo = (rangeType: DateRangeType) => {
    if (rangeType !== dateRangeType) {
      setDateRangeType(rangeType);
      calculateDates(rangeType, startDate);
    }
  };

  const calculateDates = (rangeType: DateRangeType, date: Date) => {
    switch (rangeType) {
      case 'DAY':
        calculateForDay(date);
        break;
      case 'WEEK':
        calculateForWeek(date);
        break;
      case 'MONTH':
        calculateForMonth(date);
        break;
      case 'YEAR':
        calculateForYear(date);
        break;
    }
  };

  const calculateForDay = (date: Date) => {
    const newStartDate = startOfDay(date);
    const newEndDate = endOfDay(date);
    const newComparisonStartDate = subWeeks(newStartDate, 1);
    const newComparisonEndDate = endOfDay(subWeeks(date, 1));

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setComparisonStartDate(newComparisonStartDate);
    setComparisonEndDate(newComparisonEndDate);
  };

  const calculateForWeek = (date: Date) => {
    const newStartDate = startOfWeek(date, { weekStartsOn: 1 }); // Week starts on Monday
    const newEndDate = endOfWeek(date, { weekStartsOn: 1 }); // Week ends on Sunday
    const newComparisonStartDate = subWeeks(newStartDate, 1);
    const newComparisonEndDate = subWeeks(newEndDate, 1);

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setComparisonStartDate(newComparisonStartDate);
    setComparisonEndDate(newComparisonEndDate);
  };

  const calculateForMonth = (date: Date) => {
    const newStartDate = startOfMonth(date);
    const newEndDate = endOfMonth(date);
    const newComparisonStartDate = subMonths(newStartDate, 1);
    const newComparisonEndDate = endOfMonth(newComparisonStartDate);

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setComparisonStartDate(newComparisonStartDate);
    setComparisonEndDate(newComparisonEndDate);
  };

  const calculateForYear = (date: Date) => {
    const newStartDate = startOfYear(date);
    const newEndDate = endOfYear(date);
    const newComparisonStartDate = subYears(newStartDate, 1);
    const newComparisonEndDate = endOfYear(newComparisonStartDate);

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setComparisonStartDate(newComparisonStartDate);
    setComparisonEndDate(newComparisonEndDate);
  };

  const goBack = () => {
    let newStartDate = startDate;
    switch (dateRangeType) {
      case 'DAY':
        newStartDate = subDays(startDate, 1);
        break;
      case 'WEEK':
        newStartDate = subWeeks(startDate, 1);
        break;
      case 'MONTH':
        newStartDate = subMonths(startDate, 1);
        break;
      case 'YEAR':
        newStartDate = subYears(startDate, 1);
        break;
    }
    setStartDate(newStartDate);
    calculateDates(dateRangeType, newStartDate);
  };

  const goForward = () => {
    let newStartDate = startDate;
    switch (dateRangeType) {
      case 'DAY':
        newStartDate = addDays(startDate, 1);
        break;
      case 'WEEK':
        newStartDate = addWeeks(startDate, 1);
        break;
      case 'MONTH':
        newStartDate = addMonths(startDate, 1);
        break;
      case 'YEAR':
        newStartDate = addYears(startDate, 1);
        break;
    }
    setStartDate(newStartDate);
    calculateDates(dateRangeType, newStartDate);
  };

  const setCustomRange = (newStartDate: Date, newEndDate: Date) => {
    const numberOfDays = differenceInCalendarDays(newEndDate, newStartDate) + 1;
    const newComparisonEndDate = subDays(newStartDate, 1);
    const newComparisonStartDate = subDays(newComparisonEndDate, numberOfDays - 1);

    setStartDate(startOfDay(newStartDate));
    setEndDate(endOfDay(newEndDate));
    setComparisonStartDate(startOfDay(newComparisonStartDate));
    setComparisonEndDate(endOfDay(newComparisonEndDate));
  };

  return {
    dateRangeType,
    startDate,
    endDate,
    comparisonStartDate,
    comparisonEndDate,
    goBack,
    goForward,
    setDateRangeType: setDateRangeTypeTo,
    setCustomRange,
  };
};

export default useDateRanges;
