import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid2 } from '@mui/material';
import { ToggleHistoryResource } from '../../../../../api/toggleHistory/ToggleHistoryResource';
import BulletList from '../../../../../assets/icons/bullet-list.svg';
import CloseModalButton from './CloseModalButton';
import Modal from '../../../../../components/form/Modal';
import LoadingElement from './LoadingElement';
import ModalTitle from './ModalTitle';
import NoHistoryContent from './NoHistoryContent';
import { useDefaultTheme } from '../../../../../themeConfig';
import ToggleHistoryRow from './ToggleHistoryRow';
import { formatToLocaleTextWithDateTime } from '../../../../../utils/DateUtil';

type ToggleHistoryModalProps = {
  isLoading: boolean;
  toggleHistory: ToggleHistoryResource[];
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ToggleHistoryModal: FC<ToggleHistoryModalProps> = ({ isLoading, toggleHistory, isOpen, setOpen }) => {
  const { t } = useTranslation('dashboard');
  const historyElements: ReactNode[] = [];
  const theme = useDefaultTheme();

  const closeButton = <CloseModalButton label={t('toggleHistory.modal.button')} setOpen={setOpen} />;
  const noToggleHistoryContent = <NoHistoryContent text={t('toggleHistory.modal.noData')} />;
  const loadingElement: React.JSX.Element = <LoadingElement />;
  const titleElement = <ModalTitle text={t('toggleHistory.modal.title')} icon={<BulletList />} />;

  const hasToggleHistoryEntries = toggleHistory.length > 0;

  toggleHistory
    .sort((a, b) => new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime())
    .forEach((entry, index) => {
      const date = formatToLocaleTextWithDateTime(entry.modifiedAt);
      const formattedDay = date.split(' ')[0];
      const formattedTime = date.split(' ')[1];

      function mapEntryValue(entryValue: string): string {
        if (entryValue === '1' || entryValue === 'true') {
          return t('toggleHistory.modal.values.on');
        } else if (entryValue === '0' || entryValue === 'false') {
          return t('toggleHistory.modal.values.off');
        } else {
          return getFormattedValue(entryValue);
        }
      }

      function getFormattedValue(newValue: string): string {
        const numberValue = parseInt(newValue);
        return !!numberValue ? `${numberValue}%` : newValue;
      }

      historyElements.push(
        <ToggleHistoryRow
          key={index}
          date={formattedDay}
          time={formattedTime}
          toggleName={entry.propertyName}
          modifiedBy={entry.modifiedBy}
          value={mapEntryValue(entry.newValue)}
          text={entry.comment}
        />,
      );
    });

  return (
    <Modal
      title={titleElement}
      isOpen={isOpen}
      children={
        <>
          <Box>
            <Grid2
              container
              size={12}
              sx={{
                alignItems: 'center',
                fontSize: '14px',
                color: theme.umzugEasy.palette.darkGray,
                fontWeight: '600',
                backgroundColor: '#FFFFFF',
                textAlign: 'center',
                paddingTop: '15px',
                paddingBottom: '15px',
              }}
            >
              <Grid2 size={2}>{t('toggleHistory.modal.header.day')}</Grid2>
              <Grid2 size={1}>{t('toggleHistory.modal.header.time')}</Grid2>
              <Grid2 size={2}>{t('toggleHistory.modal.header.modifiedBy')}</Grid2>
              <Grid2 size={3}>{t('toggleHistory.modal.header.modifiedField')}</Grid2>
              <Grid2 size={1}>{t('toggleHistory.modal.header.status')}</Grid2>
              <Grid2 size={2}>{t('toggleHistory.modal.header.text')}</Grid2>
            </Grid2>
          </Box>
          <Box sx={{ maxHeight: '50vh', overflow: 'auto', backgroundColor: '#fafafa' }}>
            {isLoading ? loadingElement : hasToggleHistoryEntries ? historyElements : noToggleHistoryContent}
          </Box>
          {closeButton}
        </>
      }
      width={'960px'}
      padding={'0'}
    />
  );
};

export default ToggleHistoryModal;
