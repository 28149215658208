import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Badge, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ActionImg from '../../../components/shared/ActionImg';
import PartnerAdditionalData from '../../../pages/partner/components/mainData/PartnerAdditionalData';

export type PartnerHeaderInformationProps = {
  partnerName?: string;
  accountOwner?: string;
  scoutId?: number;
  email?: string;
  isActive?: boolean;
  isQualitySealActive?: boolean;
  hasRecommendationSeal?: boolean;
  isTopPartner?: boolean;
  isSubscribed?: boolean;
  leadsCountLast12Months?: number;
};

const partnerHeaderStyles = makeStyles((theme: Theme) => ({
  container: {
    borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
    paddingBottom: '0.5rem',
  },
  icon: {
    height: '33px',
  },
  companyName: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '33px',
  },
}));

type StatusIconProps = {
  isActive?: boolean;
  isQualitySealActive?: boolean;
  hasRecommendationSeal?: boolean;
  isTopPartner?: boolean;
  isSubscribed?: boolean;
  hasLeadsLast12Months?: boolean;
  tooltip: string;
  leadsCountLast12Months?: number;
};

type IconConfig = {
  icon: string;
};

type OmitMultiple = Omit<StatusIconProps, 'tooltip' | 'leadsCountLast12Months'>;

// Configuration for icons based on each prop and its value
const statusIcons: Record<keyof OmitMultiple, { true: IconConfig; false: IconConfig }> = {
  isActive: {
    true: { icon: '/icons/online.svg' },
    false: { icon: '/icons/offline.svg' },
  },
  hasRecommendationSeal: {
    true: { icon: '/icons/customerRecommendation.svg' },
    false: { icon: '/icons/noCustomerRecommendation.svg' },
  },
  isQualitySealActive: {
    true: { icon: '/icons/activeQualitySeal.svg' },
    false: { icon: '/icons/inactiveQualitySeal.svg' },
  },
  isTopPartner: {
    true: { icon: '/icons/topPartner.svg' },
    false: { icon: '/icons/noTopPartner.svg' },
  },
  isSubscribed: {
    true: { icon: '/icons/activeNewsletter.svg' },
    false: { icon: '/icons/inactiveNewsletter.svg' },
  },
  hasLeadsLast12Months: {
    true: { icon: '/icons/activeShopping.svg' },
    false: { icon: '/icons/inactiveShopping.svg' },
  },
};

const StatusIcon: React.FC<StatusIconProps> = (props) => {
  const { tooltip, leadsCountLast12Months, ...statusProps } = props;

  return (
    <>
      {Object.entries(statusProps).map(([key, value]) => {
        if (value !== undefined) {
          const iconKey = key as keyof OmitMultiple;

          if (iconKey in statusIcons) {
            const config = statusIcons[iconKey][value ? 'true' : 'false'];

            if (key === 'hasLeadsLast12Months') {
              return (
                <Badge
                  key={iconKey}
                  badgeContent={leadsCountLast12Months}
                  color='primary'
                  sx={{
                    '& .MuiBadge-badge': {
                      right: -8,
                      top: 40,
                      padding: '5px 6px',
                    },
                  }}
                >
                  <ActionImg icon={config.icon} tooltip={tooltip} />
                </Badge>
              );
            }

            return <ActionImg key={iconKey} icon={config.icon} tooltip={tooltip} />;
          }
        }
        return null;
      })}
    </>
  );
};

const PartnerHeader: React.FC<PartnerHeaderInformationProps> = ({
  accountOwner,
  email,
  hasRecommendationSeal,
  isActive,
  isSubscribed,
  leadsCountLast12Months,
  isTopPartner,
  isQualitySealActive,
  partnerName,
  scoutId,
}: PartnerHeaderInformationProps) => {
  const classes = partnerHeaderStyles();
  const { t } = useTranslation('partner');
  const hasLeadsLast12Months = leadsCountLast12Months !== undefined && leadsCountLast12Months > 0;

  return (
    <Grid container>
      <Grid
        container
        className={classes.container}
        sx={{
          alignItems: 'flex-start',
        }}
      >
        <Grid
          container
          item
          xs={12}
          md={6}
          lg={8}
          direction='row'
          columnSpacing={2}
          sx={{
            alignItems: 'flex-start',
          }}
        >
          <Grid
            container
            item
            xs='auto'
            className={classes.icon}
            sx={{
              alignItems: 'center',
            }}
          >
            <img src={'/icons/truck.svg'} alt={'Moving Company symbol'} />
          </Grid>
          <Grid container item xs='auto' direction='column'>
            <Grid item className={classes.companyName}>
              {partnerName}
            </Grid>
            <PartnerAdditionalData accountOwner={accountOwner} scoutId={scoutId} email={email} />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={6}
          lg={4}
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <StatusIcon isActive={isActive} tooltip={isActive ? t('infoBar.online') : t('infoBar.offline')} />
          <StatusIcon isQualitySealActive={isQualitySealActive} tooltip={t('infoBar.qualitySeal')} />
          <StatusIcon hasRecommendationSeal={hasRecommendationSeal} tooltip={t('infoBar.customerRecommendationSeal')} />
          <StatusIcon isTopPartner={isTopPartner} tooltip={t('infoBar.tooPartner')} />
          <StatusIcon isSubscribed={isSubscribed} tooltip={t('infoBar.shopNewsletter')} />
          <StatusIcon
            hasLeadsLast12Months={hasLeadsLast12Months}
            leadsCountLast12Months={leadsCountLast12Months}
            tooltip={t('infoBar.shopShopping', { leadsCount: leadsCountLast12Months })}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PartnerHeader;
