import React, { FC, ReactElement } from 'react';
import { Box, Theme, Toolbar } from '@mui/material';
import NavigationNormal from './NavigationNormal';
import NavigationMobile from './NavigationMobile';
import { useTheme } from '@mui/styles';

const Navbar: FC = (): ReactElement => {
  const theme = useTheme<Theme>();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.dark,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          // maxWidth: theme.breakpoints.values.lg,
          width: '100%',
        }}
      >
        <Toolbar disableGutters>
          <NavigationMobile />
          <NavigationNormal />
        </Toolbar>
      </Box>
    </Box>
  );
};

export default Navbar;
