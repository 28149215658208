import React, { ReactElement } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import { Booking } from '../../../../api/booking/BookingResource';
import Clock from '../../../../assets/icons/clock.svg';
import CrossCircle from '../../../../assets/icons/cross-circle.svg';
import BookingActions from './BookingActions';
import { useBookingContext } from './BookingContext';
import BookingTypeIcon from './BookingTypeIcon';
import ActionIcon, { IconType } from '../../../../components/shared/ActionIcon';
import ToggleSwitch from '../../../../components/form/ToggleSwitch';

type BookingTitleAndActionsProps = {
  booking: Booking;
};

const DateRangeIcon = ({ icon, isActive, sx }: { icon: IconType; isActive: boolean; sx?: SxProps<Theme> }) => {
  const theme = useTheme<Theme>();
  const isActiveStyle: SxProps<Theme> = {
    '& svg': {
      backgroundColor: theme.palette.warning.main,
      borderRadius: '50%',
    },
    '& svg path:last-child': {
      fill: 'transparent',
    },
  };
  const color = isActive ? theme.palette.common.white : theme.palette.text.primary;
  return (
    <ActionIcon
      IconElement={icon}
      color={color}
      sx={{ cursor: 'auto !important', ...(sx || {}), ...(isActive ? isActiveStyle : {}) }}
    />
  );
};

const BookingTitleAndActions: React.FC<BookingTitleAndActionsProps> = ({
  booking,
}: BookingTitleAndActionsProps): ReactElement => {
  const { actions } = useBookingContext();
  return (
    <Box
      sx={{
        display: 'flex',
        height: '54px',
        alignItems: 'center',
      }}
    >
      <ToggleSwitch
        id='isActive'
        onClick={(event) => event.stopPropagation()}
        onChange={(value) => booking.id != null && actions.changeBookingActive(booking.id, value.target.checked)}
        defaultChecked={booking.active}
        disabled={booking.activationDate !== null || booking.deactivationDate !== null}
      />
      <BookingTypeIcon type={booking.type} sx={{ marginLeft: '20px' }} size={'small'} />
      <Box sx={{ fontSize: '14px', marginLeft: '10px', flexGrow: 1 }}>{booking.name}</Box>

      {/*TODO: activation time is set*/}
      <DateRangeIcon icon={<Clock />} isActive={booking.activationDate !== null} />
      {/*TODO: deactivation time is set*/}
      <DateRangeIcon icon={<CrossCircle />} isActive={booking.deactivationDate !== null} sx={{ marginLeft: '5px' }} />

      <BookingActions
        onRemove={() => booking.id != null && actions.onBookingRemove(booking.id)}
        onEdit={() => booking.id != null && actions.onBookingEdit(booking.id)}
      />
    </Box>
  );
};
export default BookingTitleAndActions;
