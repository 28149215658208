import { useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import {
  Booking,
  BookingDirection,
  BookingTour,
  BookingTourPostalCode,
  BookingTourType,
} from '../../../../../../api/booking/BookingResource';
import {
  BookingTestActionsProps,
  BookingTestContext,
  BookingTestContextProps,
  BookingTestEditTourProps,
} from './BookingTestContext.model';
import { BookingTestForm } from '../FormFields';
import { RegionAndPostalCode } from '../../../../../partner/components/bookings/modal/BookingModalContext.model';

export function useBookingTestContext(): BookingTestContextProps {
  return useContext(BookingTestContext);
}

export function useInitBookingTestContext(): BookingTestContextProps {
  const [editedTour, setEditedTour] = useState<BookingTestEditTourProps | undefined>(undefined);
  const [booking] = useState<Booking | undefined>(undefined);
  const [isValid, setValid] = useState<boolean>(false);
  const formik = useFormikContext<BookingTestForm>();

  useEffect(() => setValid(formik.isValid), [formik.isValid]);

  const setTour = (tour: BookingTour) => {
    formik.setFieldValue('bookingTour', tour);
  };

  const actions: BookingTestActionsProps = {
    editPostalCode: (tour, postalCode, element) => {
      const newValueToEdit: RegionAndPostalCode = { postalCode, region: undefined };
      setEditedTour({
        editedRegionOrPostalCode: newValueToEdit,
        originalRegionOrPostalCodeToEdit: newValueToEdit,
        element,
        tour,
      });
    },
    editRegion: (tour, region, element) => {
      const newValueToEdit: RegionAndPostalCode = { postalCode: undefined, region };
      setEditedTour({
        editedRegionOrPostalCode: newValueToEdit,
        originalRegionOrPostalCodeToEdit: newValueToEdit,
        element,
        tour,
      });
    },
    newPostalCode: (tour, element, isFrom) => {
      const newPostalCode: BookingTourPostalCode = {
        type: isFrom ? BookingTourType.FROM : BookingTourType.TO,
        country: 'DE',
      };
      const newValueToEdit: RegionAndPostalCode = { postalCode: newPostalCode, region: undefined };
      setEditedTour({
        originalRegionOrPostalCodeToEdit: newValueToEdit,
        editedRegionOrPostalCode: newValueToEdit,
        element: element,
        tour: tour,
      });
    },
    changeTourDirection: (tour: BookingTour) => {
      const newTour: BookingTour = {
        ...tour,
        direction: tour.direction === 'TWO_WAY' ? BookingDirection.ONE_WAY : BookingDirection.TWO_WAY,
      };
      setTour(newTour);
    },
    clearEditedTour: () => setEditedTour(undefined),
    updateEditedTour: (regionOrPostalCode) =>
      setEditedTour((prevState) => {
        if (prevState == null) {
          return undefined;
        }
        return { ...prevState, editedRegionOrPostalCode: regionOrPostalCode };
      }),
    setTour: setTour,
  };

  return { editedTour, booking, actions, isValid };
}
