import { useTranslation } from 'react-i18next';
import { Box, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { ResultsSummary } from '../../../../components/table/schema/type/AlgotestResponseSchema';

export interface AlgotestResultsSummaryProps extends ResultsSummary {}

const AlgotestResultsSummary = (props: AlgotestResultsSummaryProps) => {
  const { t } = useTranslation('algotest');
  const theme = useTheme<Theme>();

  return (
    <Box sx={{ paddingBottom: '0.25rem' }}>
      <Typography
        data-testid='algotest-results-summary'
        sx={{
          fontSize: '14px',
          color: theme.palette.text.primary,
        }}
      >
        {t('resultsSummary', {
          numberOfPartners: props.numberOfPartners,
          numberOfAcceptedPartners: props.numberOfAcceptedPartners,
          queryExecutionTimeInMs: props.queryExecutionTimeInMs,
        })}
      </Typography>
    </Box>
  );
};

export default AlgotestResultsSummary;
