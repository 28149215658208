import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid2 } from '@mui/material';
import { PartnerResource } from '../../../../../api/partner/PartnerResource';
import {
  PartnerSettingsRequest,
  PartnerSettingsResource,
} from '../../../../../api/partnerSettings/PartnerSettingsResource';
import { ContractResource } from '../../../../../api/contract/ContractResourcePage';
import Label from '../../../../../components/form/Label';
import ToggleSwitch from '../../../../../components/form/ToggleSwitch';
import OnlineStatusReasonLabel from '../../../../../pages/partner/components/dashboard/onlineStatus/OnlineStatusReasonLabel';
import { useFeatureSwitchContext } from '../../../../../utils/FeatureSwitchContext';

type OnlineStatusProps = {
  isPartnerOnline: boolean;
  partner?: PartnerResource;
  partnerSettings?: PartnerSettingsResource;
  validContracts: ContractResource[];
  updatePartnerSettingsCallback: (cwid: string, partnerSettings: PartnerSettingsRequest) => void;
  isLoading: boolean;
};

const OnlineStatus: React.FC<OnlineStatusProps> = ({
  isPartnerOnline,
  partner,
  partnerSettings,
  updatePartnerSettingsCallback,
  validContracts,
  isLoading,
}) => {
  const { t } = useTranslation('dashboard');
  const { isEditPausedByCustomerCareDisabled } = useFeatureSwitchContext();

  const handleChange = () => {
    if (partner?.cwid != null && partnerSettings != null) {
      const newSettings: PartnerSettingsRequest = {
        ...partnerSettings,
        pausedByCustomerCare: !partner.isPausedByCustomerCare,
      };
      updatePartnerSettingsCallback(partner?.cwid, newSettings);
    }
  };

  const isToggleSwitchDisabled: () => boolean = () => {
    return validContracts.length === 0 || isLoading;
  };

  const renderOfflineReasonLabel = () => {
    if (partner && partnerSettings)
      return (
        <OnlineStatusReasonLabel
          partner={partner}
          partnerSettings={partnerSettings}
          validContracts={validContracts}
          isPartnerOnline={isPartnerOnline}
        />
      );
  };

  return (
    <>
      <Grid2 size={3}>
        <Label sx={{ textAlign: 'right' }} label={t('general.onlineLabel')} />
      </Grid2>
      <Grid2 size={1}>
        <ToggleSwitch
          data-testid={'onlineStatus'}
          disabled={isToggleSwitchDisabled() || isEditPausedByCustomerCareDisabled}
          onChange={() => handleChange()}
          checked={isPartnerOnline}
        />
      </Grid2>
      <Grid2 size={8}>{renderOfflineReasonLabel()}</Grid2>
    </>
  );
};

export default OnlineStatus;
