import { apiVersion, getApiClient } from '../ApiUtils';
import { ToggleHistoryResource } from './ToggleHistoryResource';

export function getToggleHistory(partnerCwid: string): Promise<ToggleHistoryResource[]> {
  return getApiClient()
    .then((client) =>
      client.get<ToggleHistoryResource[]>(`/partners/${partnerCwid}/auditlogs`, {
        baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion,
      }),
    )
    .then((response) => response.data);
}
