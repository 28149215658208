import React, { FC, ReactElement, useState } from 'react';
import { Box } from '@mui/material';
import { DateRange } from '../../../../api/bookingsSettings/BookingsSettingsResource';
import { useBookingsSettingsContext } from '../bookings/bookingsSettings/BookingsSettingsContext';
import DeletionConfirmationModal from '../bookings/modal/DeletionConfirmationModal';
import ActionImg from '../../../../components/shared/ActionImg';
import DateRangeCalendarModal from '../../../../components/dateRangeCalendar/DateRangeCalendarModal';
import { formatToLocaleTextWithDateTime } from '../../../../utils/DateUtil';

interface DateRowActionsProps {
  dateRange: DateRange;
}

const DateRowActions: FC<DateRowActionsProps> = ({ dateRange }: DateRowActionsProps): ReactElement => {
  const { exclusionDateRangeActions, deletionConfirmationModal } = useBookingsSettingsContext();

  const [isExclusionDateCalendarVisible, setIsExclusionDateCalendarVisible] = useState(false);

  const onSelectDateRange = (start: Date, end: Date) => {
    const newDateRange = {
      start: formatToLocaleTextWithDateTime(start),
      end: formatToLocaleTextWithDateTime(end),
    } as DateRange;
    exclusionDateRangeActions.onUpdateRelocationExclusionDateRange(dateRange, newDateRange);
    setIsExclusionDateCalendarVisible(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <ActionImg
          icon={'/icons/calendar.svg'}
          iconStyle={{ marginLeft: '25px' }}
          onClick={() => setIsExclusionDateCalendarVisible(true)}
        />
        <ActionImg
          icon={'/icons/trash.svg'}
          iconStyle={{ marginLeft: '25px' }}
          onClick={() => {
            exclusionDateRangeActions.onDeleteRelocationExclusionDateRange(dateRange);
          }}
        />
        {deletionConfirmationModal && <DeletionConfirmationModal {...deletionConfirmationModal} />}
      </Box>
      <DateRangeCalendarModal
        disablePast={true}
        initialStartDate={new Date(dateRange.start)}
        initialEndDate={dateRange.start === dateRange.end ? undefined : new Date(dateRange.end)}
        showModal={isExclusionDateCalendarVisible}
        onSelect={onSelectDateRange}
        onCancel={() => setIsExclusionDateCalendarVisible(false)}
      />
    </>
  );
};

export default DateRowActions;
