import React, { FC, ReactElement } from 'react';
import { Grid } from '@mui/material';
import LabeledField from '../../../../components/form/LabeledField';
import { useTranslation } from 'react-i18next';
import { useRequestsContext } from './RequestsContext';
import { calculatePercentage } from '../../../../utils/NumbersUtil';
import MultipleRowSkeleton from '../../../../components/feedback/MultipleRowSkeleton';

const LeadsSummary: FC = (): ReactElement => {
  const { totalElements, totalCancelled, loading } = useRequestsContext();
  const { t } = useTranslation('requests');

  const displaySkeletonOnLoadingOr = (reactElement: ReactElement) => {
    return loading ? <MultipleRowSkeleton rows={1} fontSize='30px' styles={{ width: '40%' }} /> : reactElement;
  };

  return (
    <Grid container>
      <Grid container item xs={12} md={6}>
        {displaySkeletonOnLoadingOr(
          <LabeledField label={t('leads.summary.labels.found')} children={<>{totalElements}</>} />,
        )}
      </Grid>

      <Grid container item xs={12} md={6}>
        {displaySkeletonOnLoadingOr(
          <LabeledField
            label={t('leads.summary.labels.cancelled')}
            children={<>{`${totalCancelled} (${calculatePercentage(totalCancelled, totalElements)} %)`}</>}
          />,
        )}
      </Grid>
    </Grid>
  );
};

export default LeadsSummary;
