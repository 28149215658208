import { apiVersion, getApiClient } from '../ApiUtils';

export function getInvoicePdf(documentNumber: string): Promise<Blob> {
  return getApiClient()
    .then((client) =>
      client.get<Blob>(`/invoices/${documentNumber}`, {
        baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion,
        responseType: 'blob',
      }),
    )
    .then((response) => new Blob([response.data], { type: 'application/pdf' }));
}
