import { FC, ReactElement } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RelocationRequestDetail } from '../../../../../api/relocationRequest/RelocationRequestDetail';
import { formatToLocaleTextWithDate } from '../../../../../utils/DateUtil';
import RealEstateDetailsSubsection from './RealEstateDetailsSubsection';
import MoveOutFeaturesSubsection from './MoveOutFeaturesSubsection';
import StorageSubsection from './StorageSubsection';

export type RelocationFromSubsectionProps = {
  relocationRequestDetail: RelocationRequestDetail;
};
const fromToSectionKeyOf = (postfix: string): string => `requestDetails.detailsTab.sections.fromTo.${postfix}`;

const RelocationFromSubsection: FC<RelocationFromSubsectionProps> = ({
  relocationRequestDetail: { fromObject, features, type, storage },
}: RelocationFromSubsectionProps): ReactElement => {
  const { t } = useTranslation('relocationRequests');
  const { relocationDate, street, postalCode, city, realEstateType, additionalInfo } = fromObject;

  return (
    <Box>
      <Box>
        {relocationDate &&
          t(fromToSectionKeyOf('relocationFromDate'), { date: formatToLocaleTextWithDate(relocationDate) })}
      </Box>
      <Box>{street}</Box>
      <Box sx={{ mb: 2.5 }}>
        {postalCode} {city}
      </Box>
      <Box>{fromObject && realEstateType && <RealEstateDetailsSubsection relocationRequestDetails={fromObject} />}</Box>
      <Box>
        <MoveOutFeaturesSubsection relocationFeatures={features} fromObject={fromObject} relocationType={type} />
      </Box>
      {storage && <StorageSubsection storage={storage} />}
      {additionalInfo && (
        <Box sx={{ mt: 2.5 }}>{t(fromToSectionKeyOf('additionalInformationFrom'), { info: additionalInfo })}</Box>
      )}
    </Box>
  );
};

export default RelocationFromSubsection;
