import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import React, { FC, ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RelocationRequestDetail } from '../../../../../api/relocationRequest/RelocationRequestDetail';

export type RelocationInventorySubsectionProps = {
  relocationRequestDetail?: RelocationRequestDetail;
};

type InventoryListLinkProps = {
  isActive: boolean | undefined;
  url: string;
};

const translationPrefix = 'requestDetails.detailsTab.sections.relocationType.inventory';

const InventoryListLink: FC<InventoryListLinkProps> = ({ isActive, url }): ReactElement => {
  const { t } = useTranslation('relocationRequests');
  if (isActive) {
    return (
      <Link to={url} target='_blank'>
        ({t('requestDetails.detailsTab.sections.relocationType.inventory.open')})
      </Link>
    );
  }
  return <></>;
};
const RelocationInventorySubsection: FC<RelocationInventorySubsectionProps> = ({
  relocationRequestDetail,
}: RelocationInventorySubsectionProps): ReactElement => {
  const { t } = useTranslation('relocationRequests');
  if (relocationRequestDetail?.requestId == null) {
    return <Box data-testid={'empty-inventory'}></Box>;
  }

  const { hasInventoryList, hasPhotoInventory } = relocationRequestDetail || {};
  const InventoryList = () => (
    <InventoryListLink
      isActive={hasInventoryList}
      url={`/relocation-requests/${relocationRequestDetail.requestId}/inventory-list`}
    />
  );
  const PhotoInventory = () => (
    <InventoryListLink
      isActive={hasPhotoInventory}
      url={`/relocation-requests/${relocationRequestDetail.requestId}/photo-inventory`}
    />
  );

  return (
    <Box data-testid={'inventory-container'}>
      <Box data-testid={'inventory-list'}>
        <Trans
          t={t}
          i18nKey={`${translationPrefix}.hasInventoryList`}
          values={{ info: t(`${translationPrefix}.${hasInventoryList ? 'yes' : 'no'}`) }}
          components={{ link: <InventoryList /> }}
        />
      </Box>
      <Box data-testid={'photo-inventory'}>
        <Trans
          t={t}
          i18nKey={`${translationPrefix}.hasPhotoInventory`}
          values={{ info: t(`${translationPrefix}.${hasPhotoInventory ? 'yes' : 'no'}`) }}
          components={{ link: <PhotoInventory /> }}
        />
      </Box>
      <Box data-testid={'free-text'}>
        <Trans
          t={t}
          i18nKey={`${translationPrefix}.freeText`}
          values={{
            info:
              relocationRequestDetail.freeTextInventoryList &&
              relocationRequestDetail.freeTextInventoryList.trim() !== ''
                ? relocationRequestDetail.freeTextInventoryList
                : t(`${translationPrefix}.no`),
          }}
        />
      </Box>
    </Box>
  );
};

export default RelocationInventorySubsection;
