import React from 'react';
import {
  ShopSubscribedRegion,
  ShopSubscription,
} from '../../../../../../api/bookingsSettings/BookingsSettingsResource';
import { Region } from '../../../../../../api/region/RegionResource';
import { AlertProps } from '../../../../../../components/feedback/Alert';

const emptyFunction = () => {};

export type ShopSubscriptionSettingsActionsProps = {
  onAddNewShopSubscribedRegion: (element: HTMLDivElement | null) => void;
  onEditShopSubscribedRegion: (shopSubscribedRegion: ShopSubscribedRegion, element: HTMLDivElement | null) => void;
  onChangeShopNewsletter: (isSubscribed: boolean) => void;
  onChangeShopNewsletterFrequency: (frequencyPerDay: 1 | 2) => void;
  onChangeSelectedCountryOrRegion: (value: string | number) => void;
  onChangePostalCode: (postalCode: string) => void;
  onSaveShopSubscribedRegion: () => void;
  onRemoveShopSubscribedRegion: () => void;
};

export type ShopSubscriptionSettingsContextProps = {
  shopSubscription: ShopSubscription | undefined;
  selectedShopSubscribedRegion: ShopSubscribedRegion | undefined;
  selectedHtmlElement: HTMLDivElement | null;
  allRegions: Region[];
  isLoading: boolean;
  alert?: AlertProps;
  actions: ShopSubscriptionSettingsActionsProps;
};

export const ShopSubscriptionSettingsContext = React.createContext<ShopSubscriptionSettingsContextProps>({
  shopSubscription: undefined,
  selectedShopSubscribedRegion: undefined,
  selectedHtmlElement: null,
  allRegions: [],
  isLoading: false,
  actions: {
    onAddNewShopSubscribedRegion: emptyFunction,
    onEditShopSubscribedRegion: emptyFunction,
    onChangeShopNewsletter: emptyFunction,
    onChangeShopNewsletterFrequency: emptyFunction,
    onChangeSelectedCountryOrRegion: emptyFunction,
    onChangePostalCode: emptyFunction,
    onSaveShopSubscribedRegion: emptyFunction,
    onRemoveShopSubscribedRegion: emptyFunction,
  },
});
