import { apiVersion, getApiClient, mapFilters } from '../ApiUtils';
import { Page } from '../Page';
import { RelocationRequest, RelocationRequestPage } from './RelocationRequest';
import { RelocationRequestFilters } from './RelocationRequestFilters';
import { RelocationRequestDetail, RelocationRequestInventory } from './RelocationRequestDetail';

const RELOCATION_REQUEST_API_PREFIX = '/relocationrequest';

export function getRelocationRequests(
  page: number = 0,
  size: number = 20,
  filters: RelocationRequestFilters,
): Promise<
  Page<{
    requests: RelocationRequest[];
  }>
> {
  const filterString = mapFilters(filters);
  return getApiClient()
    .then((client) =>
      client.get<Page<RelocationRequestPage>>(
        `${RELOCATION_REQUEST_API_PREFIX}?page=${page}&size=${size}&${filterString}`,
        { baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion },
      ),
    )
    .then((response) => response.data);
}

export function getRelocationRequestDetail(requestId: string): Promise<RelocationRequestDetail> {
  return getApiClient()
    .then((client) =>
      client.get<RelocationRequestDetail>(`${RELOCATION_REQUEST_API_PREFIX}/${requestId}`, {
        baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion,
      }),
    )
    .then((response) => response.data);
}

export function getRelocationRequestInventory(requestId: string): Promise<RelocationRequestInventory> {
  return getApiClient()
    .then((client) =>
      client.get<RelocationRequestInventory>(`${RELOCATION_REQUEST_API_PREFIX}/${requestId}/inventory`, {
        baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion,
      }),
    )
    .then((response) => response.data);
}

export function anonymizeRelocationRequest(requestId: string): Promise<RelocationRequestDetail> {
  return getApiClient()
    .then((client) =>
      client.post<RelocationRequestDetail>(`${RELOCATION_REQUEST_API_PREFIX}/${requestId}/anonymize`, undefined, {
        baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion,
      }),
    )
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(`Cannot anonymize relocation request ${requestId}`);
      }
      return response.data;
    });
}
