import React, { FC, ReactElement } from 'react';
import { Box } from '@mui/material';
import { Formik } from 'formik';
import { BookingTestFilterValues, INITIAL_BOOKING_TEST_FILTER_VALUES } from '../shared/BookingTestFilterModel';
import { bookingTestModelToForm } from '../shared/BookingTestModelToForm';
import { BookingTestDataRequest } from '../../../../api/bookingtest/BookingTestDataRequest';
import DateSettings from './form/DateSettings';
import TestTourSettings from './form/tours/TestTourSettings';
import DetailsSettings from './form/DetailsSettings';
import RelocationTypeSettings from './form/RelocationTypeSettings';
import { bookingTestSchema } from './form/tours/BookingTestSchema';

type BookingTestFiltersProps = {
  onBookingTestRequestChanged: (request: BookingTestDataRequest) => void;
  loading?: boolean;
};

const BookingTestFilters: FC<BookingTestFiltersProps> = (props: BookingTestFiltersProps): ReactElement => {
  const handleOnSubmit = (value: BookingTestFilterValues) => {
    props.onBookingTestRequestChanged(bookingTestModelToForm(value));
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        padding: '25px 0',
      }}
    >
      <Formik
        initialValues={INITIAL_BOOKING_TEST_FILTER_VALUES}
        onSubmit={handleOnSubmit}
        validationSchema={bookingTestSchema}
      >
        <BookingTestFormFilters />
      </Formik>
    </Box>
  );
};
const BookingTestFormFilters = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '1021px',
      }}
    >
      <DateSettings />
      <TestTourSettings />
      <DetailsSettings />
      <RelocationTypeSettings />
    </Box>
  );
};

export default BookingTestFilters;
