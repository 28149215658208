import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDocumentsContext } from './DocumentsContext';
import Section from '../../../../components/form/Section';
import UmzugEasyBasicTable, { TableDataProps } from '../../../../components/basicTable/UmzugEasyBasicTable';
import { PartnerDocument } from '../../../../api/partnerDocuments/PartnerDocumentsResource';
import DocumentsActions from './DocumentsActions';
import { Box, Link } from '@mui/material';
import Button from '../../../../components/form/Button';
import ActionImg from '../../../../components/shared/ActionImg';
import { DocumentsProps } from '../Documents';
import { formatToLocaleTextWithDate } from '../../../../utils/DateUtil';

const tableDataPropsOf = (
  data: any,
  style?: any,
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify',
): TableDataProps => ({
  data,
  style,
  align,
});

const OtherDocumentsList: FC<DocumentsProps> = (props: DocumentsProps): ReactElement => {
  const { t } = useTranslation('partnerDocuments');
  const { otherPartnerDocuments, actions, document } = useDocumentsContext();

  const headerOf = (key?: string): TableDataProps => ({
    data: key ? t(`header.${key}`) : '',
    style: { paddingLeft: '0px' },
  });
  const header = [
    headerOf(),
    headerOf(),

    headerOf('filename'),
    headerOf('confirmedBy'),
    headerOf('expiryDate'),
    headerOf(),
  ];

  const rowOf = (document: PartnerDocument): TableDataProps[] => [
    tableDataPropsOf('', {
      paddingLeft: '25px',
      width: props.documentsColumnsWidth.available,
      minWidth: props.documentsColumnsWidth.available,
    }),
    tableDataPropsOf('', {
      width: props.documentsColumnsWidth.document,
      minWidth: props.documentsColumnsWidth.document,
    }),

    tableDataPropsOf(
      <Link
        underline='hover'
        color='inherit'
        href='#'
        onClick={() => actions.onPartnerDocumentOpen(document.partnerCwid, document.id)}
      >
        {document.fileName}
      </Link>,
      { width: props.documentsColumnsWidth.filename, minWidth: props.documentsColumnsWidth.filename },
    ),
    tableDataPropsOf(document.modifiedBy, {
      width: props.documentsColumnsWidth.confirmedBy,
      minWidth: props.documentsColumnsWidth.confirmedBy,
    }),
    tableDataPropsOf(formatToLocaleTextWithDate(document.expirationDate), {
      width: props.documentsColumnsWidth.expiryDate,
      minWidth: props.documentsColumnsWidth.expiryDate,
    }),
    tableDataPropsOf(
      <DocumentsActions
        onRemove={() => actions.onPartnerDocumentRemove(document.id)}
        onEdit={() => actions.onEditModalOpen(document)}
      />,
    ),
  ];

  const rows = otherPartnerDocuments.map((document) => rowOf(document));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', paddingBottom: '0px' }}>
      <Section title={t('otherDocuments')} styles={props.tableSectionStyles}>
        <UmzugEasyBasicTable header={header} rows={rows} />
      </Section>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          icon={<ActionImg icon={'/icons/plus.svg'} iconStyle={{ marginRight: '5px' }} />}
          label={t('editModal.actions.addNew')}
          type={'button'}
          appearance={'link'}
          onClick={() => actions.onEditModalOpen(document, undefined, t('otherDocuments'))}
        />
      </Box>
    </Box>
  );
};

export default OtherDocumentsList;
