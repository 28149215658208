export type PartnerDocument = {
  id: number;
  partnerCwid: string;
  fileName: string;
  filePath: string;
  type?: PartnerDocumentType;
  licenceNumber?: string;
  expirationDate?: Date;
  available: boolean;
  modifiedBy: string;
};

export enum PartnerDocumentType {
  EU_LICENSE = 'EU_LICENSE',
  AMOE_MEMBER = 'AMOE_MEMBER',
  ISO_CERTIFICATE = 'ISO_CERTIFICATE',
  TUEV_CERTIFICATE = 'TUEV_CERTIFICATE',
  BUSINESS_REGISTRATION = 'BUSINESS_REGISTRATION',
  MOE_FORM = 'MOE_FORM',
  FIDI_MEMBER = 'FIDI_MEMBER',
}

export type LicenseDuplicateCheckResponse = {
  duplicate: Boolean;
  alreadyUsedByPartner: Boolean;
  partnersUsingLicence: Array<string>;
};
