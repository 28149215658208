import { Box, Modal as MuiModal, modalClasses, styled } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export type ModalProps = {
  title: string | JSX.Element;
  children: JSX.Element;
  isOpen: boolean;
  width: string;
  sx?: SxProps<Theme>;
  padding?: string;
};

const StyledModal = styled(MuiModal)<{ width: string; padding?: string }>(({ theme, width, padding }) => ({
  [`&.${modalClasses.root} #umzug-easy-modal`]: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '5px',
    height: 'auto',
    maxHeight: '100vh',
    maxWidth: '100vw',
    width: width,
    transform: 'translate(-50%, -50%)',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
    padding: padding != null ? padding : '40px',
  },
  [`&.${modalClasses.root} #umzug-easy-modal-title`]: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '33px',
    color: theme.umzugEasy.palette.darkGray,
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },
  [`&.${modalClasses.root}`]: {
    top: '50%',
    left: '50%',
  },
}));

const Modal = (props: ModalProps) => {
  return (
    <StyledModal
      sx={props.sx}
      width={props.width}
      open={props.isOpen}
      padding={props.padding}
      aria-labelledby='umzug-easy-modal-title'
      aria-describedby='umzug-easy-modal-description'
    >
      <Box id={'umzug-easy-modal'} sx={{ outline: 'none' }}>
        <Box id={'umzug-easy-modal-title'}>{props.title}</Box>
        <Box id={'umzug-easy-modal-description'}>{props.children}</Box>
      </Box>
    </StyledModal>
  );
};
export default Modal;

export const closeModal = (stateSetter: (prevState: any) => void) => {
  // @ts-ignore
  stateSetter((prevState) => ({
    ...prevState,
    open: false,
  }));
};

export const openModal = (stateSetter: (prevState: any) => void) => {
  // @ts-ignore
  stateSetter((prevState) => ({
    ...prevState,
    open: true,
  }));
};
