import React from 'react';
import { Grid, Theme } from '@mui/material';
import LabeledField from '../../../../components/form/LabeledField';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import ContactEmails from './ContactEmails';
import { usePartnerContext } from '../../shared/PartnerContext';
import { SelectedContactEmail } from '../../../../api/partner/PartnerResource';

const contactDataStyles = makeStyles((theme: Theme) => ({
  emailsLabel: {
    color: theme.palette.text.primary,
    paddingRight: '1rem',
    fontSize: '14px',
    lineHeight: '40px',
    fontStyle: 'italic',
    textAlign: 'right',
  },
}));

const ContactData: React.FC = () => {
  const { t } = useTranslation('partner');

  const classes = contactDataStyles();
  const { partner, partnerSettings } = usePartnerContext();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid item xs={1.5} className={classes.emailsLabel}>
          {t('mainData.labels.standardEmail')}
        </Grid>
      </Grid>

      <Grid container item xs={12} md={6}>
        <ContactEmails
          salesforceEmail={partner?.contact?.email?.fromSalesforce}
          selectedContactEmail={
            partnerSettings?.alternativeMailSelected
              ? SelectedContactEmail.Alternative
              : SelectedContactEmail.Salesforce
          }
          alternativeEmail={partnerSettings?.alternativeMail}
        />
      </Grid>

      <Grid container item xs={12} md={6}>
        <LabeledField
          label={t('mainData.labels.invoicesEmail')}
          children={<>{partner?.shippingAddress.email || partner?.email}</>}
        />
        <LabeledField label={t('mainData.labels.websiteUrl')} children={<>{partner?.companyDetails.websiteUrl}</>} />
      </Grid>
    </Grid>
  );
};

export default ContactData;
