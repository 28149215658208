import { apiVersion, getApiClient } from '../ApiUtils';
import {
  RelocationExclusionDateRanges,
  BookingsSettings,
  DateRange,
  ShopSubscription,
} from './BookingsSettingsResource';
import { formatToIsoTextWithLocalDate } from '../../utils/DateUtil';

const bookingsSettingsPath = (partnerCwid: string) => `/partners/${partnerCwid}/settings/bookings`;
const relocationExclusionDateRangesPath = (partnerCwid: string) =>
  `${bookingsSettingsPath(partnerCwid)}/relocation-exclusion-dates`;
const shopSubscriptionPath = (partnerCwid: string) => `/partners/${partnerCwid}/settings/bookings/shop-subscription`;

export function getBookingsSettings(partnerCwid: string): Promise<BookingsSettings> {
  return getApiClient()
    .then((client) =>
      client.get<BookingsSettings>(bookingsSettingsPath(partnerCwid), {
        baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion,
      }),
    )
    .then((response) => response.data);
}

export function updateBookingSettings(
  partnerCwid: string,
  bookingSettings: BookingsSettings,
): Promise<BookingsSettings> {
  return getApiClient()
    .then((client) =>
      client.put(bookingsSettingsPath(partnerCwid), bookingSettings, {
        baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion,
      }),
    )
    .then((response) => response.data);
}

export function addRelocationExclusionDateRange(
  partnerCwid: string,
  startDate: Date,
  endDate: Date,
): Promise<RelocationExclusionDateRanges> {
  const dateRange = {
    start: formatToIsoTextWithLocalDate(startDate),
    end: formatToIsoTextWithLocalDate(endDate),
  } as DateRange;
  return getApiClient()
    .then((client) =>
      client.post(
        relocationExclusionDateRangesPath(partnerCwid),
        { relocationExclusionDateRanges: [dateRange] },
        { baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion },
      ),
    )
    .then((response) => response.data);
}

export function updateRelocationExclusionDateRange(
  partnerCwid: string,
  dateRange: DateRange,
  newDateRange: DateRange,
): Promise<RelocationExclusionDateRanges> {
  return getApiClient()
    .then((client) =>
      client.put(
        relocationExclusionDateRangesPath(partnerCwid),
        { value: dateRange, newValue: newDateRange },
        { baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion },
      ),
    )
    .then((response) => response.data);
}
export function deleteRelocationExclusionDateRange(
  partnerCwid: string,
  dateRange: DateRange,
): Promise<RelocationExclusionDateRanges> {
  return getApiClient()
    .then((client) =>
      client.delete(`${relocationExclusionDateRangesPath(partnerCwid)}?start=${dateRange.start}&end=${dateRange.end}`, {
        baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion,
      }),
    )
    .then((response) => response.data);
}

export function getShopSubscription(partnerCwid: string): Promise<ShopSubscription> {
  return getApiClient()
    .then((client) =>
      client.get<ShopSubscription>(shopSubscriptionPath(partnerCwid), {
        baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion,
      }),
    )
    .then((response) => response.data);
}

export function updateShopSubscription(shopSubscription: ShopSubscription): Promise<ShopSubscription> {
  return getApiClient()
    .then((client) =>
      client.put<ShopSubscription>(shopSubscriptionPath(shopSubscription.partnerCwid), shopSubscription, {
        baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion,
      }),
    )
    .then((response) => response.data);
}
