import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { YearlyInvoicesStatisticsResource } from '../../../../api/partner/InvoicesStatisticsResource';
import { InvoiceType } from '../../../../api/partner/InvoiceType';
import InvoicesList from './InvoicesList';
import { formatToTextWithTwoDecimalPlaces } from '../../../../utils/NumbersUtil';

export type YearlyInvoicesSummaryProps = {
  yearlyInvoiceSummary: YearlyInvoicesStatisticsResource;
};

const containerStyles: SxProps<Theme> = {
  paddingTop: '15px',
  borderBottom: (theme) => `1px solid ${theme.palette.primary.contrastText}`,
};

const headerStyles: SxProps<Theme> = {
  color: (theme) => theme.palette.primary.dark,
  fontSize: '14px',
  fontWeight: '600',
};

const YearlyInvoicesSummary: React.FC<YearlyInvoicesSummaryProps> = ({
  yearlyInvoiceSummary,
}: YearlyInvoicesSummaryProps) => {
  const { t } = useTranslation('partner');

  const getInvoicesCount = (): number => {
    return yearlyInvoiceSummary.invoices.filter((invoice) => invoice.documentType === InvoiceType.INVOICE).length;
  };

  return (
    <Grid container sx={containerStyles}>
      <Grid item xs={12} sx={headerStyles}>
        {t('bills.yearSummary', {
          year: yearlyInvoiceSummary.year,
          numberOfInvoices: getInvoicesCount(),
          totalAmount: formatToTextWithTwoDecimalPlaces(yearlyInvoiceSummary.totalNetAmount),
        })}
      </Grid>
      <Grid
        container
        sx={{
          justifyContent: 'flex-end',
        }}
      >
        <Grid item xs={12} md={11} lg={9}>
          <InvoicesList invoices={yearlyInvoiceSummary.invoices} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default YearlyInvoicesSummary;
