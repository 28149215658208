import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { InvoiceSummaryResource } from '../../../../api/partner/InvoicesStatisticsResource';
import InvoiceRow from './InvoiceRow';
import { compareDates } from '../../../../utils/DateUtil';

export type InvoicesTableProps = {
  invoices: InvoiceSummaryResource[];
};

const listHeaderStyles: SxProps<Theme> = {
  paddingLeft: '12px',
  paddingBottom: '5px',
  color: (theme) => theme.palette.text.secondary,
  fontSize: '14px',
  fontStyle: 'italic',
};

const listContainerStyles: SxProps<Theme> = {
  borderTop: (theme) => `1px solid ${theme.palette.primary.contrastText}`,
};

const InvoicesList: React.FC<InvoicesTableProps> = ({ invoices }: InvoicesTableProps) => {
  const { t } = useTranslation('partner');

  const getInvoiceRows = (): JSX.Element[] => {
    return invoices
      .sort((a, b) => compareDates(a.creationDate, b.creationDate, 'desc'))
      .map((invoice) => <InvoiceRow key={invoice.documentNumber} invoice={invoice} />);
  };

  return (
    <Grid container>
      <Grid container sx={listHeaderStyles}>
        <Grid item xs={3} md={2.5}>
          {t('bills.table.columns.documentNumber')}
        </Grid>
        <Grid item xs={3} md={2.5}>
          {t('bills.table.columns.documentType')}
        </Grid>
        <Grid item xs={3} md={2.5}>
          {t('bills.table.columns.issueDate')}
        </Grid>
        <Grid item xs={3} md={4.5}>
          {t('bills.table.columns.notSettled')}
        </Grid>
      </Grid>
      <Grid container sx={listContainerStyles}>
        {getInvoiceRows()}
      </Grid>
    </Grid>
  );
};

export default InvoicesList;
