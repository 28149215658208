import { useContext, useEffect, useState } from 'react';
import { DocumentsActionsProps, DocumentsContext, DocumentsContextProps } from './DocumentsContext.model';
import {
  LicenseDuplicateCheckResponse,
  PartnerDocument,
  PartnerDocumentType,
} from '../../../../api/partnerDocuments/PartnerDocumentsResource';
import {
  changePartnerDocumentAvailability,
  checkLicenseForDuplicates,
  createAvailableEmptyPartnerDocumentMetadata,
  deletePartnerDocument,
  downloadPartnerDocument,
  getPartnerDocuments,
} from '../../../../api/partnerDocuments/PartnerDocumentsApi';
import { DeletionConfirmationModalProps } from '../../../../components/form/DeletionConfirmationModal';
import { useTranslation } from 'react-i18next';
import { AlertProps } from '../../../../components/feedback/Alert';

export function useInitDocumentsContext(partnerCwid: string | undefined): DocumentsContextProps {
  const [standardPartnerDocuments, setStandardPartnerDocuments] = useState<PartnerDocument[]>([]);
  const [otherPartnerDocuments, setOtherPartnerDocuments] = useState<PartnerDocument[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [deletionConfirmationModal, setDeletionConfirmationModal] = useState<
    DeletionConfirmationModalProps | undefined
  >(undefined);
  const [isDeleting, setDeleting] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertProps | undefined>(undefined);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [documentType, setDocumentType] = useState<PartnerDocumentType | undefined>(undefined);
  const [documentDisplayType, setDocumentDisplayType] = useState<string | undefined>(undefined);
  const [document, setDocument] = useState<PartnerDocument | undefined>(undefined);
  const [licenseDuplicateCheckResponse, setLicenseDuplicateCheckResponse] = useState<
    LicenseDuplicateCheckResponse | undefined
  >(undefined);

  const { t } = useTranslation('partnerDocuments');

  const hideDeletionConfirmationModal = () => setDeletionConfirmationModal(undefined);
  const showAlert = (translationKey: string) => {
    setAlert({
      color: 'error',
      open: true,
      text: t(`alerts.${translationKey}`),
      handleClose: () => setAlert(undefined),
    });
  };

  const executeDocumentsUpdate = (
    setPartnerDocumentList: (value: ((prevState: PartnerDocument[]) => PartnerDocument[]) | PartnerDocument[]) => void,
    payload: PartnerDocument,
    partnerDocumentId?: number,
  ) => {
    if (partnerDocumentId) {
      setPartnerDocumentList((prevState) =>
        prevState.map((document) => {
          if (document.id === payload.id) {
            document = { ...payload };
          }
          return document;
        }),
      );
    } else {
      setPartnerDocumentList((prevState) => [...prevState, payload]);
    }
    setDocument(undefined);
  };

  const actions: DocumentsActionsProps = {
    onPartnerDocumentOpen: async (partnerCwid: string, partnerDocumentId: number) => {
      try {
        const fileUrl = await downloadPartnerDocument(partnerCwid, partnerDocumentId);
        if (fileUrl) {
          window.open(fileUrl, '_blank', 'noopener noreferrer');
        } else {
          console.error('Failed to download partner document file');
        }
      } catch (error) {
        console.error('Error downloading partner document file', error);
      }
    },
    onPartnerDocumentRemove: (partnerDocumentId?: number) => {
      if (partnerDocumentId) {
        setDeletionConfirmationModal({
          labels: {
            title: t('deletionConfirmation.title'),
            description: t('deletionConfirmation.description'),
            buttonYes: t('deletionConfirmation.buttonYes'),
            buttonNo: t('deletionConfirmation.buttonNo'),
          },
          confirm: () => {
            if (partnerCwid) {
              setDeleting(true);
              deletePartnerDocument(partnerCwid, partnerDocumentId)
                .then(() => {
                  setStandardPartnerDocuments((prevState) =>
                    prevState.filter((document) => document.id !== partnerDocumentId),
                  );
                  setOtherPartnerDocuments((prevState) =>
                    prevState.filter((document) => document.id !== partnerDocumentId),
                  );
                })
                .catch(() => showAlert('removingPartnerDocumentError'))
                .finally(() => {
                  setDeleting(false);
                  hideDeletionConfirmationModal();
                });
            }
          },
          cancel: () => hideDeletionConfirmationModal(),
        });
      }
    },
    onChangePartnerDocumentAvailability: (
      isAvailable: boolean,
      type: PartnerDocumentType,
      partnerDocumentId?: number,
    ) => {
      if (partnerCwid) {
        if (partnerDocumentId) {
          changePartnerDocumentAvailability(partnerCwid, partnerDocumentId, isAvailable)
            .then(() =>
              setStandardPartnerDocuments((prevState) =>
                prevState.map((document) => {
                  if (document.id === partnerDocumentId) {
                    document.available = isAvailable;
                  }
                  return document;
                }),
              ),
            )
            .catch(() => showAlert('updatePartnerDocumentError'));
        } else if (!partnerDocumentId && isAvailable) {
          createAvailableEmptyPartnerDocumentMetadata(partnerCwid, type)
            .then((newPartnerDocument) =>
              setStandardPartnerDocuments((prevState) => [...prevState, newPartnerDocument]),
            )
            .catch(() => showAlert('updatePartnerDocumentError'));
        }
      }
    },
    onPartnerDocumentUpload: (payload: PartnerDocument, partnerDocumentId?: number) => {
      executeDocumentsUpdate(
        payload.type ? setStandardPartnerDocuments : setOtherPartnerDocuments,
        payload,
        partnerDocumentId,
      );
    },
    onEditModalOpen: (document?: PartnerDocument, type?: PartnerDocumentType, displayType?: string) => {
      setDocument(document);
      setDocumentType(type);
      setDocumentDisplayType(displayType);
      setIsEditModalOpen(true);
    },
    onEditModalClose: () => {
      setIsEditModalOpen(false);
      setDocumentType(undefined);
      setDocumentDisplayType(undefined);
      setDocument(undefined);
    },
    onCheckLicenseDuplicates: (licenseNumber: string) => {
      if (partnerCwid) {
        checkLicenseForDuplicates(partnerCwid, licenseNumber)
          .then((result) => {
            setLicenseDuplicateCheckResponse(result);
          })
          .catch(() => showAlert('checkLicenseDuplicatesError'));
      }
    },
  };

  useEffect(() => {
    if (partnerCwid) {
      setLoading(true);
      getPartnerDocuments(partnerCwid)
        .then((result) => {
          setStandardPartnerDocuments(result.filter((partnerDocument) => partnerDocument.type !== null));
          setOtherPartnerDocuments(result.filter((partnerDocument) => partnerDocument.type === null));
        })
        .finally(() => setLoading(false));
    }
  }, [partnerCwid]);

  return {
    standardPartnerDocuments,
    otherPartnerDocuments,
    isLoading,
    deletionConfirmationModal,
    isDeleting,
    alert,
    document,
    documentType,
    documentDisplayType,
    isEditModalOpen,
    actions,
    licenseDuplicateCheckResponse,
  };
}

export function useDocumentsContext(): DocumentsContextProps {
  return useContext(DocumentsContext);
}
