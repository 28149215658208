import React, { FC, useEffect, useState } from 'react';
import { Box, styled } from '@mui/material';
import LabeledField from '../../../../../components/form/LabeledField';
import { useFormikContext } from 'formik';
import { TextField } from '../../../../../components/form/TextField';
import { DocumentsModalForm } from '../DocumentsContext.model';
import { PartnerDocument } from '../../../../../api/partnerDocuments/PartnerDocumentsResource';
import { useTranslation } from 'react-i18next';

type NameProps = {
  label: string;
  document?: PartnerDocument;
  errors: {};
  setErrors: Function;
};

const TextFieldContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const ErrorMessageContainer = styled('span')({
  fontSize: 'x-small',
  margin: '-20px 0 0 10px',
  color: 'red',
});

const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'png', 'tiff', 'tif', 'bmp'];

const Name: FC<NameProps> = (props: NameProps) => {
  const { t } = useTranslation('partnerDocuments');
  const formik = useFormikContext<DocumentsModalForm>();
  const [isNameToLong, setIsNameToLong] = useState<boolean>(false);
  const [isExtensionInvalid, setIsExtensionInvalid] = useState<boolean>(false);
  const { errors, label, setErrors } = props;

  const isExtensionErroneous = (name?: string) => {
    if (!name) return false;
    if (name.lastIndexOf('.') === -1) return true;
    const extension = name.substring(name.lastIndexOf('.') + 1);
    return !allowedExtensions.includes(extension);
  };

  useEffect(() => {
    const value = formik.values.name;
    if (value && value?.length >= 40) {
      setIsNameToLong(true);
      setErrors({ ...errors, nameTooLong: true });
    } else {
      setIsNameToLong(false);
      setErrors({ ...errors, nameTooLong: false });
    }
    if (isExtensionErroneous(value)) {
      setIsExtensionInvalid(true);
      setErrors({ ...errors, extension: true });
    } else {
      setIsExtensionInvalid(false);
      setErrors({ ...errors, extension: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.name]);

  return (
    <Box sx={{ display: 'block' }}>
      <LabeledField label={label}>
        <TextFieldContainer>
          <TextField
            sx={{
              width: '300px',
              border: isNameToLong || isExtensionInvalid ? '1px solid red' : '',
              margin: '5px 10px',
            }}
            id={'name'}
            key={'name'}
            type={'string'}
            value={formik.values?.name || null}
            onChange={(e) => formik.handleChange(e)}
          />
          {isNameToLong && <ErrorMessageContainer>{t('editModal.errors.maxLength')}</ErrorMessageContainer>}
          {isExtensionInvalid && <ErrorMessageContainer>{t('editModal.errors.fileExtension')}</ErrorMessageContainer>}
        </TextFieldContainer>
      </LabeledField>
    </Box>
  );
};

export default Name;
