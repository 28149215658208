import { RelocationRequestDetail } from '../../../../../api/relocationRequest/RelocationRequestDetail';
import { Box } from '@mui/material';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

export type ContactSubsectionProps = {
  relocationRequestDetail: RelocationRequestDetail;
};

const contactSectionStyles = makeStyles(() => ({
  email: {
    wordBreak: 'break-all',
  },
}));

const contactSectionKeyOf = (postfix: string): string => `requestDetails.detailsTab.sections.contact.${postfix}`;

const ContactDataSubsection: FC<ContactSubsectionProps> = ({
  relocationRequestDetail: { consumer, payment },
}: ContactSubsectionProps): ReactElement => {
  const { t } = useTranslation('relocationRequests');
  const classes = contactSectionStyles();

  return (
    <Box>
      <Box>
        {consumer.salutation && t(contactSectionKeyOf(`salutation.${consumer.salutation?.toLowerCase()}`))}{' '}
        {consumer.firstName} {consumer.lastName}
      </Box>
      <Box>{t(contactSectionKeyOf('phone'), { phone: consumer.phone })}</Box>
      <Box className={classes.email}>{t(contactSectionKeyOf('email'), { email: consumer.email })}</Box>
      <Box>
        {payment &&
          t(contactSectionKeyOf(`invoicing`), {
            invoicingType: t(contactSectionKeyOf(`invoicingType.${payment.toLowerCase()}`)),
          })}
      </Box>
    </Box>
  );
};

export default ContactDataSubsection;
