import React, { ChangeEvent, useEffect } from 'react';
import { useFormikContext } from 'formik';
import ActionImg from '../shared/ActionImg';
import { TextField } from './TextField';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export type FormEditableTextFieldProps = {
  value?: string;
  formikName: string;
  submitOnChange?: boolean;
};

const editableTextFieldStyle = makeStyles((theme: Theme) => ({
  nonEditableValue: {
    paddingRight: '0.5rem',
  },
  errorBorder: {
    border: `1px solid ${theme.palette.error.main}`,
  },
}));

const FormEditableTextField: React.FC<FormEditableTextFieldProps> = ({
  value,
  submitOnChange,
  formikName,
}: FormEditableTextFieldProps) => {
  const classes = editableTextFieldStyle();

  const [isEditing, setIsEditing] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(value);
  const [isValid, setValid] = React.useState(true);

  const formik = useFormikContext();
  const fieldProps = formik.getFieldHelpers(formikName);

  const onSubmit = () => {
    if (isValid) {
      if (submitOnChange) {
        formik.submitForm();
      }
      setIsEditing(false);
    }
  };

  useEffect(() => {
    fieldProps.setValue(inputValue, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    setValid((formik.errors as any)[formikName] == null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors]);

  const getDisplayableValue = (): JSX.Element => {
    if (isEditing) {
      return (
        <TextField
          data-testid={'editable-value'}
          classes={{ root: isValid ? '' : classes.errorBorder }}
          error={!isValid}
          value={inputValue}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setInputValue(event.target.value)}
        />
      );
    }

    return (
      <span className={classes.nonEditableValue} data-testid={'read-only-value'}>
        {inputValue}
      </span>
    );
  };

  return (
    <>
      {getDisplayableValue()}
      {!isEditing && (
        <ActionImg icon={'/icons/edit.svg'} onClick={() => setIsEditing(true)} data-testid={'edit-button'} />
      )}
      {/* TODO there is no save icon in design yet */}
      {isEditing && <ActionImg icon={'/icons/S.svg'} onClick={onSubmit} data-testid={'submit-button'} />}
    </>
  );
};

export default FormEditableTextField;
