import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import { Theme } from '@mui/material';
import { BookingTour, BookingTourType } from '../../../api/booking/BookingResource';
import { Region } from '../../../api/region/RegionResource';
import TwoWayIcon from '../../../assets/icons/two-way.svg';
import OneWayIcon from '../../../assets/icons/one-way.svg';
import { PartnerRoutes } from '../../../routes/Routes';
import { ChipItem } from '../../shared/ChipItem';
import ActionIcon from '../../shared/ActionIcon';

const getPartnerCompanyNameRendererStyles = makeStyles((theme: Theme) => ({
  companyName: {
    display: 'flex',
    minWidth: 280,
    maxHeight: 20,
  },
  name: {
    flexGrow: 1,
    color: theme.palette.common.black,
    whiteSpace: 'nowrap',
  },
}));

const getBoldRendererStyles = makeStyles((theme: Theme) => ({
  bold: {
    display: 'flex',
    maxHeight: 20,
    fontWeight: 'bold',
  },
  name: {
    flexGrow: 1,
    color: theme.palette.common.black,
    whiteSpace: 'nowrap',
  },
}));

const getBookingTourRendererStyles = makeStyles((theme: Theme) => ({
  general: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 400,
    maxWidth: '100%',
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
  },
  excluded: {
    color: theme.palette.error.main,
  },
}));

const getEmailRendererStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.warning.main,
  },
}));
const getLabelWithMarkRendererStyles = makeStyles((theme: Theme) => ({
  normal: {
    height: 'calc(100% - 4px)',
    width: '4px',
    position: 'absolute',
    top: '2px',
    left: 0,
  },
  colorSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  colorError: {
    backgroundColor: theme.palette.error.main,
  },
}));

const getLinkRendererStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.text.secondary,
    textDecoration: 'none',
  },
}));

export const DefaultRenderer = (content: string | number) => {
  return <span style={{ display: 'block', width: 'max-content' }}>{content}</span>;
};

export const LabelWithMarkRenderer = (color: 'green' | 'red' | 'unset', text: string) => {
  const styles = getLabelWithMarkRendererStyles();
  const colorStyles = {
    green: styles.colorSuccess,
    red: styles.colorError,
    unset: '',
  };
  const classes = [styles.normal, colorStyles[color]].join(' ').trim();
  return (
    <span style={{ display: 'block', width: 'max-content' }}>
      <div className={classes}></div>
      {text}
    </span>
  );
};

export const LinkRenderer = (url: string, element: JSX.Element) => {
  const styles = getLinkRendererStyles();
  return (
    <Link className={styles.link} to={url}>
      {element}
    </Link>
  );
};

export const PartnerLinkRenderer = (partnerCwid: string, element: JSX.Element) => {
  return LinkRenderer(PartnerRoutes.DASHBOARD.replace(':cwid', partnerCwid), element);
};

export const PartnerCompanyNameRenderer = (companyName: string) => {
  const classes = getPartnerCompanyNameRendererStyles();
  return (
    <div className={classes.companyName}>
      <span className={classes.name}>{companyName}</span>
    </div>
  );
};

export const BoldRenderer = (value: string) => {
  const classes = getBoldRendererStyles();
  return (
    <div className={classes.bold}>
      <span className={classes.name}>{value}</span>
    </div>
  );
};

export const EmailRenderer = (email: string) => {
  const classes = getEmailRendererStyles();
  return (
    <a className={classes.link} style={{ display: 'block', width: 'max-content' }} href={'mailto:' + email}>
      {email}
    </a>
  );
};
export const BookingDirection = (isTwoWay: boolean) => {
  const theme = useTheme<Theme>();
  return (
    <ActionIcon
      IconElement={isTwoWay ? <TwoWayIcon /> : <OneWayIcon />}
      sx={{ width: '12px' }}
      color={theme.palette.text.primary}
    />
  );
};
export const BookingTourRenderer = (value: BookingTour, isFrom: boolean, regions: Region[]) => {
  const classes = getBookingTourRendererStyles();

  const separator = ', ';
  const types = {
    included: isFrom ? BookingTourType.FROM : BookingTourType.TO,
    excluded: isFrom ? BookingTourType.FROM_EXCLUDED : BookingTourType.TO_EXCLUDED,
  };

  const getRegionNameById: (id: number) => string = (id: number) =>
    regions.find((region) => region.id === id)?.name || '';

  const getPostalCodes = (type: BookingTourType) =>
    (value.bookingTourPostalCodes || [])
      .filter((postalCode) => postalCode.type === type)
      .map((item) => `${item.country}-${item.postalCode || ''}`);
  const getRegions = (type: BookingTourType) =>
    (value.bookingTourRegions || [])
      .filter((region) => region.type === type)
      .map((item) => getRegionNameById(item.regionId));

  const includeText = [...getPostalCodes(types.included), ...getRegions(types.included)].join(separator);
  const excludeText = [...getPostalCodes(types.excluded), ...getRegions(types.excluded)].join(separator);

  return (
    <div className={classes.general}>
      <span>{includeText}</span>
      {excludeText.trim().length > 0 && includeText.trim().length > 0 && <span>{separator}</span>}
      <span className={classes.excluded}>{excludeText}</span>
    </div>
  );
};

export const PartnerStatusRenderer = (isOnline: boolean) => {
  const displayText = isOnline ? 'ONLINE' : 'OFFLINE';
  const theme = useTheme<Theme>();
  return (
    <ChipItem
      sx={{
        justifyContent: 'center',
        border: `2px solid ${isOnline ? theme.palette.success.main : theme.palette.error.main}`,
        padding: '5px',
        textAlign: 'center',
      }}
    >
      {displayText}
    </ChipItem>
  );
};
