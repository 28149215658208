import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useTheme } from '@mui/styles';
import { Box, Theme } from '@mui/material';
import { allPaymentTypes } from '../../../../../../api/relocationRequest/RelocationRequestDetail';
import BookingSection from '../../BookingSection';
import Checkbox from '../../../../../../components/form/Checkbox';
import Label from '../../../../../../components/form/Label';
import { insertOrRemoveValue } from '../../../../../../utils/FormUtils';
import { mapToCamelCasedString } from '../../../../../../utils/StringUtils';

const PaymentTypes: FC = () => {
  const { t } = useTranslation('bookings');
  const theme = useTheme<Theme>();
  const formik = useFormikContext();
  const fieldProps = formik.getFieldProps('paymentTypes');
  return (
    <BookingSection title={t('booking.edit.sections.payment')} isModalSection={true}>
      <Box
        sx={{
          display: 'flex',
          columnGap: '50px',
          [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            justifyContent: 'start',
          },
        }}
      >
        {allPaymentTypes.map((payment) => (
          <Box key={payment} sx={{ display: 'flex' }}>
            <Checkbox
              id={fieldProps.name}
              onChange={() => formik?.setFieldValue(fieldProps.name, insertOrRemoveValue(fieldProps.value, payment))}
              checked={fieldProps.value.indexOf(payment) !== -1}
            />
            <Label label={t(`payment.${mapToCamelCasedString(payment)}`)} />
          </Box>
        ))}
      </Box>
    </BookingSection>
  );
};
export default PaymentTypes;
