import React, { ReactElement } from 'react';
import { useBookingModalContext } from './BookingModalContext';
import BookingDateCalendar from '../../../../../components/dateRangeCalendar/BookingDateCalendar';
import { Booking } from '../../../../../api/booking/BookingResource';

export type BookingSchedulerProps = {
  booking?: Booking;
};

const BookingScheduler = ({ booking }: BookingSchedulerProps): ReactElement => {
  const { modalActions } = useBookingModalContext();
  const onSelectDateRange = (newActivationDate?: Date, newDeactivationDate?: Date) => {
    modalActions.setBookingDates(newActivationDate, newDeactivationDate);
  };
  return (
    <BookingDateCalendar
      activationDate={booking?.activationDate}
      deactivationDate={booking?.deactivationDate}
      onSelect={onSelectDateRange}
      activeStatus={booking?.active}
    />
  );
};

export default BookingScheduler;
