import axios, { AxiosError } from 'axios';

export function extractApiErrorMessage(error: Error | AxiosError): string {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      return error.response.data as string;
    } else if (error.request) {
      // The request was made but no response was received
      return error.request;
    }
  }

  return error.message;
}
