import type { ThemeOptions } from '@mui/material/styles/createTheme';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    umzugEasy: {
      palette: {
        darkGray: string;
        lead: {
          filter: string;
          filterGratis: string;
          special: string;
          specialGratis: string;
          upgrade: string;
          upgradeGratis: string;
          premium: string; // deprecated - kept for historical reasons R8N-1582
          premiumGratis: string; // deprecated - kept for historical reasons R8N-1582
          shop: string;
          shopGratis: string;
        };
      };
    };
  }

  interface ThemeOptions {
    umzugEasy?: {
      palette?: {
        darkGray?: string;
        lead: {
          filter: string;
          filterGratis: string;
          special: string;
          specialGratis: string;
          upgrade: string;
          upgradeGratis: string;
          premium: string; // deprecated - kept for historical reasons R8N-1582
          premiumGratis: string; // deprecated - kept for historical reasons R8N-1582
          shop: string;
          shopGratis: string;
          topPartner: string;
          topPartnerGratis: string;
        };
      };
    };
  }
}

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      light: '#23404A',
      main: '#0989e3',
      dark: '#1B353C',
      contrastText: '#CDD8DB',
    },
    secondary: {
      main: '#4db6ac',
      light: '#9CC00B',
      dark: '#00867d',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#9CC00B',
    },
    error: {
      main: '#E32121',
    },
    warning: {
      main: '#86A800',
    },
    text: {
      primary: '#1B353C',
      secondary: '#577985',
      disabled: '#AEC1C7',
    },
    background: {
      default: '#F2F4F4',
      paper: '#E0E0E0',
    },
    action: {
      active: '#D2F07E',
    },
    common: {
      white: '#FFFFFF',
      black: '#000000',
    },
  },
  typography: {
    fontSize: 12,
    htmlFontSize: 10,
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
                  @font-face {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                    src: local('Open Sans') url('https://fonts.googleapis.com/css?family=Open+Sans') format('woff2');
                    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                  }
                `,
    },
  },
};

const customThemeOptions = {
  umzugEasy: {
    palette: {
      darkGray: '#0F2125',
      lead: {
        filter: '#0B9FC0',
        filterGratis: '#8fc3cf',
        special: '#C0980B',
        specialGratis: '#c2ae67',
        upgrade: '#9C0BC0',
        upgradeGratis: '#ba7dc9',
        premium: '#577985', // deprecated - kept for historical reasons R8N-1582
        premiumGratis: '#90cade', // deprecated - kept for historical reasons R8N-1582
        shop: '#86A800',
        shopGratis: '#DAF17B',
        topPartner: '#1FA800',
        topPartnerGratis: '#29DF00',
        shopPackage: '#f2ca26',
        shopPackagePartially: '#f5f200',
      },
    },
  },
};

export const useDefaultTheme = () => {
  return createTheme(themeOptions, customThemeOptions);
};
