import React, { FC, ReactElement } from 'react';
import { Box } from '@mui/material';
import AlgotestFilters from './components/AlgotestFilters';
import { useAlgotestResponseLoader } from '../../../components/table/schema/type/AlgotestResponseSchema';
import UmzugEasyTable from '../../../components/table/UmzugEasyTable';
import AlgotestResultsSummary from './components/AlgotestResultsSummary';

const Algotest: FC = (): ReactElement => {
  const { setRelocationRequest, tableProps, loading, resultsSummary } = useAlgotestResponseLoader();
  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: 'whitesmoke',
        display: 'block',
        color: 'black',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1rem',
      }}
    >
      <AlgotestFilters loading={loading} onRelocationRequestChanged={(request) => setRelocationRequest(request)} />
      {!!resultsSummary && <AlgotestResultsSummary {...resultsSummary} />}
      <UmzugEasyTable {...tableProps} />
    </Box>
  );
};

export default Algotest;
