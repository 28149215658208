import React from 'react';
import { styled, tableCellClasses, tableRowClasses } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { fractionFormatter } from '../../pages/reports/utils/formatters';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: '14px',
    lineHeight: '22px',
    padding: '13px',
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.text.secondary,
    fontSize: '16px',
    lineHeight: '22px',
    padding: '13px',
    textWrap: 'nowrap',
  },
  [`&.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: '16px',
    lineHeight: '22px',
    padding: '13px',
    textWrap: 'nowrap',
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}:nth-of-type(odd)`]: {
    backgroundColor: theme.palette.background.default,
  },
  [`&.${tableRowClasses.root}:nth-of-type(even)`]: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export const IntegerTableCell = (props: { value: number }) => (
  <StyledTableCell sx={{ textAlign: 'right' }}>{props.value}</StyledTableCell>
);
export const DecimalTableCell = (props: { value: number }) => (
  <StyledTableCell sx={{ textAlign: 'right' }}>{fractionFormatter(props.value)}</StyledTableCell>
);
