import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import Modal from '../../../../../components/form/Modal';
import Button from '../../../../../components/form/Button';

export type DeletionConfirmationModalProps = {
  open: boolean;
  titleKey: string;
  descriptionKey: string;
  confirm: () => void;
  cancel: () => void;
  namespace: string;
};

const DeletionConfirmationModal: FC<DeletionConfirmationModalProps> = ({
  open,
  titleKey,
  descriptionKey,
  confirm,
  cancel,
  namespace,
}) => {
  const { t } = useTranslation(namespace);
  return (
    <Modal isOpen={open} title={<Box sx={{ whiteSpace: 'nowrap' }}>{t(titleKey || '')}</Box>} width={'min-content'}>
      <Box sx={{ marginTop: '15px', display: 'flex', flexDirection: 'column', rowGap: '25px' }}>
        <Box sx={{ fontSize: '16px' }}>{t(descriptionKey || '')}</Box>
        <Box sx={{ display: 'flex', justifyContent: 'end', columnGap: '15px' }}>
          <Button
            label={t('booking.edit.deletionConfirmation.no')}
            appearance={'dark'}
            variant={'outlined'}
            onClick={() => cancel()}
          />
          <Button label={t('booking.edit.deletionConfirmation.yes')} appearance={'dark'} onClick={() => confirm()} />
        </Box>
      </Box>
    </Modal>
  );
};
export default DeletionConfirmationModal;
