import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Section, { SectionStyles } from '../../../../components/form/Section';
import MultipleRowSkeleton from '../../../../components/feedback/MultipleRowSkeleton';
import ApiClientsItem from '../../../../pages/admin/components/apiClients/ApiClientsItem';
import { useApiClientsContext } from './ApiClientsContext';

const sectionStyles: SectionStyles = {
  title: {
    fontSize: '18px',
  },
};

const ApiClientsList: FC = (): ReactElement => {
  const { apiClients, isLoading } = useApiClientsContext();
  const { t } = useTranslation('admin');
  const skeleton = <MultipleRowSkeleton rows={15} fontSize='30px' styles={{ width: '100%' }} />;

  return (
    <Section title={t('apiClients.list.header')} styles={sectionStyles}>
      {isLoading ? skeleton : apiClients.map((apiClient) => <ApiClientsItem apiClient={apiClient} />)}
    </Section>
  );
};

export default ApiClientsList;
