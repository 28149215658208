import React from 'react';
import {
  Booking,
  BookingTour,
  BookingTourPostalCode,
  BookingTourRegion,
} from '../../../../../api/booking/BookingResource';

const emptyFunction = () => {};
export type BookingModalEditTourProps = {
  tour: BookingTour;
  originalRegionOrPostalCodeToEdit: RegionAndPostalCode;
  editedRegionOrPostalCode: RegionAndPostalCode;
  element: HTMLDivElement | null;
};
export type RegionAndPostalCode = {
  region?: BookingTourRegion;
  postalCode?: BookingTourPostalCode;
};
export type BookingModalActionsProps = {
  addTour: () => void;
  setBookingDates: (newActivationDate?: Date, newDeactivationDate?: Date) => void;
  removeTour: (tourUuid: string | undefined) => void;
  editPostalCode: (tour: BookingTour, postalCode: BookingTourPostalCode, element: HTMLDivElement) => void;
  editRegion: (tour: BookingTour, region: BookingTourRegion, element: HTMLDivElement) => void;
  newPostalCode: (tour: BookingTour, element: HTMLDivElement | null, isFrom: boolean) => void;
  changeTourDirection: (tour: BookingTour) => void;
  save: () => void;
  clearEditedTour: () => void;
  updateEditedTour: (newValue: RegionAndPostalCode) => void;
  setTours: (converter: (tours: BookingTour[]) => BookingTour[]) => void;
};
export type BookingModalContextProps = {
  booking: Booking | undefined;
  editedTour?: BookingModalEditTourProps | undefined;
  modalActions: BookingModalActionsProps;
  isValid: boolean;
};
export const BookingModalContext = React.createContext<BookingModalContextProps>({
  editedTour: undefined,
  booking: undefined,
  isValid: false,
  modalActions: {
    addTour: emptyFunction,
    removeTour: emptyFunction,
    editPostalCode: emptyFunction,
    editRegion: emptyFunction,
    newPostalCode: emptyFunction,
    changeTourDirection: emptyFunction,
    save: emptyFunction,
    clearEditedTour: emptyFunction,
    updateEditedTour: emptyFunction,
    setTours: emptyFunction,
    setBookingDates: emptyFunction,
  },
});
