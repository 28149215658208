import { FC, ReactElement } from 'react';
import { useDocumentsContext } from './DocumentsContext';
import DeletionConfirmationModal from '../../../../components/form/DeletionConfirmationModal';

const DocumentsDeletionConfirmationModal: FC = (): ReactElement => {
  const { deletionConfirmationModal, isDeleting } = useDocumentsContext();

  return <DeletionConfirmationModal deletionConfirmationModalProps={deletionConfirmationModal} loading={isDeleting} />;
};

export default DocumentsDeletionConfirmationModal;
