import React, { FC, ReactNode } from 'react';
import { Box, CssBaseline } from '@mui/material';
import Navbar from '../navbar/Navbar';

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          minHeight: '100vh',
          maxHeight: '100vh',
          maxWidth: '100vw',
          flexGrow: 1,
        }}
      >
        <Navbar />
        {children}
        {/*todo: we don't have footer in figma. Do we need this component ? [MA]*/}
        {/*<Footer />*/}
      </Box>
    </>
  );
};

export default Layout;
