import React, { ReactElement } from 'react';
import ActionImg from '../../../../components/shared/ActionImg';

type BookingActionsProps = {
  onRemove: () => void;
  onEdit: () => void;
};

const BookingActions: React.FC<BookingActionsProps> = ({ onRemove, onEdit }: BookingActionsProps): ReactElement => {
  return (
    <>
      <ActionImg
        icon={'/icons/trash.svg'}
        iconStyle={{ marginLeft: '25px' }}
        onClick={(event) => {
          event.stopPropagation();
          onRemove();
        }}
      />
      <ActionImg
        icon={'/icons/pencil.svg'}
        iconStyle={{ marginLeft: '25px' }}
        onClick={(event) => {
          event.stopPropagation();
          onEdit();
        }}
      />
    </>
  );
};
export default BookingActions;
