import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { PartnerStatisticsRequestFilter } from '../../../api/statistics/PartnerStatisticsResource';
import FormLabel from '../../../components/form/FormLabel';
import ToggleSwitch from '../../../components/form/ToggleSwitch';
import useUserProfileInfo from '../../../hooks/useUserProfileInfo';
import { usePartnersReportsContext } from './PartnersReportContext';

const PartnersReportFilter: FC = (): ReactElement => {
  const [userProfile] = useUserProfileInfo();
  const { partnersStatisticsFilter, showPreviousMonths, loading, actions } = usePartnersReportsContext();
  const { t } = useTranslation('partnersReport');

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        padding: '20px',
      }}
    >
      <span
        style={{
          height: '24px',
          display: 'flex',
          alignItems: 'center',
          marginRight: '20px',
        }}
      >
        <FormLabel
          title={t('list.filter.labels.onlyUserPartners')}
          fontSize='12px'
          fontWeight={400}
          customStyles={{ textTransform: 'none' }}
        />
      </span>
      <ToggleSwitch
        disabled={loading}
        id={'onlyUserPartners'}
        checked={partnersStatisticsFilter?.ownerDetails !== undefined}
        onChange={(onlyUserPartners) =>
          actions.onFilterChange({
            ...partnersStatisticsFilter,
            ownerDetails: onlyUserPartners.target.checked ? userProfile?.name : undefined,
          })
        }
      />
      <span
        style={{
          height: '24px',
          display: 'flex',
          alignItems: 'center',
          marginRight: '20px',
        }}
      >
        <FormLabel
          title={t('list.filter.labels.hasActiveContract')}
          fontSize='12px'
          fontWeight={400}
          customStyles={{ textTransform: 'none' }}
        />
      </span>
      <ToggleSwitch
        disabled={loading}
        id={'onlyActiveContract'}
        checked={partnersStatisticsFilter.hasActiveContract === true}
        onChange={(onlyActiveContract) =>
          actions.onFilterChange({
            ...partnersStatisticsFilter,
            hasActiveContract: onlyActiveContract.target.checked ? true : undefined,
          } as PartnerStatisticsRequestFilter)
        }
      />
      <span
        style={{
          height: '24px',
          display: 'flex',
          alignItems: 'center',
          marginRight: '20px',
        }}
      >
        <FormLabel
          title={t('list.filter.labels.showPreviousMonths')}
          fontSize='12px'
          fontWeight={400}
          customStyles={{ textTransform: 'none' }}
        />
      </span>
      <ToggleSwitch
        disabled={loading}
        id={'showPreviousMonths'}
        checked={showPreviousMonths}
        onChange={(showPreviousMonths) => actions.onShowPreviousMonths(showPreviousMonths.target.checked)}
      />
    </Box>
  );
};

export default PartnersReportFilter;
