import { startOfDay } from 'date-fns';
import { ContractResource } from '../api/contract/ContractResourcePage';
import { getContractsByPartnerCwid } from '../api/contract/ContractsApi';
import { isAfterOrEqual, isBeforeOrEqual } from '../utils/DateUtil';

export function isContractValid({ startDate, endDate }: ContractResource): boolean {
  const now = startOfDay(new Date());
  return (startDate == null || isBeforeOrEqual(startDate, now)) && (endDate == null || isAfterOrEqual(endDate, now));
}
export function getValidContractsByPartnerCwid(partnerCwid: string): Promise<ContractResource[]> {
  return getContractsByPartnerCwid(partnerCwid).then((contracts) =>
    contracts.filter((contract) => isContractValid(contract)),
  );
}
