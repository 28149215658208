import { differenceInDays } from 'date-fns';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';
import { UmzugEasyTableProps } from '../../../components/table/UmzugEasyTable';
import { getUmzugEasyTableProps } from '../../../components/table/schema/SchemaUtil';
import { TableSchema } from '../../../components/table/schema/SchemaModel';
import {
  DefaultRenderer,
  PartnerCompanyNameRenderer,
  PartnerLinkRenderer,
  PartnerStatusRenderer,
} from '../../../components/table/util/UmzugEasyCellRenderers';
import { calculateDaysRemaining, formatToLocaleTextWithDate } from '../../../utils/DateUtil';
import { mapToCamelCasedString } from '../../../utils/StringUtils';
import { formatToGermanCurrency } from '../../../utils/NumbersUtil';
import { PartnerStatisticsWithIndex } from './PartnersReportContext';

export const getPartnersStatisticsTableSchema = (
  showPreviousMonths: boolean,
  onSortByChange: (key: string, direction: 'asc' | 'desc' | undefined) => void,
  t: (key: string) => string,
  partnersStatistics?: PartnerStatisticsWithIndex[],
  partnersStatisticsDate?: Date,
): UmzugEasyTableProps<PartnerStatisticsWithIndex> => {
  const sxHeaders = {
    height: '50px',
    th: { borderCollapse: 'collapse', borderColor: '#333333', borderStyle: 'solid', borderWidth: '2px' },
  };
  return {
    table: getUmzugEasyTableProps<PartnerStatisticsWithIndex>({
      groupSchema: showPreviousMonths ? createEnhancedGroupSchema(t, partnersStatisticsDate) : groupSchema,
      schema: showPreviousMonths ? createEnhancedTableSchema(onSortByChange, t) : createTableSchema(onSortByChange, t),
      rows: partnersStatistics,
      translationPrefix: 'list.table.header',
      translationFile: 'partnersReport',
      sxGroupHeaders: { ...sxHeaders, th: { ...sxHeaders.th, textAlign: 'center' } },
      sxHeaders: sxHeaders,
    }),
    noDataMessageKey: 'partnersReport:list.table.noData',
    customStyles: {
      tableBody: {
        tr: { height: '58px' },
        td: { borderCollapse: 'collapse', borderColor: '#333333', borderStyle: 'solid', borderWidth: '2px' },
      },
    },
    isHeaderSticky: true,
  };
};

const getOfflineSinceValue = (t: (key: string) => string, offlineDate?: Date) => {
  if (offlineDate) {
    return `${formatToLocaleTextWithDate(offlineDate.toString())} (${differenceInDays(new Date(), new Date(offlineDate))} ${t('list.table.values.days')})`;
  }
  return '';
};

const getPreviousMonthsIndexAndYear = (date?: Date): number[][] => {
  const baseDate = date || new Date();
  let currentMonth = baseDate.getUTCMonth();
  let currentYear = baseDate.getUTCFullYear();
  const previousMonthsArray: number[][] = [];
  for (let i = 0; i < 12; i++) {
    if (currentMonth < 0) {
      currentMonth = 11;
      currentYear--;
    }
    previousMonthsArray.push([currentMonth, currentYear]);
    currentMonth--;
  }
  return previousMonthsArray;
};

const groupSchema: TableSchema<PartnerStatisticsWithIndex>[] = [
  { key: 'empty', order: 1, colSpan: 8 },
  { key: 'currentMonth', order: 2, colSpan: 3 },
  { key: 'currentYear', order: 100, colSpan: 3 },
  { key: 'lastYear', order: 101, colSpan: 3 },
];

const createEnhancedGroupSchema = (
  t: (key: string) => string,
  partnersStatisticsDate?: Date,
): TableSchema<PartnerStatisticsWithIndex>[] => {
  const previousMonthsArray = getPreviousMonthsIndexAndYear(partnersStatisticsDate);
  const monthCollspan = 2;
  return [
    ...groupSchema,
    {
      key: 'backwardMonth1',
      translationKey: `months.${previousMonthsArray[1][0]}`,
      translationKeyParam: previousMonthsArray[1][1].toString(),
      order: 20,
      colSpan: monthCollspan,
    },
    {
      key: 'backwardMonth2',
      translationKey: `months.${previousMonthsArray[2][0]}`,
      translationKeyParam: previousMonthsArray[2][1].toString(),
      order: 21,
      colSpan: monthCollspan,
    },
    {
      key: 'backwardMonth3',
      translationKey: `months.${previousMonthsArray[3][0]}`,
      translationKeyParam: previousMonthsArray[3][1].toString(),
      order: 22,
      colSpan: monthCollspan,
    },
    {
      key: 'backwardMonth4',
      translationKey: `months.${previousMonthsArray[4][0]}`,
      translationKeyParam: previousMonthsArray[4][1].toString(),
      order: 23,
      colSpan: monthCollspan,
    },
    {
      key: 'backwardMonth5',
      translationKey: `months.${previousMonthsArray[5][0]}`,
      translationKeyParam: previousMonthsArray[5][1].toString(),
      order: 24,
      colSpan: monthCollspan,
    },
    {
      key: 'backwardMonth6',
      translationKey: `months.${previousMonthsArray[6][0]}`,
      translationKeyParam: previousMonthsArray[6][1].toString(),
      order: 25,
      colSpan: monthCollspan,
    },
    {
      key: 'backwardMonth7',
      translationKey: `months.${previousMonthsArray[7][0]}`,
      translationKeyParam: previousMonthsArray[7][1].toString(),
      order: 26,
      colSpan: monthCollspan,
    },
    {
      key: 'backwardMonth8',
      translationKey: `months.${previousMonthsArray[8][0]}`,
      translationKeyParam: previousMonthsArray[8][1].toString(),
      order: 27,
      colSpan: monthCollspan,
    },
    {
      key: 'backwardMonth9',
      translationKey: `months.${previousMonthsArray[9][0]}`,
      translationKeyParam: previousMonthsArray[9][1].toString(),
      order: 28,
      colSpan: monthCollspan,
    },
    {
      key: 'backwardMonth10',
      translationKey: `months.${previousMonthsArray[10][0]}`,
      translationKeyParam: previousMonthsArray[10][1].toString(),
      order: 29,
      colSpan: monthCollspan,
    },
    {
      key: 'backwardMonth11',
      translationKey: `months.${previousMonthsArray[11][0]}`,
      translationKeyParam: previousMonthsArray[11][1].toString(),
      order: 30,
      colSpan: monthCollspan,
    },
  ];
};

const stickyColumnStyle: SxProps<Theme> = {
  backgroundColor: '#E0E0E0',
  position: 'sticky !important',
  zIndex: 2,
};

const createTableSchema = (
  onSortByChange: (key: string, direction: 'asc' | 'desc' | undefined) => void,
  t: (key: string) => string,
): TableSchema<PartnerStatisticsWithIndex>[] => {
  return [
    {
      key: 'index',
      translationKey: 'index',
      order: 1,
      cellRenderer: (row) => DefaultRenderer(row.index || ''),
      sxColumn: { ...stickyColumnStyle, width: '50px', left: '0px' },
    },
    {
      key: 'partnerName',
      translationKey: 'partnerName',
      order: 2,
      cellRenderer: (row) =>
        PartnerLinkRenderer(row.partner.partnerCwid, PartnerCompanyNameRenderer(row.partner.partnerName)),
      onSortChange: (key, direction) => onSortByChange(key, direction),
      sxColumn: { ...stickyColumnStyle, left: '50px' },
    },

    {
      key: 'scoutId',
      translationKey: 'customerId',
      order: 3,
      cellRenderer: (row) =>
        PartnerLinkRenderer(row.partner.partnerCwid, DefaultRenderer(row.partner.scoutId?.toString() || '')),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'ownerDetails',
      translationKey: 'accountOwner',
      order: 4,
      cellRenderer: (row) =>
        PartnerLinkRenderer(row.partner.partnerCwid, DefaultRenderer(row.partner.ownerDetails || '')),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'status.isOnline',
      translationKey: 'onlineOffline',
      order: 5,
      cellRenderer: (row) =>
        PartnerLinkRenderer(row.partner.partnerCwid, PartnerStatusRenderer(row.partner.status.isOnline)),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'status.offlineDate',
      translationKey: 'offlineSince',
      order: 6,
      cellRenderer: (row) =>
        PartnerLinkRenderer(
          row.partner.partnerCwid,
          DefaultRenderer(getOfflineSinceValue(t, row.partner.status.offlineDate)),
        ),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'status.offlineReason',
      translationKey: 'offlineReason',
      order: 7,
      cellRenderer: (row) =>
        PartnerLinkRenderer(
          row.partner.partnerCwid,
          DefaultRenderer(
            row.partner.status.offlineReason
              ? t(`list.table.values.offlineReason.${mapToCamelCasedString(row.partner.status.offlineReason)}`)
              : '',
          ),
        ),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'status.deactivationDate',
      translationKey: 'deactivationIn',
      order: 8,
      cellRenderer: (row) =>
        PartnerLinkRenderer(
          row.partner.partnerCwid,
          DefaultRenderer(
            row.partner.status.deactivationDate
              ? `${calculateDaysRemaining(row.partner.status.deactivationDate)} ${t('list.table.values.days')}`
              : '',
          ),
        ),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'revenue.currentMonth',
      translationKey: 'revenue',
      order: 9,
      cellRenderer: (row) =>
        PartnerLinkRenderer(
          row.partner.partnerCwid,
          DefaultRenderer(formatToGermanCurrency(row.partner.revenue.currentMonth, false)),
        ),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'leadsCount.currentMonth',
      translationKey: 'leads',
      order: 10,
      cellRenderer: (row) =>
        PartnerLinkRenderer(row.partner.partnerCwid, DefaultRenderer(row.partner.leadsCount.currentMonth)),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'avgPrice.currentMonth',
      translationKey: 'average',
      order: 11,
      cellRenderer: (row) =>
        PartnerLinkRenderer(
          row.partner.partnerCwid,
          DefaultRenderer(
            row.partner.avgPrice?.currentMonth ? formatToGermanCurrency(row.partner.avgPrice.currentMonth) : '',
          ),
        ),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },

    {
      key: 'revenue.currentYear',
      translationKey: 'revenue',
      order: 110,
      cellRenderer: (row) =>
        PartnerLinkRenderer(
          row.partner.partnerCwid,
          DefaultRenderer(formatToGermanCurrency(row.partner.revenue.currentYear, false)),
        ),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'leadsCount.currentYear',
      translationKey: 'leads',
      order: 111,
      cellRenderer: (row) =>
        PartnerLinkRenderer(row.partner.partnerCwid, DefaultRenderer(row.partner.leadsCount.currentYear)),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'avgPrice.currentYear',
      translationKey: 'average',
      order: 112,
      cellRenderer: (row) =>
        PartnerLinkRenderer(
          row.partner.partnerCwid,
          DefaultRenderer(
            row.partner.avgPrice?.currentYear ? formatToGermanCurrency(row.partner.avgPrice.currentYear) : '',
          ),
        ),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'revenue.lastYear',
      translationKey: 'revenue',
      order: 113,
      cellRenderer: (row) =>
        PartnerLinkRenderer(
          row.partner.partnerCwid,
          DefaultRenderer(formatToGermanCurrency(row.partner.revenue.lastYear, false)),
        ),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'leadsCount.lastYear',
      translationKey: 'leads',
      order: 114,
      cellRenderer: (row) =>
        PartnerLinkRenderer(row.partner.partnerCwid, DefaultRenderer(row.partner.leadsCount.lastYear)),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'avgPrice.lastYear',
      translationKey: 'average',
      order: 115,
      cellRenderer: (row) =>
        PartnerLinkRenderer(
          row.partner.partnerCwid,
          DefaultRenderer(row.partner.avgPrice?.lastYear ? formatToGermanCurrency(row.partner.avgPrice.lastYear) : ''),
        ),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
  ];
};

const createEnhancedTableSchema = (
  onSortByChange: (key: string, direction: 'asc' | 'desc' | undefined) => void,
  t: (key: string) => string,
): TableSchema<PartnerStatisticsWithIndex>[] => {
  return [
    ...createTableSchema(onSortByChange, t),
    {
      key: 'revenue.backwardMonth1',
      translationKey: 'revenue',
      order: 20,
      cellRenderer: (row) =>
        PartnerLinkRenderer(
          row.partner.partnerCwid,
          DefaultRenderer(formatToGermanCurrency(row.partner.revenue.backwardMonth1, false)),
        ),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'leadsCount.backwardMonth1',
      translationKey: 'leads',
      order: 21,
      cellRenderer: (row) =>
        PartnerLinkRenderer(row.partner.partnerCwid, DefaultRenderer(row.partner.leadsCount.backwardMonth1)),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'revenue.backwardMonths2',
      translationKey: 'revenue',
      order: 22,
      cellRenderer: (row) =>
        PartnerLinkRenderer(
          row.partner.partnerCwid,
          DefaultRenderer(formatToGermanCurrency(row.partner.revenue.backwardMonths2, false)),
        ),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'leadsCount.backwardMonths2',
      translationKey: 'leads',
      order: 23,
      cellRenderer: (row) =>
        PartnerLinkRenderer(row.partner.partnerCwid, DefaultRenderer(row.partner.leadsCount.backwardMonths2)),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'revenue.backwardMonths3',
      translationKey: 'revenue',
      order: 24,
      cellRenderer: (row) =>
        PartnerLinkRenderer(
          row.partner.partnerCwid,
          DefaultRenderer(formatToGermanCurrency(row.partner.revenue.backwardMonths3, false)),
        ),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'leadsCount.backwardMonths3',
      translationKey: 'leads',
      order: 25,
      cellRenderer: (row) =>
        PartnerLinkRenderer(row.partner.partnerCwid, DefaultRenderer(row.partner.leadsCount.backwardMonths3)),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'revenue.backwardMonths4',
      translationKey: 'revenue',
      order: 26,
      cellRenderer: (row) =>
        PartnerLinkRenderer(
          row.partner.partnerCwid,
          DefaultRenderer(formatToGermanCurrency(row.partner.revenue.backwardMonths4, false)),
        ),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'leadsCount.backwardMonths4',
      translationKey: 'leads',
      order: 27,
      cellRenderer: (row) =>
        PartnerLinkRenderer(row.partner.partnerCwid, DefaultRenderer(row.partner.leadsCount.backwardMonths4)),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'revenue.backwardMonths5',
      translationKey: 'revenue',
      order: 28,
      cellRenderer: (row) =>
        PartnerLinkRenderer(
          row.partner.partnerCwid,
          DefaultRenderer(formatToGermanCurrency(row.partner.revenue.backwardMonths5, false)),
        ),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'leadsCount.backwardMonths5',
      translationKey: 'leads',
      order: 29,
      cellRenderer: (row) =>
        PartnerLinkRenderer(row.partner.partnerCwid, DefaultRenderer(row.partner.leadsCount.backwardMonths5)),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'revenue.backwardMonths6',
      translationKey: 'revenue',
      order: 30,
      cellRenderer: (row) =>
        PartnerLinkRenderer(
          row.partner.partnerCwid,
          DefaultRenderer(formatToGermanCurrency(row.partner.revenue.backwardMonths6, false)),
        ),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'leadsCount.backwardMonths6',
      translationKey: 'leads',
      order: 31,
      cellRenderer: (row) =>
        PartnerLinkRenderer(row.partner.partnerCwid, DefaultRenderer(row.partner.leadsCount.backwardMonths6)),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'revenue.backwardMonths7',
      translationKey: 'revenue',
      order: 32,
      cellRenderer: (row) =>
        PartnerLinkRenderer(
          row.partner.partnerCwid,
          DefaultRenderer(formatToGermanCurrency(row.partner.revenue.backwardMonths7, false)),
        ),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'leadsCount.backwardMonths7',
      translationKey: 'leads',
      order: 33,
      cellRenderer: (row) =>
        PartnerLinkRenderer(row.partner.partnerCwid, DefaultRenderer(row.partner.leadsCount.backwardMonths7)),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'revenue.backwardMonths8',
      translationKey: 'revenue',
      order: 34,
      cellRenderer: (row) =>
        PartnerLinkRenderer(
          row.partner.partnerCwid,
          DefaultRenderer(formatToGermanCurrency(row.partner.revenue.backwardMonths8, false)),
        ),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'leadsCount.backwardMonths8',
      translationKey: 'leads',
      order: 35,
      cellRenderer: (row) =>
        PartnerLinkRenderer(row.partner.partnerCwid, DefaultRenderer(row.partner.leadsCount.backwardMonths8)),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'revenue.backwardMonths9',
      translationKey: 'revenue',
      order: 36,
      cellRenderer: (row) =>
        PartnerLinkRenderer(
          row.partner.partnerCwid,
          DefaultRenderer(formatToGermanCurrency(row.partner.revenue.backwardMonths9, false)),
        ),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'leadsCount.backwardMonths9',
      translationKey: 'leads',
      order: 37,
      cellRenderer: (row) =>
        PartnerLinkRenderer(row.partner.partnerCwid, DefaultRenderer(row.partner.leadsCount.backwardMonths9)),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'revenue.backwardMonths10',
      translationKey: 'revenue',
      order: 38,
      cellRenderer: (row) =>
        PartnerLinkRenderer(
          row.partner.partnerCwid,
          DefaultRenderer(formatToGermanCurrency(row.partner.revenue.backwardMonths10, false)),
        ),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'leadsCount.backwardMonths10',
      translationKey: 'leads',
      order: 39,
      cellRenderer: (row) =>
        PartnerLinkRenderer(row.partner.partnerCwid, DefaultRenderer(row.partner.leadsCount.backwardMonths10)),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'revenue.backwardMonths11',
      translationKey: 'revenue',
      order: 40,
      cellRenderer: (row) =>
        PartnerLinkRenderer(
          row.partner.partnerCwid,
          DefaultRenderer(formatToGermanCurrency(row.partner.revenue.backwardMonths11, false)),
        ),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
    {
      key: 'leadsCount.backwardMonths11',
      translationKey: 'leads',
      order: 41,
      cellRenderer: (row) =>
        PartnerLinkRenderer(row.partner.partnerCwid, DefaultRenderer(row.partner.leadsCount.backwardMonths11)),
      onSortChange: (key, direction) => onSortByChange(key, direction),
    },
  ];
};
