import { FC, ReactElement, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Lead } from '../../../api/lead/LeadResources';
import { RelocationRequestDetail } from '../../../api/relocationRequest/RelocationRequestDetail';
import UmzugEasyTab from '../../../components/tabs/EmzugEasyTab';
import UmzugEasyTabs from '../../../components/tabs/EmzugEasyTabs';
import { DeletionConfirmationModalProps } from '../../../components/form/DeletionConfirmationModal';
import RelocationRequestDetailDetailsTab from './relocationRequestDetailsTab/RelocationRequestDetailDetailsTab';
import TabPanel from './TabPanel';

export type RelocationRequestDetailTabsProps = {
  relocationRequestDetail: RelocationRequestDetail | undefined;
  loading: boolean;
  onAnonymizeRelocationRequest: () => void;
  anonymizationConfirmationModal: DeletionConfirmationModalProps | undefined;
  isAnonymizingRelocationRequest: boolean;
  leads?: Lead[];
};

const relocationRequestDetailTabsStyles = makeStyles((theme: Theme) => ({
  relocationRequestTabButtons: {
    borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
    textTransform: 'unset',
  },
}));

const requestDetailsTabNameOf = (name: string): string => `request-details-${name}-tab`;

const RelocationRequestDetailTabs: FC<RelocationRequestDetailTabsProps> = (props): ReactElement => {
  const classes = relocationRequestDetailTabsStyles();
  const detailsTabId = requestDetailsTabNameOf('details');
  const [currentTabValue, setCurrentTabValue] = useState(0);
  const { t } = useTranslation('relocationRequests');
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setCurrentTabValue(newValue);
  };

  return (
    <>
      <UmzugEasyTabs className={classes.relocationRequestTabButtons} value={currentTabValue} onChange={handleChange}>
        <UmzugEasyTab aria-controls={detailsTabId} label={t('requestDetails.tabs.details')} value={0} />
      </UmzugEasyTabs>
      <TabPanel index={0} value={currentTabValue}>
        <RelocationRequestDetailDetailsTab {...props} />
      </TabPanel>
    </>
  );
};

export default RelocationRequestDetailTabs;
