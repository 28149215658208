import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import BookingSection from '../BookingSection';
import { useBookingsSettingsContext } from './BookingsSettingsContext';
import DateRanges from '../../DateRanges/DateRanges';
import Alert from '../../../../../components/feedback/Alert';

const RelocationExclusionDays: FC = () => {
  const translationNameSpace = 'bookings';
  const { t } = useTranslation(translationNameSpace);
  const { bookingsSettings, alert } = useBookingsSettingsContext();

  return (
    <>
      <BookingSection title={t('booking.bookingsSettings.exclusionDates.title')} isModalSection={false}>
        <DateRanges dateRanges={bookingsSettings?.relocationExclusionDateRanges || []} />
      </BookingSection>
      <Alert text={''} {...alert} />
    </>
  );
};

export default RelocationExclusionDays;
