import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Box, Skeleton } from '@mui/material';
import BookingSection from '../BookingSection';
import Checkbox from '../../../../../components/form/Checkbox';
import Label from '../../../../../components/form/Label';
import { insertOrRemoveValue } from '../../../../../utils/FormUtils';

const LeadDaysSettings: FC = () => {
  const { t } = useTranslation('bookings');
  const formik = useFormikContext();
  const fieldProps = formik.getFieldProps('leadDays');
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const checkboxOf = (dayOfWeek: string) => (
    <Checkbox
      id={fieldProps.name}
      onChange={() => {
        formik?.setFieldValue(fieldProps.name, insertOrRemoveValue(fieldProps.value, dayOfWeek.toUpperCase()));
        formik?.submitForm();
      }}
      checked={fieldProps.value !== undefined && fieldProps.value?.indexOf(dayOfWeek.toUpperCase()) !== -1}
    />
  );

  return (
    <BookingSection title={t('booking.bookingsSettings.leadDays.title')} isModalSection={false}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          gap: '50px',
          paddingTop: '16px',
        }}
      >
        {daysOfWeek.map((dayOfWeek) => (
          <Box key={dayOfWeek} sx={{ display: 'flex' }}>
            {fieldProps.value === undefined ? <Skeleton>{checkboxOf(dayOfWeek)}</Skeleton> : checkboxOf(dayOfWeek)}
            <Label label={t(`booking.bookingsSettings.leadDays.inputs.${dayOfWeek.toLowerCase()}`)} />
          </Box>
        ))}
      </Box>
    </BookingSection>
  );
};

export default LeadDaysSettings;
