import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BookingsSettings } from '../../../../../api/bookingsSettings/BookingsSettingsResource';
import {
  addRelocationExclusionDateRange,
  deleteRelocationExclusionDateRange,
  getBookingsSettings,
  updateBookingSettings,
  updateRelocationExclusionDateRange,
} from '../../../../../api/bookingsSettings/BookingsSettingsApi';
import { PriceGroupType } from '../../../../../api/booking/BookingResource';
import { BookingsSettingsForm } from './BookingsSettings';
import {
  BookingsSettingsContext,
  BookingsSettingsContextProps,
  ExclusionDateRangeActionsProps,
} from './BookingsSettingsContext.model';
import { AlertProps } from '../../../../../components/feedback/Alert';
import { closeModal } from '../../../../../components/form/Modal';
import { DeletionConfirmationModalProps } from '../modal/DeletionConfirmationModal';

export function useInitBookingsSettingsContext(partnerCwid: string | undefined): BookingsSettingsContextProps {
  const [isLoading, setLoading] = useState(false);
  const [isSaving] = useState(false);
  const [bookingsSettings, setBookingsSettings] = useState<BookingsSettings | undefined>(undefined);
  const [deletionConfirmationModal, setDeletionConfirmationModal] = useState<
    DeletionConfirmationModalProps | undefined
  >(undefined);
  const [alert, setAlert] = useState<AlertProps | undefined>(undefined);

  const namespace = 'bookings';
  const { t } = useTranslation(namespace);

  useEffect(() => {
    if (partnerCwid) {
      setLoading(true);
      getBookingsSettings(partnerCwid).then((result) => setBookingsSettings(result));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerCwid]);

  const hideDeletionConfirmationModal = () => closeModal(setDeletionConfirmationModal);

  const showDeletionConfirmationModal = (action: (partnerCwid: string) => void) => {
    setDeletionConfirmationModal({
      open: true,
      titleKey: 'booking.bookingsSettings.exclusionDates.deletionConfirmation.title',
      descriptionKey: 'booking.bookingsSettings.exclusionDates.deletionConfirmation.description',
      cancel: () => hideDeletionConfirmationModal(),
      confirm: () => partnerCwid != null && action(partnerCwid),
      namespace,
    });
  };

  const showAlert = (translationKey: string) => {
    setAlert({
      color: 'error',
      open: true,
      text: t(`booking.bookingsSettings.alerts.${translationKey}`),
      handleClose: () => setAlert(undefined),
    });
  };

  const exclusionDateRangeActions: ExclusionDateRangeActionsProps = {
    onDeleteRelocationExclusionDateRange: (dateRange) =>
      showDeletionConfirmationModal((partnerCwid) => {
        if (partnerCwid) {
          deleteRelocationExclusionDateRange(partnerCwid, dateRange)
            .then((allDates) => {
              if (bookingsSettings) {
                setBookingsSettings(
                  (prevState) =>
                    prevState && {
                      ...prevState,
                      relocationExclusionDateRanges: allDates.relocationExclusionDateRanges,
                    },
                );
              }
            })
            .catch(() => showAlert('deleteRelocationExclusionDateRangeError'))
            .finally(() => hideDeletionConfirmationModal());
        }
      }),
    onAddRelocationExclusionDateRange: (start: Date, end: Date) => {
      if (partnerCwid) {
        addRelocationExclusionDateRange(partnerCwid, start, end)
          .then((allDates) => {
            if (bookingsSettings) {
              setBookingsSettings(
                (prevState) =>
                  prevState && { ...prevState, relocationExclusionDateRanges: allDates.relocationExclusionDateRanges },
              );
            }
          })
          .catch(() => showAlert('addRelocationExclusionDateRangeError'));
      }
    },
    onUpdateRelocationExclusionDateRange: (dateRange, newDateRange) => {
      if (partnerCwid) {
        updateRelocationExclusionDateRange(partnerCwid, dateRange, newDateRange)
          .then((allDates) => {
            if (bookingsSettings) {
              setBookingsSettings(
                (prevState) =>
                  prevState && { ...prevState, relocationExclusionDateRanges: allDates.relocationExclusionDateRanges },
              );
            }
          })
          .catch(() => showAlert('updateRelocationExclusionDateRangeError'));
      }
    },
  };

  const saveChanges = (form: BookingsSettingsForm) => {
    if (bookingsSettings != null && partnerCwid != null) {
      const updateBookingSettingsRequest: BookingsSettings = {
        ...bookingsSettings,
        cancellationRules: form.cancellationRules || undefined,
        freeLeadsLimit: form.freeLeadsLimit || bookingsSettings.freeLeadsLimit,
        leadThrottling: form.leadThrottling || bookingsSettings.leadThrottling,
        shopPricesByApartmentArea: {
          priceGroup1: form.priceGroup1 || bookingsSettings.shopPricesByApartmentArea.priceGroup1,
          priceGroup2: form.priceGroup2 || bookingsSettings.shopPricesByApartmentArea.priceGroup2,
          priceGroup3:
            bookingsSettings.shopPricesByApartmentArea.priceGroupType === PriceGroupType.STANDARD
              ? form.priceGroup3 || bookingsSettings.shopPricesByApartmentArea.priceGroup3
              : null,
          priceGroupOther: form.priceGroupOther || bookingsSettings.shopPricesByApartmentArea.priceGroupOther,
          priceGroupType: PriceGroupType.STANDARD, // priceGroupType is ignored by BE during update (a separate request model should be created)
        },
        relocationDelayRange: {
          min: form.minRelocationDelay || undefined,
          max: form.maxRelocationDelay || undefined,
        },
        leadDays: form.leadDays,
      };
      updateBookingSettings(partnerCwid, updateBookingSettingsRequest)
        .then((result) => setBookingsSettings(result))
        .catch(() => showAlert('unableToSaveChanges'));
    }
  };

  return {
    bookingsSettings,
    isLoading,
    partnerCwid,
    deletionConfirmationModal,
    exclusionDateRangeActions,
    alert,
    isSaving,
    saveChanges,
  };
}

export function useBookingsSettingsContext(): BookingsSettingsContextProps {
  return useContext(BookingsSettingsContext);
}
