import { FC } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  dummyPlug: {
    width: '100%',
    border: '1px solid #d0d0d0',
    backgroundColor: '#e0e0e0',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '35px',
    marginTop: '20px',
  },
  text: {
    color: '#bbbbbb',
  },
}));

export type DummyPlugProps = {
  text: string;
  height?: string;
  horizontalMargin?: string;
};

const DummyPlug: FC<DummyPlugProps> = ({ text, height, horizontalMargin }: DummyPlugProps) => {
  const classes = styles();

  return (
    <Box
      sx={{
        height,
        mx: horizontalMargin,
      }}
      className={classes.dummyPlug}
    >
      <Box className={classes.text}>{text}</Box>
    </Box>
  );
};

export default DummyPlug;
