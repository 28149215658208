import { FC, ReactElement } from 'react';
import Box from '@mui/material/Box';

export type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

const TabPanel: FC<TabPanelProps> = (props: TabPanelProps): ReactElement => {
  const { children, value, index } = props;
  return value === index ? <Box>{children}</Box> : <></>;
};

export default TabPanel;
