import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Box } from '@mui/material';
import { BookingTour } from '../../../../../../api/booking/BookingResource';
import Label from '../../../../../../components/form/Label';
import { BookingTestForm } from '../FormFields';
import { useInitBookingTestContext } from './BookingTestContext';
import { BookingTestContext } from './BookingTestContext.model';
import { BookingContext } from '../../../../../partner/components/bookings/BookingContext.model';
import BookingSection from '../../../../../partner/components/bookings/BookingSection';
import TourFlex from '../../../../../partner/components/bookings/modal/tours/TourFlex';
import { useInitBookingContext } from '../../../../../partner/components/bookings/BookingContext';
import TourTestEditPopover from './popover/TourTestEditPopover';
import TestTour from './TestTour';

const TestTourSettings: FC = () => {
  const bookingTestContext = useInitBookingTestContext();
  const { t } = useTranslation('bookingTest');
  const formik = useFormikContext<BookingTestForm>();
  const fieldProps = formik.getFieldProps<BookingTour>('bookingTour');
  const bookingCtx = useInitBookingContext(undefined);

  return (
    <BookingContext.Provider value={bookingCtx}>
      <BookingTestContext.Provider value={bookingTestContext}>
        <BookingSection title={t('form.tour')} isModalSection={false}>
          <Box
            sx={{
              marginRight: '100px',
            }}
          >
            <Box sx={{ flexGrow: 1, overflowY: 'auto', overflowX: 'hidden' }}>
              <TourFlex left={<Label label={'Von'} />} right={<Label label={'Nach'} />} />
              <Box sx={{ flexGrow: 1, overflowY: 'auto', overflowX: 'hidden' }}>
                <TourFlex
                  left={<TestTour key={`${fieldProps.value.uuid}-left`} tour={fieldProps.value} isFrom={true} />}
                  right={<TestTour key={`${fieldProps.value.uuid}-right`} tour={fieldProps.value} isFrom={false} />}
                />
              </Box>
            </Box>
            <TourTestEditPopover />
          </Box>
        </BookingSection>
      </BookingTestContext.Provider>
    </BookingContext.Provider>
  );
};
export default TestTourSettings;
