import React, { FC } from 'react';
import { Grid2 } from '@mui/material';
import { useDefaultTheme } from '../../../../../themeConfig';

type ToggleHistoryRowProps = {
  date: string;
  time: string;
  modifiedBy: string;
  toggleName: string;
  value: string;
  text: string | undefined;
};

const ToggleHistoryRow: FC<ToggleHistoryRowProps> = ({ date, toggleName, time, text, value, modifiedBy }) => {
  const theme = useDefaultTheme();
  return (
    <Grid2
      container
      size={12}
      sx={{
        alignItems: 'center',
        fontSize: '14px',
        color: theme.umzugEasy.palette.darkGray,
        textAlign: 'center',
        paddingTop: '10px',
        paddingBottom: '10px',
        borderBottom: `0.5px solid ${theme.palette.primary.contrastText}`,
      }}
    >
      <Grid2 size={2}>{date}</Grid2>
      <Grid2 size={1}>{time}</Grid2>
      <Grid2 size={2}>{modifiedBy}</Grid2>
      <Grid2 size={3} sx={{ textWrap: 'wrap' }}>
        {toggleName}
      </Grid2>
      <Grid2 size={1}>{value}</Grid2>
      <Grid2 size={2} sx={{ textWrap: 'wrap' }}>
        {text}
      </Grid2>
    </Grid2>
  );
};

export default ToggleHistoryRow;
