import { PartnerApiClient } from './PartnerApiAdminResource';
import { apiVersion, getApiClient } from '../ApiUtils';

const partnerApiAdminPath = '/admin/partner-api/clients';

export const getPartnerApiClients = (): Promise<PartnerApiClient[]> => {
  return getApiClient()
    .then((client) =>
      client.get<PartnerApiClient[]>(partnerApiAdminPath, {
        baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion,
      }),
    )
    .then((response) => response.data);
};
