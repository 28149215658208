import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/styles';
import { Box, Theme, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { allRequestTypes } from '../../../../../../api/relocationRequest/RelocationRequestDetail';
import BookingSection from '../../BookingSection';
import Checkbox from '../../../../../../components/form/Checkbox';
import Label from '../../../../../../components/form/Label';
import { insertOrRemoveValue } from '../../../../../../utils/FormUtils';
import { mapToCamelCasedString } from '../../../../../../utils/StringUtils';

const RequestTypes: FC = () => {
  const { t } = useTranslation('bookings');
  const theme = useTheme<Theme>();
  const formik = useFormikContext();
  const fieldProps = formik.getFieldProps('requestTypes');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (fieldProps.value.length === 0) {
      setError(t('booking.edit.errors.min1OptionSelected'));
    } else {
      setError(null);
    }
  }, [fieldProps.value, t]);

  return (
    <BookingSection title={t('booking.edit.sections.requestType')} isModalSection={true}>
      <>
        {error && (
          <Typography color='error' variant='body2' sx={{ marginTop: '8px', marginLeft: '8px', fontSize: '14px' }}>
            {error}
          </Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            columnGap: '50px',
            [theme.breakpoints.down('sm')]: {
              flexWrap: 'wrap',
              justifyContent: 'start',
            },
          }}
        >
          {allRequestTypes.map((request) => (
            <Box key={request} sx={{ display: 'flex' }}>
              <Checkbox
                id={fieldProps.name}
                onChange={() => formik?.setFieldValue(fieldProps.name, insertOrRemoveValue(fieldProps.value, request))}
                checked={fieldProps.value.indexOf(request) !== -1}
              />
              <Label label={t(`requestType.${mapToCamelCasedString(request)}`)} />
            </Box>
          ))}
        </Box>
      </>
    </BookingSection>
  );
};

export default RequestTypes;
