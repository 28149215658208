import { FC, ReactElement } from 'react';
import { Box } from '@mui/material';
import DateRangeRow from './DateRangeRow';
import DateRangeHeader from './DateRangeHeader';
import DateRangeFooter from './DateRangeFooter';
import { DateRange } from '../../../../api/bookingsSettings/BookingsSettingsResource';

interface DateRangesProps {
  dateRanges: Array<DateRange>;
}

const DateRanges: FC<DateRangesProps> = ({ dateRanges }: DateRangesProps): ReactElement => {
  return (
    <Box>
      <DateRangeHeader />
      {dateRanges.map((range, index) => (
        <DateRangeRow key={index} dateRange={range} />
      ))}
      <DateRangeFooter />
    </Box>
  );
};

export default DateRanges;
