import React, { FC } from 'react';
import { Box, IconButton, Theme } from '@mui/material';
import LabeledField from '../../../../../components/form/LabeledField';
import { useFormikContext } from 'formik';
import { useTheme } from '@mui/styles';
import ActionImg from '../../../../../components/shared/ActionImg';
import { PartnerDocument } from '../../../../../api/partnerDocuments/PartnerDocumentsResource';
import { DocumentsModalForm } from '../DocumentsContext.model';

type UploadFileProps = {
  label: string;
  document?: PartnerDocument;
};
const UploadFile: FC<UploadFileProps> = (props: UploadFileProps) => {
  const formik = useFormikContext<DocumentsModalForm>();
  const uploadedFilename = formik.getFieldProps('file').value?.name;
  const theme = useTheme<Theme>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files ? e.currentTarget.files!![0] : null;
    formik.setFieldValue('file', file);
    formik.setFieldValue('name', file?.name);
  };

  return (
    <Box sx={{ marginTop: '12px' }}>
      <LabeledField label={props.label}>
        <IconButton sx={{ color: theme.palette.primary.dark }} component={'label'}>
          <input hidden id={'file'} type='file' onChange={(e) => handleChange(e)} />
          <ActionImg icon={'/icons/upload.svg'} />
          <Box
            sx={{
              color: theme.palette.text.secondary,
              fontSize: theme.typography.fontSize,
              paddingLeft: '12px',
            }}
          >
            <span>{uploadedFilename || props.document?.fileName}</span>
          </Box>
        </IconButton>
      </LabeledField>
    </Box>
  );
};

export default UploadFile;
