import React, { FC } from 'react';
import { Box } from '@mui/material';
import Header from './Header';
import TourEditPopover from './popover/TourEditPopover';
import TourList from './TourList';

const TourForm: FC = () => (
  <Box sx={{ maxHeight: '70vh', display: 'flex', flexDirection: 'column' }}>
    <Header />
    <TourList />
    <TourEditPopover />
  </Box>
);
export default TourForm;
