import React, { FC, ReactElement } from 'react';
import { Box } from '@mui/material';
import Alert from '../../components/feedback/Alert';
import ApiClientsList from '../../pages/admin/components/apiClients/ApiClientsList';
import { useInitApiClientsContext } from './components/apiClients/ApiClientsContext';
import { ApiClientsContext } from './components/apiClients/ApiClientsContext.model';

const ApiClients: FC = (): ReactElement => {
  const ctx = useInitApiClientsContext();

  return (
    <Box sx={{ width: '100%' }}>
      <ApiClientsContext.Provider value={ctx}>
        <ApiClientsList />
        <Alert text={''} {...ctx.alert} />
      </ApiClientsContext.Provider>
    </Box>
  );
};

export default ApiClients;
