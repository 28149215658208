import React, { FC } from 'react';
import { Box } from '@mui/material';
import Button from '../../../../../components/form/Button';

type ActionButtonsProps = {
  closeButtonLabel: string;
  submitButtonLabel: string;
  isSubmitButtonDisabled: boolean;
  onUpload: Function;
  onClose: Function;
};
const ActionButtons: FC<ActionButtonsProps> = (props: ActionButtonsProps) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
      <Button
        type={'reset'}
        label={props.closeButtonLabel}
        variant={'outlined'}
        sx={{ margin: '0 5px' }}
        onClick={() => props.onClose()}
      />
      <Button
        type={'submit'}
        disabled={props.isSubmitButtonDisabled}
        label={props.submitButtonLabel}
        onClick={() => {
          props.onUpload();
        }}
        sx={{ margin: '0 5px' }}
      />
    </Box>
  );
};
export default ActionButtons;
