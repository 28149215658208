import React, { FC, ReactElement } from 'react';
import { Typography } from '@mui/material';

export type FontWeight = 'bold' | 'normal';
export type FontSize = 'small' | 'medium' | 'large';

export type UpperCaseHeaderProps = {
  title: string;
  fontSize: FontSize;
  weight: FontWeight;
  className?: string;
};

const fontWeightDefinition: Map<FontWeight, number> = new Map<FontWeight, number>([
  ['bold', 600],
  ['normal', 400],
]);

const UpperCaseHeader: FC<UpperCaseHeaderProps> = (props: UpperCaseHeaderProps): ReactElement => (
  <Typography
    className={props?.className}
    sx={{
      fontWeight: fontWeightDefinition.get(props.weight) || 400,
      textAlign: 'center',
      fontSize: props.fontSize,
      textTransform: 'capitalize',
    }}
  >
    {props.title}
  </Typography>
);

export default UpperCaseHeader;
