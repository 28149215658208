import { PaymentType, RelocationType, RequestType } from '../relocationRequest/RelocationRequestDetail';
import { SelectableValue } from '../../api/SelectableValue';

export enum BookingType {
  SPECIAL = 'SPECIAL',
  FILTER = 'FILTER',
  UPGRADE = 'UPGRADE',
  PREMIUM = 'PREMIUM', // deprecated, kept for historical reasons R8N-1582
}

export const allBookingTypes: BookingType[] = [BookingType.FILTER, BookingType.SPECIAL, BookingType.UPGRADE];

export enum BookingDirection {
  ONE_WAY = 'ONE_WAY',
  TWO_WAY = 'TWO_WAY',
}

export enum BookingTourType {
  TO = 'TO',
  TO_EXCLUDED = 'TO_EXCLUDED',
  FROM = 'FROM',
  FROM_EXCLUDED = 'FROM_EXCLUDED',
}

export enum PriceGroupType {
  STANDARD = 'STANDARD', // all price groups
  CH = 'CH', // priceGroup1, priceGroup2, priceGroupOther
}

export interface Booking {
  id?: number;
  name: string;
  active: boolean;
  partnerCwid: string;
  type: BookingType;
  minSquareMeters: number;
  maxSquareMeters: number;
  minDistance: number;
  maxDistance: number;
  bookingTours: BookingTour[];
  relocationTypes: RelocationType[];
  pricesByApartmentArea: BookingPricesByApartmentArea;
  paymentTypes: PaymentType[];
  maxPartners: SelectableValue<number | null>;
  activationDate?: Date;
  deactivationDate?: Date;
  requestTypes: RequestType[];
}

export interface BookingPricesByApartmentArea {
  priceGroup1: number;
  priceGroup2: number;
  priceGroup3: number | null;
  priceGroupOther: number;
  priceGroupType: PriceGroupType;
}

export const getPriceGroups = (prices: BookingPricesByApartmentArea): number[] => {
  switch (prices.priceGroupType) {
    case PriceGroupType.STANDARD:
      const priceGroup3 = prices.priceGroup3 ? prices.priceGroup3 : 0;
      return [prices.priceGroup1, prices.priceGroup2, priceGroup3, prices.priceGroupOther];
    case PriceGroupType.CH:
      return [prices.priceGroup1, prices.priceGroup2, prices.priceGroupOther];
  }
};

export const emptyBookingPricesByApartmentArea = (): BookingPricesByApartmentArea => ({
  priceGroup1: 0,
  priceGroup2: 0,
  priceGroup3: null,
  priceGroupOther: 0,
  priceGroupType: PriceGroupType.STANDARD,
});

export interface BookingTour {
  id?: number;
  uuid?: string;
  name: string;
  direction: BookingDirection;
  surcharge: number;
  bookingTourRegions: BookingTourRegion[];
  bookingTourPostalCodes: BookingTourPostalCode[];
}

export interface BookingTourRegion {
  id?: number;
  regionId: number;
  type: BookingTourType;
}

export interface BookingTourPostalCode {
  id?: number;
  country: string;
  type: BookingTourType;
  postalCode?: string;
}
