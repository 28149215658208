import React from 'react';
import {
  LicenseDuplicateCheckResponse,
  PartnerDocument,
  PartnerDocumentType,
} from '../../../../api/partnerDocuments/PartnerDocumentsResource';
import { Shape } from '../../../../components/form/FormInput';
import * as Yup from 'yup';
import { DeletionConfirmationModalProps } from '../../../../components/form/DeletionConfirmationModal';
import { AlertProps } from '../../../../components/feedback/Alert';

const emptyFunction = () => {};

export type DocumentsActionsProps = {
  onPartnerDocumentOpen: (partnerCwid: string, partnerDocumentId: number) => void;
  onPartnerDocumentRemove: (partnerDocumentId?: number) => void;
  onChangePartnerDocumentAvailability: (
    isAvailable: boolean,
    type: PartnerDocumentType,
    partnerDocumentId?: number,
  ) => void;
  onPartnerDocumentUpload: (response: PartnerDocument, partnerDocumentId?: number) => void;
  onEditModalOpen: (document?: PartnerDocument, type?: PartnerDocumentType, displayType?: string) => void;
  onEditModalClose: () => void;
  onCheckLicenseDuplicates: (licenseNumber: string) => void;
};

export type DocumentsContextProps = {
  standardPartnerDocuments: PartnerDocument[];
  otherPartnerDocuments: PartnerDocument[];
  isLoading: boolean;
  deletionConfirmationModal: DeletionConfirmationModalProps | undefined;
  isDeleting: boolean;
  alert?: AlertProps;
  document?: PartnerDocument;
  documentType?: PartnerDocumentType;
  documentDisplayType?: string;
  isEditModalOpen: boolean;
  actions: DocumentsActionsProps;
  licenseDuplicateCheckResponse: LicenseDuplicateCheckResponse | undefined;
};

export const DocumentsContext = React.createContext<DocumentsContextProps>({
  standardPartnerDocuments: [],
  otherPartnerDocuments: [],
  isLoading: false,
  deletionConfirmationModal: undefined,
  isDeleting: false,
  document: undefined,
  isEditModalOpen: false,
  licenseDuplicateCheckResponse: undefined,
  actions: {
    onPartnerDocumentOpen: emptyFunction,
    onPartnerDocumentRemove: emptyFunction,
    onChangePartnerDocumentAvailability: emptyFunction,
    onPartnerDocumentUpload: emptyFunction,
    onEditModalOpen: emptyFunction,
    onEditModalClose: emptyFunction,
    onCheckLicenseDuplicates: emptyFunction,
  },
});

export interface DocumentsModalForm extends Shape {
  file?: File;
  name?: string;
  licenseNumber?: string;
  expirationDate?: Date;
  type?: PartnerDocumentType;
  documentId?: number;
}

export const schema = Yup.object<DocumentsModalForm>().shape({});

export const initializeDocumentForm = (document?: PartnerDocument): DocumentsModalForm => ({
  file: undefined,
  name: document?.fileName || '',
  licenseNumber: document?.licenceNumber || '',
  expirationDate: document ? new Date(document.expirationDate!!) : undefined,
  type: document?.type,
  documentId: document?.id || undefined,
});
