import { apiVersion, getApiClient } from '../ApiUtils';
import { Region } from './RegionResource';

export function getRegions(): Promise<Region[]> {
  return getApiClient()
    .then((client) =>
      client.get<Region[]>(`/regions`, { baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion }),
    )
    .then((response) => response.data);
}
