import {
  RelocationFeature,
  RelocationRequestDetailObjectResponse,
  RelocationType,
} from '../../../../../api/relocationRequest/RelocationRequestDetail';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

export type MoveOutFeaturesSubsectionProps = {
  relocationFeatures: Array<RelocationFeature>;
  fromObject: RelocationRequestDetailObjectResponse;
  relocationType: RelocationType;
};

const MoveOutFeaturesSubsection: FC<MoveOutFeaturesSubsectionProps> = ({
  relocationFeatures,
  fromObject,
  relocationType,
}: MoveOutFeaturesSubsectionProps): ReactElement => {
  const { t } = useTranslation('relocationRequests');
  const translationPathPrefix = 'requestDetails.detailsTab.sections.fromTo.features';
  const yes = t(`${translationPathPrefix}.yes`);
  const no = t(`${translationPathPrefix}.no`);
  const client = t(`${translationPathPrefix}.client`);
  const spedition = t(`${translationPathPrefix}.spedition`);
  const translationKeyOf = (postfix: string) => `${translationPathPrefix}.${postfix}`;
  const moveOutKeyOf = (postfix: string) => `${translationPathPrefix}.moveOut.${postfix}`;
  const hasFeature = (feature: RelocationFeature, truthyString: string, falsyString: string) => {
    return relocationFeatures.includes(feature) ? truthyString : falsyString;
  };
  const isPrivate = relocationType === RelocationType.RELOCATION;
  const isCorporate = relocationType === RelocationType.COMPANY_RELOCATION;
  const isPrivateOrCorporate = isPrivate || isCorporate;

  return (
    <>
      {isPrivate && (
        <Box>
          {t(translationKeyOf('cellarAtticIncluded'), {
            cellar: hasFeature(RelocationFeature.CELLAR_ATTIC_INCLUDED, t(yes), t(no)),
          })}
        </Box>
      )}
      {isPrivate && (
        <Box>
          {t(translationKeyOf('atticIncluded'), { attic: hasFeature(RelocationFeature.ATTIC_INCLUDED, t(yes), t(no)) })}
        </Box>
      )}
      {isPrivate && (
        <Box>
          {t(translationKeyOf('arrangeViewing'), {
            viewing: hasFeature(RelocationFeature.ARRANGE_VIEWING, t(yes), t(no)),
          })}
        </Box>
      )}
      <Box sx={{ my: 2.5 }}>
        {fromObject.hasElevator !== undefined &&
          t(moveOutKeyOf('elevator'), { elevator: fromObject.hasElevator === true ? yes : no })}
      </Box>
      {isPrivateOrCorporate && <Box>{t(moveOutKeyOf('subheader'))}</Box>}
      {isPrivateOrCorporate && (
        <Box>
          {t(moveOutKeyOf('pack'), { clientOrSpedition: hasFeature(RelocationFeature.PACK, spedition, client) })}
        </Box>
      )}
      {isPrivateOrCorporate && (
        <Box>
          {t(moveOutKeyOf('furnitureDisassembly'), {
            clientOrSpedition: hasFeature(RelocationFeature.FURNITURE_DISASSEMBLY, spedition, client),
          })}
        </Box>
      )}
      {isPrivate && (
        <Box>
          {t(moveOutKeyOf('kitchenDisassembly'), {
            clientOrSpedition: hasFeature(RelocationFeature.KITCHEN_DISASSEMBLY, spedition, client),
          })}
        </Box>
      )}
      {isCorporate && (
        <Box>
          {t(moveOutKeyOf('officeEquipmentOrMachineDisassembly'), {
            clientOrSpedition: hasFeature(RelocationFeature.OFFICE_EQUIPMENT_OR_MACHINE_DISASSEMBLY, spedition, client),
          })}
        </Box>
      )}
      {isCorporate && (
        <Box>
          {t(moveOutKeyOf('disposal'), { disposal: hasFeature(RelocationFeature.DISPOSAL_FROM, spedition, client) })}
        </Box>
      )}
      {isCorporate && (
        <Box>
          {t(translationKeyOf('heavyGoodsTransport'), {
            heavyLoad: hasFeature(RelocationFeature.HEAVY_GOODS_TRANSPORT, spedition, client),
          })}
        </Box>
      )}
      {isPrivateOrCorporate && (
        <Box>
          {t(moveOutKeyOf('noParkingZoneFrom'), {
            noParking: hasFeature(RelocationFeature.NO_PARKING_ZONE_FROM, yes, no),
          })}
        </Box>
      )}
    </>
  );
};

export default MoveOutFeaturesSubsection;
