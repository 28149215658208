import { useContext, useEffect, useState } from 'react';
import { PartnerLeadsFilters } from '../../../../api/partner/PartnerResource';
import { AlertProps } from '../../../../components/feedback/Alert';
import { RequestContext, RequestsActionsProps, RequestsContextProps } from './RequestsContext.model';
import { useTranslation } from 'react-i18next';
import { Lead, PartnerLead } from '../../../../api/lead/LeadResources';
import { getLeads, getPartnerLeads, getPartnerLeadsStatistics } from '../../../../api/lead/LeadApi';
import { DeletionConfirmationModalProps } from '../../../../components/form/DeletionConfirmationModal';
import { anonymizeRelocationRequest } from '../../../../api/relocationRequest/RelocationRequestsApi';

export const getInitialPartnerLeadsFilters = (): PartnerLeadsFilters => {
  const now = new Date();

  // default - last 30 days
  const dateFrom = new Date(now);
  dateFrom.setUTCDate(now.getUTCDate() - 30);
  dateFrom.setUTCHours(0, 0, 0, 0);

  const dateTo = new Date(now);
  dateTo.setUTCHours(23, 59, 59, 999);
  return {
    start: dateFrom,
    end: dateTo,
  };
};

export function useInitRequestsContext(partnerCwid: string | undefined): RequestsContextProps {
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertProps | undefined>(undefined);

  const [partnerLeads, setPartnerLeads] = useState<PartnerLead[]>([]);
  const [partnerLeadsFilters, setPartnerLeadsFilters] = useState<PartnerLeadsFilters>(getInitialPartnerLeadsFilters());
  const [page, setPage] = useState(-1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [totalCancelled, setTotalCancelled] = useState(0);

  const [leadsByRequestId, setLeadsByRequestId] = useState<Map<string, Lead[]>>(new Map());
  const [openLeadDetailsId, setOpenLeadDetailsId] = useState<string | undefined>(undefined);

  const [anonymizationConfirmationModal, setAnonymizationConfirmationModal] = useState<
    DeletionConfirmationModalProps | undefined
  >(undefined);
  const [isAnonymizingRelocationRequest, setIsAnonymizingRelocationRequest] = useState<boolean>(false);

  const { t } = useTranslation(['relocationRequests', 'requests']);

  const showAlert = (translationKey: string) => {
    setAlert({
      color: 'error',
      open: true,
      text: t(`leads.alerts.${translationKey}`),
      handleClose: () => setAlert(undefined),
    });
  };

  const hideAnonymizationConfirmationModalModal = () => setAnonymizationConfirmationModal(undefined);

  const anonymize = () => {
    if (openLeadDetailsId) {
      setIsAnonymizingRelocationRequest(true);
      anonymizeRelocationRequest(openLeadDetailsId)
        .then((relocationRequestDetailResponse) => {
          setPartnerLeads((prevState) =>
            prevState.map((lead) =>
              openLeadDetailsId === lead.relocationRequest.requestId
                ? { ...lead, relocationRequest: relocationRequestDetailResponse }
                : lead,
            ),
          );
        })
        .catch(() => showAlert('anonymizationError'))
        .finally(() => {
          setIsAnonymizingRelocationRequest(false);
          hideAnonymizationConfirmationModalModal();
        });
    }
  };

  const loadPartnerLeads = (pageForLoad: number) => {
    if (partnerCwid) {
      if (pageForLoad === 0) {
        setLoading(true);
      }
      getPartnerLeads(partnerCwid, pageForLoad, 20, partnerLeadsFilters)
        .then((partnerLeads) => {
          if (pageForLoad === 0) {
            setPartnerLeads(partnerLeads._embedded?.leads || []);
          } else {
            setPartnerLeads((prevState) => [...prevState, ...(partnerLeads._embedded?.leads || [])]);
          }
          setTotalElements(partnerLeads.page.totalElements);
          setTotalPages(partnerLeads.page.totalPages);
          setPage(pageForLoad);
        })
        .catch(() => showAlert('getPartnerLeadsError'))
        .finally(() => setLoading(false));
    }
  };

  const loadPartnerLeadsStatistics = () => {
    partnerCwid &&
      getPartnerLeadsStatistics(partnerCwid, partnerLeadsFilters).then((partnerLeadsStatistics) =>
        setTotalCancelled(partnerLeadsStatistics.totalCancelled),
      );
  };

  const actions: RequestsActionsProps = {
    onOpenPartnerLeadDetails: (requestId: string) => {
      if (!leadsByRequestId.has(requestId)) {
        setLoading(true);
        getLeads(requestId)
          .then((leads) => setLeadsByRequestId((prevState) => new Map(prevState).set(requestId, leads.leads)))
          .catch(() => showAlert('getLeadsError'))
          .finally(() => setLoading(false));
      }
      setOpenLeadDetailsId((prevState) => (prevState === requestId ? undefined : requestId));
    },
    onLoadNextPage: () => {
      if (page + 1 < totalPages) {
        loadPartnerLeads(page + 1);
      }
    },
    onFilterChange: (filter: PartnerLeadsFilters) => setPartnerLeadsFilters(filter),
    onAnonymizeRelocationRequest: () => {
      setAnonymizationConfirmationModal({
        labels: {
          title: t('requestDetails.detailsTab.modals.anonymize.title'),
          description: t('requestDetails.detailsTab.modals.anonymize.description'),
          buttonYes: t('requestDetails.detailsTab.modals.anonymize.buttonYes'),
          buttonNo: t('requestDetails.detailsTab.modals.anonymize.buttonNo'),
        },
        confirm: () => anonymize(),
        cancel: () => hideAnonymizationConfirmationModalModal(),
      });
    },
  };

  useEffect(() => {
    if (partnerCwid) {
      loadPartnerLeads(0);
      loadPartnerLeadsStatistics();
    }
    // eslint-disable-next-line
  }, [partnerCwid, partnerLeadsFilters]);

  return {
    partnerLeads,
    leadsByRequestId,
    openLeadDetailsId,
    loading,
    page,
    totalPages,
    totalElements,
    totalCancelled,
    anonymizationConfirmationModal,
    isAnonymizingRelocationRequest,
    alert,
    actions,
  };
}

export function useRequestsContext(): RequestsContextProps {
  return useContext(RequestContext);
}
