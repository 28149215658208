import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import {
  RelocationRequestDetail,
  RelocationRequestInventory,
} from '../../../api/relocationRequest/RelocationRequestDetail';
import {
  getRelocationRequestDetail,
  getRelocationRequestInventory,
} from '../../../api/relocationRequest/RelocationRequestsApi';
import Alert from '../../../components/feedback/Alert';
import LeadDetails from '../../../pages/relocationRequest/invntoryListDetail/LeadDetails';
import InventoryListDetails from '../../../pages/relocationRequest/invntoryListDetail/InventoryListDetails';
import PhotoInventoryDetails from '../../../pages/relocationRequest/invntoryListDetail/PhotoInventoryDetails';
import FreeTextInventoryDetails from '../../../pages/relocationRequest/invntoryListDetail/FreeTextInventoryDetails';

type InventoryPartContainerProps = {
  children: ReactElement[];
  fullWidth?: boolean;
  maxWidth?: string;
  minWidth?: string;
  hide?: boolean;
};
type TitleProps = {
  title: string;
  size: 'small' | 'normal';
};
const Title: FC<TitleProps> = ({ title, size }): ReactElement => {
  const fontSize = {
    small: '16px',
    normal: '24px',
  };
  return (
    <Box
      sx={{
        fontSize: fontSize[size] || fontSize.normal,
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '33px',
        paddingBottom: '10px',
      }}
    >
      {title}
    </Box>
  );
};

const InventoryPartContainer: FC<InventoryPartContainerProps> = ({
  children,
  fullWidth,
  minWidth,
  maxWidth,
  hide,
}): ReactElement => {
  if (hide) {
    return <></>;
  }
  return (
    <Box
      sx={{
        flexGrow: `${fullWidth ? '1' : '0'}`,
        maxWidth: `${maxWidth || 'unset'}`,
        minWidth: `${minWidth || 'unset'}`,
        display: 'block',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </Box>
  );
};

const InventoryList: FC = (): ReactElement => {
  const { id } = useParams();
  const { t } = useTranslation('inventory');

  const [relocationRequest, setRelocationRequest] = useState<RelocationRequestDetail | undefined>();
  const [relocationRequestInventory, setRelocationRequestInventory] = useState<
    RelocationRequestInventory | undefined
  >();

  const [hasInventoryList, setHasInventoryList] = useState(false);
  const [hasPhotoInventory, setHasPhotoInventory] = useState(false);
  const [hasFreeTextInventoryList, setHasFreeTextInventoryList] = useState(false);

  const [showInventoryList, setShowInventoryList] = useState(false);

  const [isLoading, setLoading] = useState<boolean>(false);

  const [apiError, setApiError] = useState<boolean>(false);

  useEffect(() => {
    if (id != null) {
      setLoading(true);
      Promise.all([getRelocationRequestDetail(id), getRelocationRequestInventory(id)])
        .then((response: [RelocationRequestDetail, RelocationRequestInventory]) => {
          setRelocationRequest(response[0]);
          setRelocationRequestInventory(response[1]);
        })
        .catch(() => setApiError(true))
        .finally(() => setLoading(false));
    } else {
      setRelocationRequest(undefined);
      setRelocationRequestInventory(undefined);
    }
  }, [id]);

  useEffect(() => {
    setShowInventoryList(hasInventoryList || isLoading);
  }, [isLoading, hasInventoryList, setShowInventoryList]);

  useEffect(() => {
    setHasInventoryList(relocationRequestInventory?.inventoryList != null);
    setHasPhotoInventory(
      (relocationRequestInventory?.photoInventory || []).length > 0 ||
        (relocationRequestInventory?.photoInventoryListComment || '').length > 0,
    );
    setHasFreeTextInventoryList((relocationRequestInventory?.freeTextInventoryList || '').length > 0);
  }, [relocationRequestInventory]);

  return (
    <Box
      sx={{
        backgroundColor: 'whitesmoke',
        display: showInventoryList ? 'block' : 'flex',
        color: 'black',
        justifyContent: 'space-between',
        maxWidth: '1200px',
        width: '100%',
        margin: '0 auto',
        alignItems: 'start',
        paddingTop: '16px',
        paddingBottom: '16px',
        columnGap: '64px',
      }}
    >
      <Box
        sx={{
          flexGrow: showInventoryList ? 1 : 0,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          columnGap: '64px',
          paddingBottom: '16px',
        }}
      >
        <InventoryPartContainer maxWidth={'450px'}>
          <Title title={`${t('title.detail')}: ${id}`} size={'normal'} />
          <LeadDetails
            isLoading={isLoading}
            details={relocationRequest}
            hide={relocationRequest == null && !isLoading}
          />
          <FreeTextInventoryDetails
            inventory={relocationRequestInventory}
            isLoading={isLoading}
            hide={!hasFreeTextInventoryList && !isLoading}
          />
        </InventoryPartContainer>
        <InventoryPartContainer fullWidth hide={!showInventoryList} minWidth={'450px'}>
          <Title title={t('title.inventoryList')} size={'small'} />
          <InventoryListDetails isLoading={isLoading} inventory={relocationRequestInventory} />
        </InventoryPartContainer>
      </Box>
      <InventoryPartContainer fullWidth hide={!hasPhotoInventory && !isLoading}>
        <Title title={t('title.photoInventory')} size={'small'} />
        <PhotoInventoryDetails inventory={relocationRequestInventory} isLoading={isLoading} />
      </InventoryPartContainer>
      <Alert text={t('error.apiError')} open={apiError} handleClose={() => setApiError(false)} />
    </Box>
  );
};

export default InventoryList;
