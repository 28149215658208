import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

const Administration: FC<any> = (): ReactElement => {
  const { t } = useTranslation('common');
  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: 'whitesmoke',
        display: 'block',
        color: 'black',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant='h3'>{t('nav.administration')}</Typography>
    </Box>
  );
};

export default Administration;
