import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import {
  BookingTestSummary,
  useBookingTestResponseLoader,
} from '../../../components/table/schema/type/BookingTestResponseSchema';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/form/Button';
import { BookingTestDataRequest } from '../../../api/bookingtest/BookingTestDataRequest';
import BookingTestFilters from './components/BookingTestFilters';
import ResultSettings from './components/results/ResultSettings';

const BookingTest: FC = (): ReactElement => {
  const { setBookingTestRequest, loading, resultsSummary } = useBookingTestResponseLoader();
  const [formValues, setFormValues] = useState<BookingTestDataRequest | null>(null);
  const [summary, setSummary] = useState<BookingTestSummary | null>(null);
  const { t } = useTranslation('bookingTest');

  useEffect(() => {
    if (resultsSummary) {
      setSummary(resultsSummary);
    }
  }, [resultsSummary]);

  const handleSendClick = () => {
    if (formValues) {
      setBookingTestRequest(formValues);
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: 'whitesmoke',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <BookingTestFilters onBookingTestRequestChanged={(request) => setFormValues(request)} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'left',
          width: '100%',
          maxWidth: '1021px',
          marginLeft: '20px',
        }}
      >
        <Button
          type={'submit'}
          disabled={loading}
          icon={
            loading ? (
              <CircularProgress size={20} sx={{ mr: '10px' }} color='inherit' />
            ) : (
              <SearchIcon sx={{ fontSize: '18px', marginRight: '10px' }} />
            )
          }
          label={t('form.search')}
          onClick={handleSendClick}
        />
      </Box>
      {summary && <ResultSettings summary={summary} />}
    </Box>
  );
};

export default BookingTest;
