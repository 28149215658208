import { FC, ReactElement } from 'react';
import { Box } from '@mui/material';
import Section from '../../../../components/form/Section';
import LeadsList from './LeadsList';
import { useTranslation } from 'react-i18next';
import LeadsSummary from './LeadsSummary';
import LeadsFilter from './LeadsFilter';

const sectionStyle = {
  container: {
    padding: '0px',
    paddingTop: '22px',
  },
};

const RequestsSections: FC = (): ReactElement => {
  const { t } = useTranslation('requests');

  return (
    <Box>
      <LeadsFilter />
      <Section title={t('leads.summary.section')} styles={sectionStyle}>
        <LeadsSummary />
      </Section>
      <Section title={t('leads.table.requestsReceived')} styles={sectionStyle}>
        <LeadsList />
      </Section>
    </Box>
  );
};

export default RequestsSections;
