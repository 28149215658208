import React, { CSSProperties } from 'react';
import { BookingType } from '../../../../api/booking/BookingResource';

type BadgeSize = 'small' | 'big';
export type BookingTypeIconProps = {
  type: BookingType;
  size: BadgeSize;
  sx?: CSSProperties;
};
const BookingTypeIcon: React.FC<BookingTypeIconProps> = (props: BookingTypeIconProps) => {
  const badgeConf2: { type: BookingType; iconPath: string }[] = [
    { type: BookingType.FILTER, iconPath: '/icons/bookingType/filter.svg' },
    { type: BookingType.UPGRADE, iconPath: '/icons/bookingType/upgrade.svg' },
    { type: BookingType.SPECIAL, iconPath: '/icons/bookingType/special.svg' },
    { type: BookingType.PREMIUM, iconPath: '/icons/bookingType/premium.svg' }, // deprecated, kept for historical reasons R8N-1582
  ];
  const badgeSizeConf: { size: BadgeSize; iconSize: string }[] = [
    { size: 'small', iconSize: '16px' },
    { size: 'big', iconSize: '20px' },
  ];
  const currentConf = badgeConf2.find((item) => item.type === props.type);
  const currentSizeConf = badgeSizeConf.find((item) => item.size === props.size);

  return (
    <img
      src={currentConf?.iconPath}
      alt={'filter'}
      style={{
        width: currentSizeConf?.iconSize,
        height: currentSizeConf?.iconSize,
        ...(props.sx || {}),
      }}
    />
  );
};
export default BookingTypeIcon;
