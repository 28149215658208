import { UmzugEasyTableProps } from '../../UmzugEasyTable';
import { EmailRenderer } from '../../util/UmzugEasyCellRenderers';
import { getUmzugEasyTableProps } from '../SchemaUtil';
import { TableSchema } from '../SchemaModel';
import { useEffect, useState } from 'react';
import { RelocationRequest } from '../../../../api/relocationRequest/RelocationRequest';
import { RelocationRequestFilters } from '../../../../api/relocationRequest/RelocationRequestFilters';
import {
  anonymizeRelocationRequest,
  getRelocationRequestDetail,
  getRelocationRequests,
} from '../../../../api/relocationRequest/RelocationRequestsApi';
import { TableHeader } from '../../model/UmzugEasyTableModel';
import {
  RelocationRequestDetail,
  relocationRequestDetailToRelocationRequest,
} from '../../../../api/relocationRequest/RelocationRequestDetail';
import { Lead } from '../../../../api/lead/LeadResources';
import { getLeads } from '../../../../api/lead/LeadApi';
import { useTranslation } from 'react-i18next';
import { DeletionConfirmationModalProps } from '../../../form/DeletionConfirmationModal';
import { AlertProps } from '../../../feedback/Alert';

export const setHeadersVisibility = (
  partialTable: boolean,
  tableProps: UmzugEasyTableProps<RelocationRequest>,
): UmzugEasyTableProps<RelocationRequest> => {
  const getHeaders = <T extends unknown>(headers: TableHeader<T>[], partialTable: boolean): TableHeader<T>[] => {
    const partialTableContent = ['anfrageId', 'name'];
    return headers.map((header) => {
      if (partialTable) {
        header.hide = partialTableContent.indexOf(header.name.replace('list.table.header.', '')) === -1;
      } else {
        header.hide = false;
      }
      return header;
    });
  };
  return {
    ...tableProps,
    table: {
      ...tableProps.table,
      content: {
        ...tableProps.table.content,
        headers: getHeaders(tableProps.table.content.headers, partialTable),
      },
    },
  };
};

const useRelocationRequestSelection = (relocationRequests: RelocationRequest[]) => {
  const [selectedPartner, setSelectedPartner] = useState<RelocationRequest | undefined>(undefined);
  const [partialTable, setPartialTable] = useState(false);

  useEffect(() => {
    // change partial table if user select the user
    setPartialTable(selectedPartner !== undefined);
  }, [selectedPartner, setPartialTable]);

  useEffect(() => {
    // if user click partial table check if user is selected, if not select the first one
    if (partialTable && selectedPartner == null && relocationRequests.length > 0) {
      setSelectedPartner(relocationRequests[0]);
    }
    // eslint-disable-next-line
  }, [partialTable]);

  return { selectedPartner, partialTable, setSelectedPartner, setPartialTable };
};

export const useRelocationRequestsLoader = (autoInit?: boolean) => {
  const { t } = useTranslation('relocationRequests');
  const [alert, setAlert] = useState<AlertProps | undefined>(undefined);
  const [relocationRequests, setRelocationRequests] = useState<RelocationRequest[]>([]);
  const [relocationRequestDetail, setRelocationRequestDetail] = useState<RelocationRequestDetail>();
  const [relocationRequestDetailLoading, setRelocationRequestDetailLoading] = useState(false);
  const [anonymizationConfirmationModal, setAnonymizationConfirmationModal] = useState<
    DeletionConfirmationModalProps | undefined
  >(undefined);
  const [isAnonymizingRelocationRequest, setIsAnonymizingRelocationRequest] = useState<boolean>(false);
  const [leads, setLeads] = useState<Lead[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(-1);
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState<RelocationRequestFilters | undefined>(undefined);
  const [tableProps, setTableProps] = useState<UmzugEasyTableProps<RelocationRequest>>(getRelocationRequestsSchema());
  const [loading, setLoading] = useState(false);

  const { selectedPartner, partialTable, setSelectedPartner, setPartialTable } =
    useRelocationRequestSelection(relocationRequests);

  const showAlert = (translationKey: string) => {
    setAlert({
      color: 'error',
      open: true,
      text: t(`alerts.${translationKey}`),
      handleClose: () => setAlert(undefined),
    });
  };

  const hideAnonymizationConfirmationModalModal = () => setAnonymizationConfirmationModal(undefined);

  const loadRelocationRequests = (pageForLoad: number, filtersForLoad: RelocationRequestFilters) => {
    setLoading(true);
    getRelocationRequests(pageForLoad, 20, filtersForLoad).then((relocationRequests) => {
      if (pageForLoad === 0) {
        setRelocationRequests(relocationRequests._embedded?.requests || []);
      } else {
        setRelocationRequests((prevState) => [...prevState, ...(relocationRequests._embedded?.requests || [])]);
      }
      setCount(relocationRequests.page.totalElements);
      setPageCount(relocationRequests.page.totalPages);
      setPage(pageForLoad);
      setLoading(false);
    });
  };

  const loadNextPage = () => {
    if (page + 1 < pageCount) {
      loadRelocationRequests(page + 1, filters || {});
    }
  };

  const changeFilters = (value: RelocationRequestFilters) => {
    setFilters(value);
    loadRelocationRequests(0, value);
  };

  const anonymize = () => {
    if (relocationRequestDetail) {
      setIsAnonymizingRelocationRequest(true);
      anonymizeRelocationRequest(relocationRequestDetail.requestId)
        .then((relocationRequestDetailResponse) => {
          setRelocationRequestDetail(relocationRequestDetailResponse);
          setRelocationRequests((prevState) =>
            prevState.map((relocationRequest) =>
              relocationRequest.requestId === relocationRequestDetail.requestId
                ? relocationRequestDetailToRelocationRequest(relocationRequestDetailResponse)
                : relocationRequest,
            ),
          );
        })
        .catch(() => showAlert('anonymizationError'))
        .finally(() => {
          setIsAnonymizingRelocationRequest(false);
          hideAnonymizationConfirmationModalModal();
        });
    }
  };

  const onAnonymizeRelocationRequest = () => {
    setAnonymizationConfirmationModal({
      labels: {
        title: t('requestDetails.detailsTab.modals.anonymize.title'),
        description: t('requestDetails.detailsTab.modals.anonymize.description'),
        buttonYes: t('requestDetails.detailsTab.modals.anonymize.buttonYes'),
        buttonNo: t('requestDetails.detailsTab.modals.anonymize.buttonNo'),
      },
      confirm: () => anonymize(),
      cancel: () => hideAnonymizationConfirmationModalModal(),
    });
  };

  useEffect(() => {
    if (autoInit) {
      loadRelocationRequests(0, {});
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTableProps(setHeadersVisibility(partialTable, getRelocationRequestsSchema(relocationRequests)));
    // eslint-disable-next-line
  }, [relocationRequests]);

  useEffect(() => {
    setTableProps((prevState) => setHeadersVisibility(partialTable, prevState));
  }, [partialTable]);

  useEffect(() => {
    if (selectedPartner?.requestId) {
      setRelocationRequestDetailLoading(true);
      getRelocationRequestDetail(selectedPartner?.requestId!!)
        .then((relocationRequest) => {
          setRelocationRequestDetail(relocationRequest);
          getLeads(selectedPartner?.requestId!!).then((leads) => setLeads(leads.leads));
        })
        .finally(() => setRelocationRequestDetailLoading(false));
    }
  }, [selectedPartner]);

  return {
    selectedPartner,
    setSelectedPartner,
    tableProps,
    count,
    pageCount,
    page,
    filters,
    loadNextPage,
    changeQuery: changeFilters,
    partialTable,
    setPartialTable,
    loading,
    alert,
    relocationRequestDetail,
    relocationRequestDetailLoading,
    onAnonymizeRelocationRequest,
    anonymizationConfirmationModal,
    isAnonymizingRelocationRequest,
    leads,
  };
};

const schema: TableSchema<RelocationRequest>[] = [
  { key: 'anfrageId', order: 1, value: (row) => row.anfrageId },
  { key: 'name', value: (row) => `${row.consumer.firstName} ${row.consumer.lastName}`, order: 2 },
  { key: 'email', order: 3, cellRenderer: (row) => EmailRenderer(row.consumer.email || '') },

  { key: 'fromStreet', value: (row) => row.from.street || '', order: 4 },
  { key: 'fromPostalCode', value: (row) => `${row.from.iso2CountryCode}-${row.from.postalCode}`, order: 5 },
  { key: 'fromCity', value: (row) => row.from.city, order: 6 },

  { key: 'toStreet', value: (row) => row.to?.street || '', order: 7 },
  { key: 'toPostalCode', value: (row) => `${row.to?.iso2CountryCode || ''}-${row.to?.postalCode || ''}`, order: 8 },
  { key: 'toCity', value: (row) => row.to?.city || '', order: 9 },

  { key: 'source', value: (row) => row.affiliatePartner || '', order: 10 },
];

export const getRelocationRequestsSchema = (requests?: RelocationRequest[]): UmzugEasyTableProps<RelocationRequest> => {
  return {
    table: getUmzugEasyTableProps<RelocationRequest>({
      schema: schema,
      rows: requests,
      translationPrefix: 'list.table.header',
      translationFile: 'relocationRequests',
    }),
    noDataMessageKey: 'relocationRequests:list.table.noData',
  };
};
