import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPartnersStatistics } from '../../../api/statistics/StatisticsApi';
import { PartnerStatistics, PartnerStatisticsRequestFilter } from '../../../api/statistics/PartnerStatisticsResource';
import { AlertProps } from '../../../components/feedback/Alert';
import { UmzugEasyTableProps } from '../../../components/table/UmzugEasyTable';
import useUserProfileInfo from '../../../hooks/useUserProfileInfo';
import { getPartnersStatisticsTableSchema } from './PartnersReportTableSchema';
import {
  PartnersReportActionsProps,
  PartnersReportContext,
  PartnersReportContextProps,
} from './PartnersReportContext.model';

export const getInitialPartnersStatisticsFilter = (name?: string): PartnerStatisticsRequestFilter => {
  return {
    hasActiveContract: false,
    ownerDetails: name,
  };
};

export const addIndexToTable = (partners: PartnerStatistics[] | undefined, startIndex: number) => {
  const result: PartnerStatisticsWithIndex[] = [];
  partners = partners ??= [];

  let index = startIndex + 1;
  for (const partner of partners) {
    result.push(new PartnerStatisticsWithIndex(index, partner));
    index = index + 1;
  }
  return result;
};

export class PartnerStatisticsWithIndex {
  index: number;
  partner: PartnerStatistics;
  constructor(index: number, partner: PartnerStatistics) {
    this.index = index;
    this.partner = partner;
  }
}

export const useInitPartnersReportsContext = (): PartnersReportContextProps => {
  const [userProfileLoaded, setUserProfileLoaded] = useState(false);
  const [userProfile] = useUserProfileInfo();
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertProps | undefined>(undefined);
  const [showPreviousMonths, setShowPreviousMonths] = useState<boolean>(false);

  const [partnersStatistics, setPartnersStatistics] = useState<PartnerStatisticsWithIndex[]>([]);
  const [partnersStatisticsDate, setPartnersStatisticsDate] = useState<Date | undefined>(undefined);
  const [partnersStatisticsFilter, setPartnersStatisticsFilter] = useState<PartnerStatisticsRequestFilter>(
    getInitialPartnersStatisticsFilter(userProfile?.name),
  );
  const [sortBy, setSortBy] = useState<string>('revenue.currentYear:desc');
  const [page, setPage] = useState(-1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [tableProps, setTableProps] = useState<UmzugEasyTableProps<PartnerStatisticsWithIndex> | undefined>(undefined);

  const { t } = useTranslation('partnersReport');

  const showAlert = (translationKey: string) => {
    setAlert({
      color: 'error',
      open: true,
      text: t(`alerts.${translationKey}`),
      handleClose: () => setAlert(undefined),
    });
  };

  const loadPartnersStatistics = (pageForLoad: number) => {
    setLoading(true);
    getPartnersStatistics(pageForLoad, 20, sortBy, partnersStatisticsFilter)
      .then((partnerStatistics) => {
        if (pageForLoad === 0) {
          setPartnersStatistics(addIndexToTable(partnerStatistics.data._embedded?.partners, 0) || []);
        } else {
          setPartnersStatistics((prevState) => [
            ...prevState,
            ...(addIndexToTable(partnerStatistics.data._embedded?.partners, prevState.length) || []),
          ]);
        }
        setPartnersStatisticsDate(partnerStatistics.serverTime);
        setTotalElements(partnerStatistics.data.page.totalElements);
        setTotalPages(partnerStatistics.data.page.totalPages);
        setPage(pageForLoad);
      })
      .catch(() => showAlert('getPartnersStatisticsError'))
      .finally(() => setLoading(false));
  };

  const onSortByChange = (key: string, direction: 'asc' | 'desc' | undefined) =>
    setSortBy((prevState) => {
      const newSortByArray = prevState.split(',').filter((value) => !value.startsWith(key));
      if (direction !== undefined) {
        newSortByArray.unshift(`${key}:${direction}`);
      }
      return newSortByArray.join(',');
    });

  const actions: PartnersReportActionsProps = {
    onOpenPartnerDetails: () => {},
    onLoadNextPage: () => {
      if (page < totalPages) {
        loadPartnersStatistics(page + 1);
      }
    },
    onFilterChange: (filter: PartnerStatisticsRequestFilter) => setPartnersStatisticsFilter(filter),
    onSortByChange: (sortField: string, direction: string) =>
      setSortBy((prevState) => `${sortField}:${direction},${prevState}`),
    onShowPreviousMonths: (active: boolean) => setShowPreviousMonths(active),
  };

  useEffect(() => {
    if (userProfile?.name !== undefined) {
      setPartnersStatisticsFilter(getInitialPartnersStatisticsFilter(userProfile?.name));
      setUserProfileLoaded(true);
    }
  }, [userProfile]);

  useEffect(() => {
    if (userProfileLoaded) {
      loadPartnersStatistics(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnersStatisticsFilter, sortBy]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () =>
      setTableProps(
        getPartnersStatisticsTableSchema(
          showPreviousMonths,
          onSortByChange,
          t,
          partnersStatistics,
          partnersStatisticsDate,
        ),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partnersStatistics, showPreviousMonths],
  );

  return {
    partnersStatistics,
    partnersStatisticsFilter,
    loading,
    showPreviousMonths,
    page,
    totalPages,
    totalElements,
    tableProps,
    alert,
    actions,
  };
};

export function usePartnersReportsContext() {
  return useContext(PartnersReportContext);
}
