import { useEffect, useState } from 'react';
import { BookingTestDataResponse } from '../../../../api/bookingtest/BookingTestDataResponse';
import { BookingTestDataRequest } from '../../../../api/bookingtest/BookingTestDataRequest';
import { bookingTestApi } from '../../../../api/bookingtest/BookingTestApi';

export interface BookingTestSummary {
  numberOfRequests: number;
  numberOfUndistributedRequests: number;
  numberOfUniquePartners: number;
  averageLeadsPerRequest: number;
}

export const useBookingTestResponseLoader = () => {
  const [bookingTestRequest, setBookingTestRequest] = useState<BookingTestDataRequest | undefined>(undefined);
  const [resultsSummary, setResultsSummary] = useState<BookingTestSummary | undefined>();
  const [loading, setLoading] = useState(false);

  const getResultsSummary = (response: BookingTestDataResponse): BookingTestSummary => {
    return {
      numberOfRequests: response.requestsCount,
      numberOfUndistributedRequests: response.undistributedRequestsCount,
      numberOfUniquePartners: response.uniqueTotalPartnersCount,
      averageLeadsPerRequest: response.averageLeadsPerRequest,
    };
  };

  useEffect(() => {
    if (bookingTestRequest != null) {
      setLoading(true);
      bookingTestApi(bookingTestRequest)
        .then((response) => {
          setLoading(false);
          setResultsSummary(getResultsSummary(response));
        })
        .catch(() => {
          setLoading(false);
          setResultsSummary(undefined);
        });
    } else {
      setResultsSummary(undefined);
    }
  }, [bookingTestRequest]);

  return { setBookingTestRequest, loading, resultsSummary };
};
